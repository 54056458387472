import { OrbitControls, MapControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

import CameraControls from '../modules/camera-controls';

import { CreateDistrict3DUI } from './scene-3d-ui-builder';

import { SetUIHoveredState } from './userinterface-3d';


CameraControls.install( { THREE: THREE } );

//Initializing Control

const clock = new THREE.Clock();
var d_Pointer = new THREE.Vector2(0,0);

let minPan,maxPan,m_Camera,m_MapScale,controls,newControl,m_renderer;

// const minPan = new THREE.Vector3( -1500, -2, -1500 );
// const maxPan = new THREE.Vector3( 1500, 2, 1500 );

/**
 * This function initializes the map controller
 * @param {THREE.Camera} camera 
 * @param {Number} scale 
 * @param {*} renderer 
 */
function InitializeControls(camera,scale,renderer,pointer,mapLimits = {
    minX: 0,
    minZ: 0,
    maxX: 0,
    maxZ: 0,
    maxY: 0,
    minDistance: 100,
    maxDistance: 5000
}) 
{
    m_Camera = camera;
    m_MapScale = scale;
    m_renderer = renderer;
    
    minPan = new THREE.Vector3( mapLimits.minX, mapLimits.minDistance, mapLimits.minZ );
    maxPan = new THREE.Vector3( mapLimits.maxX, mapLimits.maxY, mapLimits.maxZ );
    
    //Old Controls
    // controls = new MapControls( camera, renderer.domElement, minPan, maxPan );
    // controls.update();

    // controls.minDistance = mapLimits.minDistance;
    // controls.maxDistance = mapLimits.maxDistance;

    // controls.enablePan = true;
    // controls.enableDamping = true;
    // controls.dampingFactor = 0.1;

    // controls.enableRotate = true;
    // controls.maxPolarAngle = Math.PI / 2.1;

    //New Controls

    newControl = new CameraControls(camera,renderer.domElement);
    newControl.maxPolarAngle = Math.PI / 2.3;

    
    newControl.maxDistance = mapLimits.maxDistance;
    newControl.minDistance = mapLimits.minDistance;

    // newControl.minZoom =1;
    // newControl.maxZoom = 2;

    // newControl.minZoom = mapLimits.minDistance;
    // newControl.maxZoom = mapLimits.maxDistance;

    // const bb = new THREE.Box3(
	// 	new THREE.Vector3( -dist, 0, -dist ),
	// 	new THREE.Vector3( dist, (30*m_MapScale), dist )
	// );

    const bb = new THREE.Box3(minPan,maxPan);

	newControl.setBoundary( bb );
    newControl.verticalDragToForward = true;

    newControl.mouseButtons.left = CameraControls.ACTION.TRUCK;
    newControl.mouseButtons.right = CameraControls.ACTION.ROTATE;

    newControl.touches.one = CameraControls.ACTION.TRUCK;
    // newControl.touches.two = CameraControls.ACTION.ZOOM;
    // newControl.touches.three = CameraControls.ACTION.ROTATE;

    //newControl.maxAzimuthAngle  = Math.PI;
    newControl.enablePan = true;
    newControl.enableDamping = true;
    newControl.dampingFactor = 0.1 ;

}


let worldPos = new THREE.Vector3();
let cursor_pointer = new THREE.Vector2();
let cursor_lookAt = new THREE.Vector2();
let multiplier = 0.2;

// land.getWorldPosition(worldPos);
// console.log(worldPos);
/**
 * 
 * @param {*} pointer 
 */
function ControlLookAt(state = 0, pointer){

    // console.log(pointer);
    

    if (state === 0) {

        multiplier = 0.2;
        cursor_pointer.set(pointer.x *m_MapScale,pointer.y *m_MapScale);

        cursor_lookAt.set(pointer.x *m_MapScale,pointer.y *m_MapScale);
        

        if (pointer.x == 0 && pointer.y == 0) { 
            cursor_pointer.set(0,2000); 
            cursor_lookAt.set(0,0);
            multiplier = 1;
        }
    }
    else{

        pointer.getWorldPosition(worldPos);
        
        cursor_pointer.set(worldPos.x,worldPos.z);
        cursor_lookAt.set(worldPos.x,worldPos.z);
        multiplier = 0.7;
    }


    newControl.setLookAt(
        //Move To
         cursor_pointer.x * multiplier,
        20 * m_MapScale,
         cursor_pointer.y * multiplier,
        //Look At
         cursor_lookAt.x,
        50,
         cursor_lookAt.y,
        true );

    /*if (pointer.x == 0 && pointer.y == 0) {
        
        newControl.setLookAt(
        //Move To
        0,
        20 * m_MapScale,
        2000,
        //Look At
        0,
        50,
        0,
        true );
    }
    else{
        newControl.setLookAt(
        //Move To
        pointer.x *m_MapScale * 0.2,
        20 * m_MapScale,
        pointer.y *m_MapScale * 0.2,
        //Look At
        pointer.x *m_MapScale,
        50,
        pointer.y *m_MapScale,
        true );
    }  */
}


let Initialized_Anim = false,delta,hasControlsUpdated;
/**
 * This function controls the camera rotation and panning
 */

function ControlUpdates() {

    m_Camera.updateMatrixWorld();

    delta = clock.getDelta();
	hasControlsUpdated = newControl.update( delta );

    if (Initialized_Anim) return;

    // console.log(m_Camera.getPosition.x);

    if(clock.elapsedTime <= 2){

        SetUIHoveredState(true);
        newControl.dolly( clock.elapsedTime* 3, true );
        newControl.rotate( 0 , clock.elapsedTime * 0.4 * THREE.MathUtils.DEG2RAD, true );
        newControl.enabled = false;
    }
    else{
        
        newControl.enabled = true;
        SetUIHoveredState(false);
        Initialized_Anim = true;
    }

}

export {InitializeControls, ControlUpdates,ControlLookAt,}