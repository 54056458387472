import * as THREE from 'three';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { Sky } from 'three/examples/jsm/objects/Sky';

import { GetGLBModel, GetRotationInRadians } from './land-builder';
// import { Water } from '../modules/Water2.js';

import { Water } from 'three/examples/jsm/objects/Water2';

import{Lensflare, LensflareElement} from 'three/examples/jsm/objects/Lensflare.js';
import { Particle } from 'three';


import j_District_Info from '../landData/district-information.json';
import { TextureLoader } from 'three';


let l_SceneParticles = null;

/**
 * Create a WebGL Renderer and make it transparent, ready for the CSS3D renderer
 * @param {Number} width 
 * @param {Number} height 
 * @returns {THREE.WebGL1Renderer}
 */
function CreateWebGLRenderer(width, height)
{
    const renderer = new THREE.WebGLRenderer( { 
        // alpha: true, 
        // antialias: true, 
        // powerPreference: "low-power",
        // preserveDrawingBuffer: true,
        // adding Z buffer to reduce the mesh shaky effects
        // logarithmicDepthBuffer: true
    } ); // required

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( width, height );
    renderer.autoClear = false;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.outputEncoding = THREE.sRGBEncoding;
    // renderer.toneMappingExposure = 0.9;
    renderer.setClearColor( 0x000000, 0 );
    renderer.gammaInput = true;
    renderer.shadowMap.enabled = false;

    /*renderer.setClearColor( 0x000000, 0 );
   
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShado

    renderer.autoClear = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    //renderer.setPixelRatio(1);  // handle pixel ratio externally
    renderer.shadowMap.autoUpdate = false;


    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.toneMapping = THREE.ReinhardToneMapping;
    // renderer.toneMappingExposure = Math.pow(0.9, 4.0);
    renderer.toneMappingExposure = 0.5;

    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    

    renderer.setClearColor( 0x000000, 1 );*/

    return renderer; 
    
}

/**
 * Create a CSS3D Renderer and append it on top of the screen
 * @param {Number} width 
 * @param {Number} height 
 * @returns {THREE.CSS3DRenderer}
 */
function CreateCSS3DRenderer(width, height)
{
    const renderer = new CSS3DRenderer();
    // renderer.setSize( container.clientWidth - 1, container.clientHeight - 1 );
    renderer.setSize( width, height );
    renderer.domElement.style.position = 'absolute';
    renderer.domElement.style.top = 0;

    return renderer;
    // container.appendChild(renderer.domElement);
}


//Default 3D JS function
//Adds Light, Scene and Camera

function InitializeCamera(fov = 60, aspectRatio, nearClip = 1, farClip = 15000, position = {
    x: 0,
    y: 0,
    z: 0
}) {

    const camera = new THREE.PerspectiveCamera( fov, aspectRatio, nearClip, farClip );

    camera.position.set( position.x, position.y, position.z );

    return camera;
}


let m_NightLight = new THREE.Group();

function InitializeLight(lightConfig = {
    useHemisphere: true,
    hem_intensity: 0.5,
    hem_color: 0xffffff,
    hem_base: 0xffffff,

    useDirectional: false,
    direct_intensity: 0.5,
    direct_color: 0xffffff,

    useSkybox: false,
    sky_intensity: 0.5,
    sky_color: 0xffffff,

    scene: null,
    renderer: null,
    lightMode: true,
    map_scale: 50

}) {
    const m_LightGroup = new THREE.Group();

    if (lightConfig.useHemisphere) {

        const hemiLight = new THREE.HemisphereLight( lightConfig.hem_color, lightConfig.hem_base, lightConfig.hem_intensity );

        hemiLight.color.setHSL( 205, 98, 52 );
		hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );

        hemiLight.position.set( 0, 50, 0 );
        m_LightGroup.add( hemiLight );  
    }

    if (lightConfig.useDirectional) {
        
        const directlight = new THREE.DirectionalLight( lightConfig.direct_color, lightConfig.direct_intensity );
        // directlight.position.set( 1, 1, 1 ).normalize();

        directlight.color.setHSL( 0.1, 1, 0.95 );
        directlight.position.set( - 1, 1.75, 1 );
        directlight.position.multiplyScalar( 30 );
        m_LightGroup.add( directlight ); 

        directlight.castShadow = true;

        directlight.shadow.mapSize.width = 2048;
        directlight.shadow.mapSize.height = 2048;

        const d = 50;

        directlight.shadow.camera.left = - d;
        directlight.shadow.camera.right = d;
        directlight.shadow.camera.top = d;
        directlight.shadow.camera.bottom = - d;

        directlight.shadow.camera.far = 3500;
        directlight.shadow.bias = - 0.0001;
    }

    if (lightConfig.useSkybox) {
        
        const sky = new Sky();
        sky.scale.setScalar( 10000 * lightConfig.sky_intensity );
        m_LightGroup.add( sky );

        if (lightConfig.scene != null && lightConfig.renderer != null) {

            buildSun(sky, lightConfig.renderer, lightConfig.scene);    
        }
        
    }
    
    for(let district in j_District_Info)
    {   
        const data = j_District_Info[district]
        
        if (data.isActive) {

            // const light = new THREE.PointLight( data.uiColor,2.5,2500,1);

            const light = new THREE.PointLight( "#fff",2.5,2500,1);

            light.position.set( data.x * lightConfig.map_scale, 500, data.z * lightConfig.map_scale );

            m_NightLight.add(light)
        }
    }

    

    m_LightGroup.add(m_NightLight);

    return m_LightGroup;
}

function AddBasicInitialization()
{
    camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 20000 );
    //camera.position.set(0,0,250);
    camera.position.set( 0, 2000, 4000 );

    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xcccccc );
	scene.fog = new THREE.FogExp2( 0xcccccc, 0.000125 );

    const light = new THREE.DirectionalLight( 0xf8fafc, 0.75 );
    light.position.set( 1, 1, 1 ).normalize();
    scene.add( light );

    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.5 );
    //hemiLight.color.setHSL( 0.6, 1, 0.6 );
    //hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
    hemiLight.position.set( 0, 100, 0 );
    scene.add( hemiLight );

    document.addEventListener( 'mousemove', onDocumentMouseMove );
    
    //Add Sky
    sky = new Sky();
    sky.scale.setScalar( 10000 );
    scene.add( sky );

    buildSun();
    //scene.add(buildSun())
}

function buildSun(sky, renderer, scene) {
  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  const sun = new THREE.Vector3();

  // Defining the x, y and z value for our 3D Vector
  const theta = Math.PI * (0.49 - 0.5);
  const phi = 2 * Math.PI * (0.205 - 0.5);
  sun.x = Math.cos(phi);
  sun.y = Math.sin(phi) * Math.sin(theta);
  sun.z = Math.sin(phi) * Math.cos(theta);

  sky.material.uniforms['sunPosition'].value.copy(sun);
  scene.environment = pmremGenerator.fromScene(sky).texture;

  return sun;
}

function AddLensfair(scene){
    const textureLoader = new THREE.TextureLoader();

    // const textureFlare0 = textureLoader.load( "staticAsset/lensflare0.png" );

    const textureFlare3 = textureLoader.load( "static/threejs/lensflare3.png" );
    const textureFlare4 = textureLoader.load( "static/threejs/lensflare4.png" );

    // textureFlare4.opacity = .01;

    const fare = addLight(-3700, 2500, -10000 );
    // const fare = addLight(0, 1000, -4000 );


    return fare;


    function addLight(x, y, z ) {

        const light = new THREE.PointLight( 0xfff, 1, 1000 );

        light.position.set( x, y, z );
        // scene.add( light );

        const lensflare = new Lensflare();
        /*lensflare.addElement( new LensflareElement( textureFlare0, 250, 0 ) );
        lensflare.addElement( new LensflareElement( textureFlare3, 60, 0.6 ) );
        lensflare.addElement( new LensflareElement( textureFlare3, 70, 0.7 ) );
        lensflare.addElement( new LensflareElement( textureFlare3, 120, 0.9 ) );
        lensflare.addElement( new LensflareElement( textureFlare3, 70, 1 ) );*/


        // lensflare.addElement( new LensflareElement( textureFlare0, 512, 0 ) );
        lensflare.addElement( new LensflareElement( textureFlare3, 256, 0.1 ) );
        // lensflare.addElement( new LensflareElement( textureFlare3, 128, 0.7 ) );
        lensflare.addElement( new LensflareElement( textureFlare4,1500, 0.5 ) );
        // lensflare.addElement( new LensflareElement( textureFlare3, 512, 0.5 ) );
        // lensflare.addElement( new LensflareElement( textureFlare3, 64, 1 ) );
        light.add( lensflare );

        return light;
    }
}



async function AddGLBObject(url = " ", transform = {
    x: 0,
    y: 0,
    z: 0,
    rotation_x: 0,
    scale_x : 1,
    scale_y : 1,
    scale_z : 1,
}) {

    const mesh = await GetGLBModel(url);

    // console.log(mesh);

    if (mesh != null) {

        mesh.scene.position.set(transform.x,transform.y,transform.z);
        // mesh.rotation.set(transform.x,transform.y,transform.z);
        mesh.scene.scale.set(transform.scale_x,transform.scale_y,transform.scale_z);
    }

    return mesh.scene;
}


/**
 * 
 * @param {Number} VHCMapScale 
 * @returns {THREE.Points}
 */
function AddCloudPar(VHCMapScale){

    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    const cloudSprite = new THREE.TextureLoader().load( "/static/threejs/clouds.png" );
    var materialPar = new THREE.PointsMaterial( { 
        // size:(Math.random()*7000),
        size:(5000 * window.devicePixelRatio),
        map: cloudSprite, 
        transparent:true,
        //blending: THREE.AdditiveBlending,
        depthTest: false,
        opacity: .35
    } );

    for ( let i = 0; i < 10; i ++ ) {

        const x = ((Math.random()*(80+80)-80))*VHCMapScale;
        const y = 30*VHCMapScale;
        const z = ((Math.random()*(80+80)-80))*VHCMapScale;

        vertices.push( x, y, z );
        // console.log(vertices);

       // geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
    }

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

    // const particles = new THREE.Points( geometry, materialPar );
    l_SceneParticles = new THREE.Points( geometry, materialPar );

    return l_SceneParticles;
    // scene.add( particles );

}

/**
 * 
 * @param {Number} speed 
 */
function AddCloudMove(speed){

    // const time = Date.now() * 0.000005;
    const time = performance.now() * 0.000005;

    /*for ( let i = 0; i < scene.children.length; i ++ ) {

        const object = scene.children[ i ];

        if ( object instanceof THREE.Points ) {

            object.rotation.y = time * ( i < 4 ? i + 1 : - ( i + 1 ) );

        }

    }*/

    if (l_SceneParticles != null) {
        // l_SceneParticles.rotation.y = time * ( i < 4 ? i + 1 : - ( i + 1 ) );
        l_SceneParticles.rotation.y = time * speed;
    }
    

}

// let oceanFloor;

async function AddMapWaterSystem(length = 10000, segments = 32, waterConfig={
    groundColor: '#074a5d',
    waterColor: '#005477',
    waterScale: 50,
    textureSize: 512,
    flowX: 4,
    flowY: 4,
    waterPosition: 0,
    groundGap : 10,
    rotation: -90,
    renderer: null,
    scene: null,
    camera: null
}) {

    const m_WaterGroup = new THREE.Group();

    // const oceanMaterial = new THREE.MeshBasicMaterial({color: waterConfig.groundColor,transparent: true, opacity: 0.6});

    const texture = await new TextureLoader().loadAsync("/static/threejs/ocean-shader.png");

    const oceanMaterial = new THREE.MeshBasicMaterial({side: THREE.FrontSide, map: texture, transparent: true, opacity: 0.6});

    const oceanFloor = new THREE.Mesh(new THREE.CircleGeometry(length,segments), oceanMaterial);

    // const oceanFloor = new THREE.Mesh(new THREE.PlaneGeometry(length,length), oceanMaterial);

    oceanFloor.rotation.x = Math.PI * - 0.5;
    oceanFloor.position.y = waterConfig.waterPosition - waterConfig.groundGap + 3.5;

    m_WaterGroup.add(oceanFloor);

    const waterGeometry = new THREE.CircleGeometry( length, segments );
    

    /*const water = new Water( waterGeometry, {
        color: waterConfig.waterColor,
        scale: waterConfig.waterScale,
        flowDirection: new THREE.Vector2( waterConfig.flowX, waterConfig.flowY ),
        textureWidth: waterConfig.textureSize,
        textureHeight: waterConfig.textureSize,
        waterNormals: null
    } );


    water.position.y = waterConfig.waterPosition;
    water.rotation.x = GetRotationInRadians(waterConfig.rotation);

    m_WaterGroup.add(water);*/

    return m_WaterGroup;

}


function LightMode(renderer) {
    renderer.toneMappingExposure = 0.8;
    m_NightLight.visible = false;
}


function DarkMode(renderer) {
    renderer.toneMappingExposure = 0.2;
    m_NightLight.visible = true;
}


export { CreateWebGLRenderer, CreateCSS3DRenderer, InitializeCamera, InitializeLight, AddGLBObject, AddMapWaterSystem, AddCloudPar, AddCloudMove, AddLensfair, LightMode, DarkMode}


const water_ground_material = new THREE.ShaderMaterial({
  uniforms: {
    color1: {
      value: new THREE.Color("#005a87")
    },
    color2: {
      value: new THREE.Color("#4ae2ef")
    }
  },
  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1);
    }
  `,
  fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;
  
    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 0.6);
    }
  `,
  wireframe: false,
  transparent: true
});


var water_ground_material_radial = new THREE.ShaderMaterial({
    uniforms: {
        color1: {
            value: new THREE.Color("#005a87")
        },
        color2: {
            value: new THREE.Color("#96f3fa")
        }
    },
    vertexShader: `varying vec2 vUv;
      void main(){
        vUv = uv;
        gl_Position = vec4(position, 1.);
      }`,
          fragmentShader: `varying vec2 vUv;
        uniform vec3 color1;
        uniform vec3 color2;
        uniform float ratio;
        void main(){
        	vec2 uv = (vUv - 0.5) * vec2(ratio, 1.);
          gl_FragColor = vec4( mix( color1, color2, length(uv)), 1. );
        }`
    })