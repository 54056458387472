<script>

</script>

<section>
    <h1>
        Not Found
    </h1>
    <a href="/">Back</a>
</section>

<style>
    section{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>