import * as THREE from "three";
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


import { BuildingLoader } from "./viewer-functions/environment/building-loader";
import { PlayerController } from "./viewer-functions/functions/PlayerController";
import { VerifyOwnerShip, GetLandBuildingInfo, VerifyOwnerShipv2 } from "./global/user-session-details";
import { SetLoaderState } from './global/loader-controller';
import { AuthenticateFunction } from "./map-functions/authenticate-user";

import { InitMultiplayer, MuliplayerClientsUpdate } from "./viewer-functions/multiplayer/SocketMultiplayer";

import { SetStatus } from "./viewer-functions/functions/UIController";

let camera: THREE.PerspectiveCamera, 
    scene: THREE.Scene, 
    renderer: THREE.WebGLRenderer, 
    m_CurrentPlayer: PlayerController, 
    m_CurrentInterior: BuildingLoader,
    m_CurrentTime = 0;

//Parameters

const colliders = new THREE.Group();
const clock = new THREE.Clock();

async function StartViewer() {

  SetLoaderState(true,{
    val: 0,string: 'Validating User..'
  });

  await AuthenticateFunction(async ()=>{
    //Start ThreeJS

    SetLoaderState(true,{
      val: 0,string: 'Preparing Environment..'
    });
    
    await init();
    animate();

    SetLoaderState(false);
  })
  
}

async function init() {  

  renderer = new THREE.WebGLRenderer({ 
    antialias: true,
    powerPreference: 'high-performance'
  });
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.shadowMap.type = THREE.PCFShadowMap;
  // renderer.shadowMap.enabled = true;
  // renderer.shadowMapSoft = true;
  // renderer.gammaOutput = true;
  renderer.toneMapping =  THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;


  const canvas = document.querySelector('#viewer-canvas')
  if(canvas) canvas.appendChild(renderer.domElement);

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0xcccccc);

  //Skybox
  /*new RGBELoader()
  .setDataType(THREE.HalfFloatType)
  .load('./static/threejs/blaubeuren_night_1k.hdr',(texture)=>{

    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    pmremGenerator.compileEquirectangularShader();

    const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
    // scene.background = envMap;
    // scene.environment = envMap;

  })*/

  new THREE.TextureLoader()
  .load('./static/threejs/vaulthill-env-bg.png',(texture)=>{

    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    pmremGenerator.compileEquirectangularShader();

    const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
    scene.background = envMap;
    scene.environment = envMap;

  })

  // Add Plane
  const geom = new THREE.PlaneGeometry(100, 100);

  const material = new THREE.MeshBasicMaterial({
    color: "grey",
    // roughness: 0.2,
  });

  const boundMaterial = new THREE.MeshBasicMaterial({
    color: "grey",
    transparent: true,
    opacity: 0
    // roughness: 0.2,
  });
  
  const floorMesh = new THREE.Mesh(geom, material);

  const box = new THREE.BoxGeometry(100, 10, 1);
  const boundOne = new THREE.Mesh(box, boundMaterial);
  const boundTwo = new THREE.Mesh(box, boundMaterial);
  const boundThree= new THREE.Mesh(box, boundMaterial);
  const boundFour = new THREE.Mesh(box, boundMaterial);

  boundOne.position.set(0, 0, 25);
  boundTwo.position.set(0, 0, -25);

  boundThree.position.set(25, 0, 0);
  boundThree.rotation.set(0,0.0174533 * 90,0);

  boundFour.position.set(-25, 0, 0);
  boundFour.rotation.set(0,0.0174533 * 90,0);

  // @ts-ignore
  floorMesh.rotation.set(0.0174533 * 270, 0, 0);

  // @ts-ignore
  floorMesh.position.set(0, 0, 0);

  floorMesh.receiveShadow = true;

  const gridHelper = new THREE.GridHelper(40, 40);

  //Add billboard

  const billBoard = await GetGLBModel('/static/samples/billboard.glb');
  billBoard.position.set(0,0,5)  

  colliders.add(floorMesh, gridHelper, boundOne, boundTwo, boundThree, boundFour, billBoard);
  scene.add(colliders);
  
  // lights
  const ambientLight = new THREE.AmbientLight(0xffffff, 2);
  scene.add(ambientLight);

  // const hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 1);
  // scene.add(hemiLight);

  const pointLight = new THREE.PointLight(0xff0000,1,100);

  // @ts-ignore
  pointLight.position.set(0, 5, 0);

  // pointLight.castShadow = true;
  // scene.add(pointLight);

  // Run Instantiation
  const currentLandID = window.location.hash.split('/')?.pop()?.split('#')[0].split('?')[0];

  const landDetails = await GetLandBuildingInfo(currentLandID || '');
  // const userstate = VerifyOwnerShip(currentLandID || '');
  const userstate = VerifyOwnerShipv2(landDetails || {});

  // console.log(userstate);
  
  const isBuildingCustomised = landDetails?.building?.buildingCustomization?.fileUrl? true: false;

  const buildignDetails = landDetails?.building?.buildingCustomization?.fileUrl || landDetails?.building?.animationRenderUrl;  

  // const isLandOwner = true;
  const isLandOwner = userstate.state;

  window.postMessage({
    type: 'building-state',
    data: {
      isLandOwner
    }
  }, undefined);

  //To test

  await AddBuildingInterior({
    link: "/static/samples/gallery-final.glb",
    // link: "/static/samples/test-interior-v2.glb",
    // link: "/static/samples/Vitality_D_v1_test.glb",
    // link: "/static/samples/gallery-asad-v6.glb",
    // link: "/static/samples/hammed-test.glb",
    // link: "/static/samples/vhc_custom.zip",
    isOwner: isLandOwner,
    buildingInfo: {},
    type: 'model'
  });

  if(buildignDetails){

    SetLoaderState(true,{
      val: 50,string: 'Adding User Building...'
    });

    const data = {
      link: buildignDetails,
      isOwner: isLandOwner,
      buildingInfo: landDetails?.building
    }

    if(isBuildingCustomised){
      await AddBuildingInterior({
        ...data,
        type: 'zip'
      });
    }
    else{
      await AddBuildingInterior({
        ...data,
        type: 'model'
      });
    }
    
  }

  SetLoaderState(true,{
    val: 75,string: 'Adding User Avatar...'
  });

  const playerAvatar = userstate.avatar || '/static/samples/avatar.glb';

  const m_PlayerCharacter = await AddCharacter(playerAvatar,colliders, isLandOwner);

  /*InitMultiplayer({
    playerAvatar: playerAvatar, 
    room: parseInt(currentLandID || '1'),
    scene: scene,
    m_MainPlayer: m_PlayerCharacter
  });*/
}

function animate() {
  requestAnimationFrame(animate);

  // playerUpdate();
  if(m_CurrentPlayer && camera){
    m_CurrentTime = clock.getDelta();
    m_CurrentPlayer.UpdatePlayer();
    // m_CurrentPlayer.UpdatePlayerDelta(m_CurrentTime);
    // MuliplayerClientsUpdate(m_CurrentTime);
    renderer.render(scene, camera);
  }
}

function onWindowResize() {

  if(!camera) return;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

}

window.addEventListener("resize", () => {
  onWindowResize();
});

async function AddCharacter(link: string, colliders: THREE.Group, isOwner: boolean) {

  if(m_CurrentPlayer) m_CurrentPlayer.RemovePlayer(scene);  

  m_CurrentPlayer = new PlayerController({
    canvas: renderer.domElement,
    playerAvatarUrl: link,
    colliders: colliders,
    isOwner: isOwner
  });
  const playerInit = await m_CurrentPlayer.Initialize();
  camera = playerInit.camera;
  scene.add(playerInit.group);

  return m_CurrentPlayer;
}

async function AddBuildingInterior({
  link = '', 
  type ='model', 
  buildingInfo,
  isOwner = false
}) {

  let opts;

  if(isOwner){
    opts = {
      walletAddress: buildingInfo?.buildingCustomization?.deploymentDetails?.walletAddress,
      buildingId: buildingInfo?.tokenId,
      data: buildingInfo?.buildingCustomization
    }
  }
  
  if(m_CurrentInterior) m_CurrentInterior.RemoveBuilding(scene);

  m_CurrentInterior = new BuildingLoader(link,type,opts);
  const building = await m_CurrentInterior.Initialize();

  if(building) {
    
    const length = colliders.children.length;

    for (let i = 0; i < length; i++) {

      colliders.remove(colliders.children[0]);
      
    }

    //Remove the 2 objects added
    // colliders
    // .remove(colliders.children[0])
    // .remove(colliders.children[0]);

    colliders.add(building);
  }
}

async function ExportSceneBuilding() {

  if(!m_CurrentInterior) {

    SetStatus({
      isError: true,
      text: 'No building available'
    })

    return;
  }

  const state = await m_CurrentInterior.ExportBuilding();
  

  //@ts-ignore
  if (!state.success) {

    SetStatus({
      isError: true,
      text: state?.error || ''
    })
    
    return;
  };

  SetStatus({
    isError: false,
    text: state?.success
  })

  console.log(state);
}

function DeSelectCurrentObject() {

  if(!m_CurrentPlayer) return;

  m_CurrentPlayer.playerRaycaster.deselectObject();
}

export {StartViewer, ExportSceneBuilding, m_CurrentInterior, DeSelectCurrentObject}


// Helper
function HideElement(el) {
  el.style.display = 'none';
}

async function GetGLBModel(url) 
{
  const loader = new GLTFLoader();

  const gltf = await new Promise<THREE.Group>((resolve,reject)=>{
      loader.load(url,
      (gltf)=>{
          resolve(gltf.scene);
      },(progress)=>{

      },(error)=>{
          resolve(new THREE.Group());
      })
  })

  return gltf;
    
}



