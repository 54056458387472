<script>
    import {SwitchThemeMode} from '../../../js/map-functions/userinterface-2d';

	import { onMount } from 'svelte';

    onMount(()=>{
		
	})


</script>

<div class="ControlMenu">

    <label class="switch" >
        
    <input id="modeControl" type="checkbox">

    <span class="slider round"></span>

    <div class="toggle-icon">
        <div>
            <img src="./static/assets/sun-black.svg" alt="Light Mode" id="day-day">

            <img src="./static/assets/sun-white.svg" alt="Light Mode" class="hide" id="day-night">
        </div>

        <div>
            <img src="./static/assets/moon-black.svg" alt="Dark Mode">
        </div>
    </div>

    </label>
</div>