<script>

    import {onMount} from 'svelte';

    import {AddArtworkToFrame, OpenArtworkControlPanel, SetStatus} from '../../../js/viewer-functions/functions/UIController';

    const tabs = [
        {
            name: 'NFTS',
            contentId: 'asset-image-nft',
            btn: null,
            contentEl: null
        },
        {
            name: 'UPLOAD',
            contentId: 'asset-upload',
            btn: null,
            contentEl: null
        }
    ]

    const assetConfig = {
        currentBlob: '',
        blob: Blob
    }

    onMount(()=>{

        tabs.forEach((data)=>{
            data.btn = document.querySelector(`#${data.name}`);
            data.contentEl = document.querySelector(`#${data.contentId}`);

            data.btn.addEventListener('click',()=>{

                for(const dt of tabs){
                    if(dt.contentEl) dt.contentEl.classList.add('hide');
                    if(dt.btn) dt.btn.classList.remove('tabs-selected');
                }

                data.contentEl.classList.remove('hide');
                data.btn.classList.add('tabs-selected');
            })
        })

        tabs[1].btn.click();


    })

    function OnInputButtonClick() {
        document.querySelector('#image-file').click();
    }

    function OnInputFileUploaded(e) {
        
        const files = e.target.files;

        if(files.length < 1) return;

        document.querySelector('#image-upload-preview').classList.remove('hide');
        const thumb = document.querySelector('#image-file-thumb');

        if(assetConfig.currentBlob){
            URL.revokeObjectURL(assetConfig.currentBlob);
        }

        assetConfig.currentBlob = URL.createObjectURL(files[0]);
        assetConfig.blob = files[0];
        thumb.setAttribute('src',assetConfig.currentBlob);

    }

    function ClearImagePreview() {
        document.querySelector('#image-upload-preview').classList.add('hide');
    }

    function ConfirmImagePreview() {

        document.querySelector('#customiser').classList.add('hide');
        document.querySelector('#image-upload-preview').classList.add('hide');

        AddArtworkToFrame({
            blob: assetConfig.blob,
            onComplete: ()=>{
                SetStatus({
                    isError: false,
                    text: 'Artwork Uploaded'
                })

                OpenArtworkControlPanel();
            }
        });
    }

</script>

<section>
    <div class="action-completed trans-hide" id="action-completed">
        <p id="action-completed-text">Artwork Uploaded</p>
    </div>

    <div class="customiser" id="customiser">

        <div>
            <div class="header">
                <h1>Select Image</h1>
                <button id="close-viewer">Close</button>
                <!-- <img src="./static/assets/square.png" alt="" id="image-src"> -->
            </div>

            <div class="asset-selector">
                <div class="tabs" id="tabs" >
                    {#each tabs as {name}}
                        <button class="tabs-button" id={name}>
                            {name}
                        </button>
                    {/each}
                </div>

                <div class="content">

                    <div id="asset-upload">

                        <h1>Upload files here</h1>
                        <p style="opacity: 0.7;">Only .png .jpg .webp are supported</p>

                        <input type="file" name="" id="image-file" style="display: none;" on:change={OnInputFileUploaded}>

                        <button id="image-file-button" on:click={OnInputButtonClick}>
                            Select From Your Device
                        </button>

                        <!-- <button id="change-artwork">Change Artwork</button> -->
                        
                    </div>

                    <div id="asset-image-nft">

                        <input type="file" name="" id="image-file" style="display: none;">
                        <button id="change-artwork" style="display: none;">Change Artwork</button>

                        <p>No NFT Art...</p>
                        
                    </div>

                    <div class="image-upload-preview hide" id="image-upload-preview">
                        <h1>Confirm Image</h1>
                        <img src="/" alt="no-preview" id="image-file-thumb">
                        <div class="copyright">
                            <h3>Copyright & License</h3>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet..</p>
                        </div>
                        <div>
                            <button class="secondaryButton" on:click={ClearImagePreview}>Cancel</button>

                            <button class="primaryButton" on:click={ConfirmImagePreview}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

</section>

<style>
    section{
        width: 100%;
        height: 100%;
    }

    .customiser{
        display: flex;
        z-index: 11;
        position: fixed;
        padding: 10px;
        column-gap: 10px;
        left: 0px;
        top: 0px;
        background-color: #1b1b1ba3;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        /* pointer-events: none; */
    }

    .customiser > div{
        background-color: #f3f3f3;
        border-radius: 10px;
        max-width: 1000px;
        width: 70%;  
        padding: 20px;  
        height: 90%;
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

    .header{
        display: flex;
        justify-content: space-between;
    }

    .asset-selector{
        display: flex;
        column-gap: 10px;
        height: 100%;
    }

    .tabs{
        min-width: 150px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .tabs-button{
        border: none;
        background-color: transparent;
        color: #1b1b1b;
        cursor: pointer;
        text-align: left;
        transition: .2s;
        font-weight: 600;
        padding: 5px;
    }

    .tabs-button:hover{
        text-decoration: underline;
    }

    /*.imageholder{
        width: 50%;
        height: 70%;
        max-width: 500px;
        max-height: 500px;

        
    }

    .imageholder img{
        max-width: 100%;
        max-height: 100%;
    }*/

    #close-viewer{
        /* position: absolute; */
        top: 10px;
        right: 10px;
    }

    .content{
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
    }

    #asset-image-nft{
        display: grid;
        grid-template-columns: repeat(auto-fit, 150px);
    }

    #asset-upload{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        border: 2px dotted #C5D2E3;
        row-gap: 15px;
        
    }

    #asset-upload button{
        background-color: #A96CEF;
        color: #f3f3f3;
        padding: 15px;
    }

    #image-file-thumb{
        max-width: 100%;
        max-height: 250px;
        /* width: 100%; */

    }

    .image-upload-preview{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        background-color: #f3f3f3;
        padding: 0 20px;
        padding-bottom: 20px;
        grid-template-rows: max-content 3fr 1fr max-content;
    }

    .image-upload-preview .copyright{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .copyright p{
        text-align: center;
        opacity: 0.8;
        font-size: 14px;
    }

    .image-upload-preview > div{
        display: flex;
        column-gap: 10px;
    }

    .image-upload-preview  button{
        width: 150px;
        font-weight: 600;
    }

    .primaryButton{
        background-color: #A96CEF;
        color: #f3f3f3;
    }

    .secondaryButton{
        background-color: #f3f3f3;
        color: #A96CEF;
        border: 1px solid #A96CEF
    }

    .action-completed{
        z-index: 15;
        position: absolute;
        width: 100vw;
        height: max-content;
        transition: .2s;
        
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .action-completed p{
        background-color: #f3f3f3;
        border-radius: 20px;
        padding: 12.5px;
        font-size: 14px;
        font-weight: 700;
    }

    
</style>