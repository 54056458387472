<script>
    //Commons
	import Panel from '../common/Panel.svelte';
	import Onboarding from '../common/Onboarding.svelte';
	import Loader from '../common/Loader.svelte';

    //Components
	import DetailsPanel from './components/DetailsPanel.svelte';
	import ThemeSelector from './components/ThemeSelector.svelte';
	import LandModal from './components/LandModal.svelte';
	import LandInfoPanel from './components/LandInfoPanel.svelte';
	import BrandInfoPanel from './components/BrandInfoPanel.svelte';
	import Canvas from './components/Canvas.svelte';
	import LandingPanel from './components/LandingPanel.svelte';

	import { StartApp } from '../../js/map-main';

	import { onMount } from 'svelte';

	onMount(()=>{
		StartApp();
	})
 
</script>

<section>
    <Onboarding/>
    <Loader/>
	
	<LandingPanel/>
	<Canvas/>
	<Panel/>
	<DetailsPanel/>
	<ThemeSelector/>
	<LandModal/>
	<LandInfoPanel/>
	<BrandInfoPanel/>
	<!-- <Canvas/> -->
	
	<!-- <Footer/> -->
	
</section>

<style>
	
</style>