{
    "37":{
        "name": "KeenWorld",
        "about": "We bridge the gap between brands and in-world marketing",
        "website": "http://keenworld.io/",
        "logo": "/static/brands/keenworld.png"
    },
    "89":{
        "name": "Art.by.Amrita",
        "about": "Amrita Sethi is an award-winning, golden visa holder and the first NFT artist in the UAE and creator of a new multimedia art form called “SoundBYTE”. SoundBYTEs merge sound, technology and story telling with NFT’s giving her a perfect platform to showcase her dynamic art form.",
        "website": "https://artbyamrita.com/",
        "logo": "/static/brands/amrita.png"
    },
    "46":{
        "name": "Haus of Specs",
        "about": "Welcome to the Virtual world of Haus of Specs. At Haus of Specs, we cater to your eyewear needs; frames for both prescription and non-prescription glasses. Established in 2020; to bridge the gap between what is both fashionable and practical for optical frames. We have a wide range of styles to suit all face shapes, occasions and personalities. Browse through our products today, you won’t be disappointed!",
        "website": "https://instagram.com/hausofspecs?igshid=YmMyMTA2M2Y=",
        "logo": "/static/brands/haus-of-spec.png"
    },
    "471":{
        "name": "MGCG Middle East FZCO",
        "about": "MGCG is a fast-growing staffing & recruitment expert based in the UAE covering worldwide opportunities with teams implemented across 16 countries.",
        "website": "https://www.linkedin.com/company/mgcg-middle-east/about/",
        "logo": "/static/brands/mgc.png"
    },
    "473":{
        "name": "Today Fit",
        "about": "The world’s first sustainable Personal Training. Founded in 2021, Hong Kong, our App gives you a real, world class Personal Trainer.",
        "website": "https://todayfitapp.com/",
        "logo": "/static/brands/today-fit.png"
    },
    "107":{
        "name": "Meta  Doge",
        "about": "Metaverse ruled by 3D Metadoge avatars.",
        "website": "https://metadogetoken.com/",
        "logo": "/static/brands/meta-doge.png"
    },
    "378":{
        "name": "Cryptopia",
        "about": "A YouTube channel bringing you new and exciting crypto projects, focused on gaming and metaverse, but covering other projects/strategies, including: Exclusive interviews with project CEOs/Founders, Making the most of market cycles/trends, Staking - Top 100 crypto reviews Round table projects discussions",
        "website": "https://www.youtube.com/c/Cryptopia1",
        "logo": "/static/brands/cryptopia.png"
    },
    "459":{
        "name": "Andrew Smreker",
        "about": " Conceptual artist. Expressions of free will through rejections of etiquette. Price provokes premise",
        "website": "",
        "logo": "/static/brands/parallel-thought-typography.png"
    },
    "418":{
        "name": "MyBubble",
        "about": "We’re MyBubble and we make it easier to access mental health support that’s tailored to you. We offer free, science-backed insights via our mobile app and we deliver metaverse events such as mindfulness sessions and educational talks. On top of this, we’re building our virtual therapy services in Vault Hill City so that anybody can schedule a therapy appointment 24/7, from anywhere in the world!",
        "website": "https://mybubbleapp.co.uk/",
        "logo": "/static/brands/my-bubble.png"
    }
}