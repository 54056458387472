<script>

</script>

<div id="brandInfo" class="landinfoParent hide">
    <div  class="landInfo brandInfo" id="">
    <img src="./static/assets/location-brand.svg" alt="">
    <h1 id="brandInfoText">click to explore</h1>
    </div>
</div>

<style>
    .landinfoParent{
        background-color: #FFFFFFD9; 
        padding: 5px;
        /* z-index: 9; */
    }
</style>