<script>
    
    let m_ExperienceCardDetails = {};    

    window.addEventListener('message', (e) => {

        if(e.origin != window.location.protocol + '//' + window.location.host){
            return;
        }

        const data = e.data;

        if(data?.type != 'land-details'){
            return;
        }

        m_ExperienceCardDetails = data?.data;

    });
</script>

<div id="vhcModal" class="modal">

    <button id="vhc_modal_close" class="modal-close-button">X</button>
    
    {#key m_ExperienceCardDetails}

    <div id="vhc_modal_data" class="vhc_modal_data">

        <div class="LandInterface">

            <div class="image-header">
                <img src="{m_ExperienceCardDetails?.experience_bg}" alt="experience display" >
            </div>

            <div class="card-details">

                <div class="card-header">

                    <div class="card-header-avatar">
                        <img src="{m_ExperienceCardDetails?.display_picture}" alt="display">
                    </div>

                    <div class="card-header-title">

                        <h1>{m_ExperienceCardDetails?.title}</h1>

                        <div>
                            <div class="badge">{m_ExperienceCardDetails?.land_type}</div>
                            <div class="badge">{m_ExperienceCardDetails?.district}</div>
                        </div>

                    </div>

                </div>

                <div class="card-content">

                    <p>{m_ExperienceCardDetails?.description}</p>

                    <div class="card-action">
                        <button class="btn-action" on:click={()=>{

                            if(m_ExperienceCardDetails.experience_link){
                                window.location.href = m_ExperienceCardDetails.experience_link;
                            }
                            
                        }}>Explore Land</button>
                    </div>
                </div>

            </div>
        
        </div>
    
    </div>

    {/key}

</div>

<style>

    .modal {
        display: none; 
        position: fixed;
        z-index: 10; 
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        /* overflow: auto; */
        background-color: rgb(0,0,0); 
        background-color: rgba(0,0,0,0.5); /* Black w/ opacity */

        padding: 20px;
        transition: .2s;

        flex-direction: column;

        /* overflow: hidden; */
    }

    .modal-close-button{
        position: absolute;
        
        top: 50px;
        right: 50px;
        background-color: transparent;
        font-size: 24px;
        color: white;
    }

    .vhc_modal_data{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .LandInterface{
        width: 400px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #FFFFFF;
    }

    .image-header{
        width: 100%;
        height: 250px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-header img{
        max-width: 100%;
    }

    .card-details{
        background-color: #FFFFFF; 
        padding: 25px;
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }

    .card-header{
        display: grid;
        column-gap: 20px;
        height: max-content;
        grid-template-columns: 100px 1fr;
        margin-top: -40px;
        /* align-items: baseline; */
    }

    .card-header-avatar{
        width: 100px;
        height: 100px;
        overflow: hidden;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 5px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 10px 0px #00000026;
    }

    .card-header-avatar img{
        max-width: 100%;
        max-height: 100%;
    }

    .card-header-title{
        display: flex;
        row-gap: 5px;
        flex-direction: column;
        height: 100%;
        justify-content: end;
    }

    .card-header-title > div{
        display: flex;
        column-gap: 10px;
        font-size: 14px;
        /* margin-top: auto; */
    }
    .badge{
        padding: 5px;
        border-radius: 5px;
        background-color: #f1f1f1;
        font-size: 12px;
        box-shadow: 0px 0px 2.5px 0px #00000026;
    }

    .card-content{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .card-action{
        display: flex;
        column-gap: 10px;
        justify-content: center;
    }

    .btn-action{
        background: linear-gradient(103.54deg, #66DFC4 -4.84%, #7FA8EC 16.16%, #A96CEE 47.66%, #EC8BC0 72.34%, #F9C294 95.97%);

        border-radius: 25px;

        color: #FFFFFF;

        padding: 15px 10px;
        width: 100%;
        height: 50px;
        font-size: 16px;
    }
    

    /* .vhc-modal-header {
        background-color: #FFFFFFE5;
        width: 100%;

        padding: 20px 30px;

        justify-content: space-between;
        display: flex;

    }

    .vhc-modal-header h1{
        font-size: 28px;
    }

    .vhc-modal-header p {
        font-size: 16px;
    }

    #vhc_modal_close {
        right: 10px;
        top: 50%;
        font-size: 16px;
        padding: 2px;
        width: 40px;
        height: 40px;

        margin: auto 0;
    }

    .vhc_modal_data{

        background-color: #FFFFFFB2;
        display: grid;
        max-width: 90vw;
        min-height: max-content;

        grid-template-columns: repeat(auto-fit, 300px);
        grid-auto-rows: max-content;
        grid-auto-flow: row;


        column-gap: 20px;
        row-gap: 20px;

        padding: 20px 30px;

        overflow-y: auto;
        overflow-x: hidden;
        
    }

    .LandInterface{
        display: flex;
        grid-template-rows: 1fr 3fr 2fr 1fr;
        flex-direction: column;
        justify-content: space-around;
        padding: 40px 0;
        row-gap: 10px;
        column-gap: 20px;

        width: 300px;
        max-width: 100%;

        height: 600px;

        background-color: #FFFFFF;
        
        clip-path: polygon(
        0 7.5%,
        50% 0,
        100% 7.5%,
        100% 92.5%,
        50% 100%,
        0 92.5%);
    }

    .c_one{
        display: flex;
        column-gap: 20px;
        align-items: center;
        padding: 0 20px;

    }

    .c_one > div{
        border-radius: 50%;
    }

    .c_two{
    display: flex; 
    width: 100%;

    }

    .c_two img{
        max-width: 100%;
    }

    .c_three{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }

    .c_three div{
        
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        row-gap: 5px;
    }

    .c_three .c_three_header h1{
        color: #434343;
        font-size: 18px;
    }

    .c_three .c_three_sales h1{
        color: #434343;
        font-size: 14px;
    }


    .c_three p{
        color: #1b1b1b;
        font-size: 14px;
    }

    .c_four{
        display: flex;
        flex-direction: column;
        row-gap: 5px; 
        justify-content: center;
        align-items: center;
    }

    .vhc_action_button{

        background: linear-gradient(102.89deg, #66DFC4 4.15%, #7FA8EC 19.4%, #A96CEE 54.99%, #EC8BC0 84.47%, #F9C294 101.76%);

        border-radius: 30px;
        border: solid 2px transparent;
        color: #fff;
        cursor: pointer;
        transition: .2s;

        text-decoration: none;
        text-align: center;
        justify-content: center;
        padding: 10px 30px;
        width: 200px;
    }

    .vhc_action_button:hover{
        opacity: 0.7;
    }

    .vhc_action_button:active{
        opacity: 0.5;
    }

    .vhc_action_button_disabled{
        pointer-events: none;
        opacity: .5;
        cursor: default !important;
    }

    .landAvailable{
        height: 15px;
        width: 15px;
        background-color: rgb(39, 235, 13);
    }

    .landNotAvailable{
        height: 15px;
        width: 15px;
        background-color: #e25403;
    } */

</style>