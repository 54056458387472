import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import{ RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

import{ FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import{ UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass'

import * as THREE from 'three';

let m_Composer, m_BloomComposer, m_BloomLayer, m_Scene, m_Container;

const darkMaterial = new THREE.MeshBasicMaterial( { color: "black" } );
const materials = {};


let m_SceneFallback = {
   bloomOn: new THREE.Color(0x000000),
   bloomOff: new THREE.Color(0xcccccc),
   fog: null
}

const j_PostConfig = {
    useBloom : true,
    bloomStrenght: 0.5,
    bloomRadius: 1,
    useAliasing: true,
    useFog: true,
    fogColor: 0xcccccc,
    fogDensity: 0.0001,
    sceneBackground: 0x000
}
/**
 * Initialize Post Processing
 * @param {THREE.Scene} scene 
 * @param {THREE.Camera} camera 
 * @param {THREE.Layers} layer 
 * @param {j_PostConfig} config 
 */
function InitPostProcessing(scene, camera, renderer, layer, container, config = j_PostConfig) {

    m_BloomLayer = layer;
    m_Scene = scene;
    m_Container = container;

    m_SceneFallback.bloomOff = config.sceneBackground;

    const renderScene = new RenderPass( m_Scene, camera );

    m_Composer = new EffectComposer( renderer );
    m_Composer.addPass( renderScene );

    if (config.useAliasing) 
    {
        const effectFXAA = new ShaderPass( FXAAShader );
        effectFXAA.uniforms.resolution.value.set( 1 / window.innerWidth, 1 / window.innerHeight );
        m_Composer.addPass( effectFXAA );

        //const pass = new SMAAPass( window.innerWidth * renderer.getPixelRatio(), window.innerHeight * renderer.getPixelRatio() );
        // m_Composer.addPass( pass );

    }

    if (config.useBloom) {

        const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        bloomPass.threshold = 0;
        bloomPass.strength = config.bloomStrenght;
        bloomPass.radius = config.bloomRadius;

        m_BloomComposer = new EffectComposer( renderer );
        m_BloomComposer.renderToScreen = false;
        m_BloomComposer.addPass( renderScene );
        m_BloomComposer.addPass( bloomPass );

        const finalPass = new ShaderPass(
            new THREE.ShaderMaterial( {
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: { value: m_BloomComposer.renderTarget2.texture }
                },
                vertexShader: m_VertexShader,
                fragmentShader: m_FragmentShader,
                defines: {}
            } ), "baseTexture"
        );

        m_Composer.addPass( finalPass );
    }

    if (config.useFog) {
        m_SceneFallback.fog = new THREE.FogExp2(config.fogColor,config.fogDensity);
        // m_SceneFallback.fog = new THREE.Fog(config.fogColor,0.25,10000);
    }

    //Event on Window Resize
    window.addEventListener( 'resize', onWindowResize );
}


function RenderPostProcessedScene() {
    
    if(m_SceneFallback.fog != null) m_Scene.fog = null;

    if (m_BloomComposer != null) {
        m_Scene.background = m_SceneFallback.bloomOn;
        m_Scene.traverse( darkenNonBloomed );
        m_BloomComposer.render();
        m_Scene.traverse( restoreMaterial );
        m_Scene.background = m_SceneFallback.bloomOff;
    }
    
    if(m_SceneFallback.fog != null) m_Scene.fog = m_SceneFallback.fog;

    m_Composer.render();
}

function onWindowResize() {

    m_Composer.setSize(m_Container.clientWidth - 1, m_Container.clientHeight - 1  );
    m_BloomComposer.setSize(m_Container.clientWidth - 1, m_Container.clientHeight - 1  );

}

export {InitPostProcessing, RenderPostProcessedScene}
//Helper Functions

function darkenNonBloomed( obj ) {

    if ( /*obj.isMesh &&*/ m_BloomLayer.test( obj.layers ) === false ) {

        materials[ obj.uuid ] = obj.material;
        obj.material = darkMaterial;

    }

}

function restoreMaterial( obj ) {

    if ( materials[ obj.uuid ] ) {

        obj.material = materials[ obj.uuid ];
        delete materials[ obj.uuid ];

    }

}

const m_VertexShader = `
varying vec2 vUv;

			void main() {

				vUv = uv;

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

			}
`

const m_FragmentShader = `
uniform sampler2D baseTexture;
			uniform sampler2D bloomTexture;

			varying vec2 vUv;

			void main() {

				gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );

			}
`