<script>

</script>

<div id="loader" style="display: none;">

    <img src="./static/assets/loader-new2.gif" alt="">
    
    <div>
    <p id="loader-progress-text">Loading VaultHill Map</p>
    <progress max="100" id="loader-progress-bar" value="0"></progress>
    </div>
    
</div>

<style>
    #loader{
        z-index: 50;
    }
</style>