import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import j_Land_Info from '../landData/land-information.json';
import j_Sub_Island_Info from '../landData/sub-island-information.json';
import j_Island_Info from '../landData/island-information.json';
import j_District_Info from '../landData/district-information.json';

import j_Brands_Info from '../landData/brands-information.json';
import j_SoldLand_Info from '../landData/sold-lands-information.json';

import { GetLandCount } from './userinterface-2d';

import { GenerateLandID } from './land-id-generator';
import { CreateBrandUI } from './scene-3d-ui-builder';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'

let m_APIActiveLands = {};

const API_SERVER = process.env.MARKET_API || "https://mp-server.vaulthill.io/graphql";

const graphl_query = {
    query: `
        query {
            vlands {
                id
                tokenId
                tokenAddress
                name
                attributes {
                    key
                    value
                }

                balances {
                    ownerAddress
                }
                
                building {
                    tokenId
                    animationRenderUrl
                    buildingCustomization {
                        brandName
                        fileUrl
                        brandLogoUrl
                        headerUrl
                        description
                        deploymentDetails {
                            relatedVlandIds
                            walletAddress
                            datetime
                        }
                    }
                }
                activeListing{
                    type
                    assetId
                    assetAddress
                    makerAddress
                    isActive
                    auction {
                        startDate

                        endDate

                        type

                        bids {
                        id

                        listingId

                        owner

                        order

                        status

                        createdAt

                        assetName

                        activeUntil

                        amount {
                            currency

                            value
                        }
                        }

                        startingPrice {
                        currency

                        value
                        }
                    }

                    buyNow {
                        startDate

                        endDate

                        order

                        price {
                        currency

                        value
                        }
                    }
                }

            }
        }
    `,
    variables: { },
}

const m_DracoLoader = new DRACOLoader();

// Specify path to a folder containing WASM/JS decoding libraries.
m_DracoLoader.setDecoderPath( '/static/draco/' );

const loader = new GLTFLoader().setPath( '/static/' );

loader.setDRACOLoader(m_DracoLoader);


// var buildingObjects = [];
let brandsBuilding = [];

let m_LandBuildings = { };
var m_GlobalMapScale = 1;
var m_ObjectLayer = 1;

let land_count = 0;

//Build up Land Type Reference for Faster Cloning
for (let landtype in j_Land_Info) {
    m_LandBuildings[landtype] = {};
}

function ResetStoredLands() {
    for (let buildings in m_LandBuildings) {
        
        m_LandBuildings[buildings] = {};
    }
}


/**
 * Returns a GLTF Mesh from a Loaded Object
 * @param {string} gltf 
 * @returns {THREE.Mesh}
 */
function GetMeshFromGltf(gltf) {

    let mesh = null;

    gltf.scene.traverse(function (child) {
                    
        if (child.isMesh) {

            mesh = child;
        }
    });

    return mesh;
}



async function GetGLBModel(url) 
{
    const gltf = await new Promise((resolve,reject)=>{
        loader.load(url,
        (gltf)=>{
            resolve(gltf);
        },(progress)=>{

        },(error)=>{
            resolve(null);
        })
    })

    return gltf;
    
}


/**
 * This functions return a new or cloned copy of the land typology based on the land type and positions accordingly.
 * 
 * Time Complexity: O(1) 
 * 
 * Space Complexity: O(1)
 * @param {string} name 
 * @param {THREE.Material} material 
 * @returns {Promise<THREE.Mesh>}
 */
async function GetLandTypology(type,material,district) 
{
    let object = null;

    if (Object.keys(m_LandBuildings[type]).length == 0) {
           
        const building = await GetGLBModel('typology/c_'+type+'.glb');

        if (building == null) {
            console.warn("GLB model not found, skipping",type);
            return;
        }

        if (!type.includes("hill")) {
            
            //const mesh = GetMeshFromGltf(building);
           //m_LandBuildings[type] = mesh;

            //object = new THREE.Mesh(child,material);
            
            building.scene.traverse(function (child) {
                if (child.isMesh){

                    child.material.toneMapped = false;

                    child.material.color.set( j_District_Info[district].landColor );

                    // child.material.metalness = 0.1;
                    child.material.roughness = 0.1;

                    child.material.emissive.set( j_District_Info[district].landColor );

                    child.material.emissiveIntensity = 0.3;

                    // console.log(j_District_Info[district].landColor);
                }
            })

            const mesh = GetMeshFromGltf(building);
            m_LandBuildings[type].mesh = mesh;
            m_LandBuildings[type].material = mesh.material;

            // mesh.material = material;
            object = mesh;
            
        }
        
        else{

            // const meshGroup = new THREE.Group();
            building.scene.traverse(function (child) {
                  
                if (child.isMesh) {

                    // meshGroup.add(child);
                    // child.layers.enable( m_ObjectLayer );

                    if (child.material.name == "LayerHill") {
                        // console.log(child.material);
                        child.material.color.set(j_District_Info[district].layerHill);
                    }else if (child.material.name == "Layer1") {
                        child.material.color.set(j_District_Info[district].layer1);
                    }else if (child.material.name == "Layer2") {
                        child.material.color.set(j_District_Info[district].layer2);
                    }else if (child.material.name == "Layer3") {
                        child.material.color.set(j_District_Info[district].layer3);
                    }
                }
                
            });

            object = building.scene;

            m_LandBuildings[type].mesh = object;
            m_LandBuildings[type].material = object.material;
        } 
    }
    else{

        object = m_LandBuildings[type].mesh.clone();
        object.material = m_LandBuildings[type].material;
       
    }

    if(object != null) 
    {

        // console.log(object);
        
        // object.material = material;
        //object.castShadows = true;

        //object.castShadow = true; 
        //object.receiveShadow = true;
    }

    // buildingObjects.push(object);
    return object;
}

const ReferenceJSONLand = {
    type: "",
    x: 0,
    z: 0,
    district: "",
    instinct: "",
    price: "",
    landID: "",
    color: "",
    count: 0,
    sub_island: "",
    sub_island_count: 0
}


/**
 * This function spawns a VHC Land based on input JSON object and color information
 * 
 * Time Complexity: O(1) 
 * 
 * Space Complexity: O(1)
 * @param {ReferenceJSONLand} data 
 * @param {color}
 * @returns {Promise<THREE.Mesh>}
 */
async function CreateVHCLand(data = {})
{
    let object,geometry;

    data.color = data.color? data.color:"#ccc";

    data.type = data.type? data.type : "standard";

    const brand_material = new THREE.MeshBasicMaterial({color: "red"})

    if(data.type) 
    {
        const material = new THREE.MeshBasicMaterial( { color : j_District_Info[data.district].landColor } );
        object = await GetLandTypology(data.type,material,data.district);
    }


    if (object) {

        object.position.set(data.x * m_GlobalMapScale,0,data.z * m_GlobalMapScale);

        // object.rotation.set(0,GetRotationInRadians(data.rotation),0);
        
        //object.scale.multiplyScalar(data.scale * 5);
        const m_Scale = (m_GlobalMapScale/3.33) * GetLandScale(data.type);


        object.scale.set(m_Scale,m_Scale,m_Scale);
        
        
        object.vhc_name = data.type;
        object.district = data.district;
        object.instinct = data.instinct;
        object.price = data.price;
        object.sub_island = data.sub_island;
        object.sub_island_index = data.sub_island_count; 

        // object.landID = data.id? data.id: "null";
        object.landID = land_count;

        object.landQuantity = GetLandTypeCount(data.type);

        object.availableLands = GetLandTypeCount(data.type);

        // object.landData = GetLandIDData(land_count,object).landID;

        // object.availableLands = GetLandIDData(land_count,object).availableLands;

        object.layers.disable( m_ObjectLayer );

        
        /*if (data.isAvailable || (object.availableLands < object.landQuantity)) {

            object.tag = "vhc-land";
            object.layers.enable( m_ObjectLayer ); 
        }*/

        // land_count += GetLandTypeCount(data.type);

        // object.landID = GenerateLandID(data.count,data.type,data.sub_island,data.sub_island_count,data.district);

        // console.log(GenerateLandID(data.count,data.type,data.sub_island,data.sub_island_count,data.district));

        // console.log("Adding Object",object.name,object.position,object.scale);

        return object;
    }
    
}


const brand_material = new THREE.MeshBasicMaterial({color: "red"});

/**
 * Builds a Sub-Island based on the type, positions, rotates it accordingly and retuns a grouped object.
 * 
 * Time Complexity: O(n) 
 * 
 * Space Complexity: O(n)
 * @param {string} type 
 * @param {number} x 
 * @param {number} z 
 * @param {number} rotation_y_axis 
 * @param {number} district 
 * @returns {Promise<THREE.Group>}
 */
async function BuildSubIsland(type,x,z,rotation_y_axis,flip,district,sub_island_count, isAvailable)
{
    const sectionGroup = new THREE.Group();
    let m_Sub_Island = j_Sub_Island_Info[type];

    for (let lands in m_Sub_Island) {

        const data = m_Sub_Island[lands];
        
        if (!data.type.includes("hill")) {
            land_count += GetLandTypeCount(data.type);
        }

        const land = await CreateVHCLand({
            type: data.type,
            x: data.x,
            z: data.z,
            district: district,
            instinct: GetLandInstinct(district),
            price: GetLandPrice(data.type),
            landID: 1,
            sub_island: type,
            sub_island_count: sub_island_count,
            isAvailable: isAvailable
        });

        sectionGroup.add(land);
 
    }

    sectionGroup.position.set(x * m_GlobalMapScale,0,z * m_GlobalMapScale);
    sectionGroup.rotation.y = GetRotationInRadians(rotation_y_axis);

    if(flip == 1) sectionGroup.scale.x = -1;
    if(flip == 2) sectionGroup.scale.z = -1;

    return sectionGroup;
}

/**
 * Builds an Island based on the type, positions, rotates it accordingly and retuns a grouped object.
 * 
 * Time Complexity: O(n*2) 
 * 
 * Space Complexity: O(n*2)
 * @param {string} type 
 * @param {number} x 
 * @param {number} z 
 * @param {number} rotation_y_axis 
 * @param {string} district 
 * @returns {Promise<THREE.Group>}
 */
async function BuildIsland(type,x,z,rotation_y_axis,district, startCount)
{
    let m_Island = j_Island_Info[type];
    const districtSection = new THREE.Group();

    let sub_island_count = 0;
    land_count = startCount;

    // m_LandBuildings = {};
    ResetStoredLands();

    for (let sub_island in m_Island) {

        sub_island_count++;

        const data = m_Island[sub_island];

        // console.log(data);
        // console.log(sub_island,data.type);

        districtSection.add(await BuildSubIsland(data.type,data.x,data.z,data.rotation,data.flip,district,sub_island_count,data.available))
    }


    districtSection.position.set(x * m_GlobalMapScale,0,z * m_GlobalMapScale);
    districtSection.rotation.y = GetRotationInRadians(rotation_y_axis);

    // console.log(land_count);
    return districtSection;
}


/**
 * Builds all VHC Districts and add it to the scene
 * @param {Number} scale
 * @returns {Promise<THREE.Group>}
 */
async function BuildAllDistricts(scale,layer, userLands)
{
    m_GlobalMapScale = scale;
    m_ObjectLayer = layer;
    let storedLands = null;

    const map = new THREE.Group();


    //remap this

    //Upgrade system to be smarter when fetching expensive API Calls
    if(sessionStorage) {
        storedLands = JSON.parse(sessionStorage.getItem('lands-data'))
    }

    m_APIActiveLands = !storedLands ? await FetchNFTfromGraphQL(API_SERVER, graphl_query) : storedLands;

    if(sessionStorage) sessionStorage.setItem('lands-data',JSON.stringify(m_APIActiveLands))
    
    for (let islands in j_District_Info) {
        const data = j_District_Info[islands];

        // console.log(islands);

        map.add(await BuildIsland(data.type,data.x,data.z,data.rotation,islands,data.startNumber));
        
    }

    GenerateLandsData(map, userLands);

    return map;
}

export { BuildAllDistricts, GetGLBModel, GetRotationInRadians, m_ObjectLayer };

//#region Helper Functions

/**
 * Converts Degree to Radians
 * @param {number} rotation 
 * @returns 
 */
function GetRotationInRadians(rotation)
{
    return (rotation * (Math.PI/180));
}

/**
 * Get Land Instint Based on the District
 * @param {string} district 
 * @returns {string}
 */
function GetLandInstinct(district)
{
    return j_District_Info[district ? district : "alpha"].instinct;
}

//Helper Functions
/**
 * Get the Land Price Based on the Typology
 * @param {string} land_type 
 * @returns {string}
 */
function GetLandPrice(land_type)
{
    return j_Land_Info[land_type ? land_type : "standard"].price;
}

function GetLandScale(land_type)
{
    return j_Land_Info[land_type ? land_type : "standard"].scale;
}

function GetLandTypeCount(land_type)
{
    return j_Land_Info[land_type ? land_type : "standard"].quantity;
}


// const jsonDataServer = FetchLandAPI()


function FetchNFTfromGraphQL(url, query) {

    const remappedData = {};

    return new Promise((resolve, reject) =>{
        fetch(url,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(query)
        })
        .then(response => response.json())
        .then((data)=>{
            // resolve(data.data.assets);
            // console.log(data);

            for (let q in data.data.vlands) {

                remappedData[data.data.vlands[q].tokenId] = data.data.vlands[q];
            }

            resolve(remappedData);
        })
        .catch((e)=>{
            console.log(e);
            resolve({});
        })
    })
}


function GetLandIDData(count,data) {
    let landID = {};
    let availableLands = data.landQuantity;
    // GenerateLandID(count,data);

    for (let i = data.landQuantity; i > 0; i--) 
    {   
        const refNum = count - i + 1;
        const isAvailable = j_SoldLand_Info.basic.indexOf(refNum) === -1? true: false;
        

        if(!isAvailable) availableLands -= 1;
        

        landID[`${GenerateLandID(count - i + 1,data)}`] = {
            available: isAvailable,
            tokenID: count - i + 1
        };
        
    }

    return {landID,availableLands};
}

function GetLandIDDataAPI(count,data, userLands) {
    let landID = {},
        availableLands = 0,
        tradableLands = 0,
        brandsInfo = {},
        ownerLands = {},
        ownerLandsCount = 0,
        brandCount = 0,
        isAvailable = false,
        canTrade = false,
        isOwner = false;

    for (let i = data.landQuantity; i > 0; i--){

        const refNum = count - i + 1;

        isAvailable = false;
        canTrade = false;
        isOwner = false;
       
        if (m_APIActiveLands[refNum]) {
            isAvailable = true;
            canTrade = m_APIActiveLands[refNum].activeListing != null? true: false;
        }

        if(userLands[refNum]){
            isOwner = true;
            ownerLands[ownerLandsCount] = userLands[refNum];
            ownerLandsCount ++;
        }

        if(isAvailable) availableLands += 1;

        if(canTrade) tradableLands += 1;

        const isBrandLand = j_Brands_Info[refNum] != undefined? true: false;

        if (isBrandLand) {
            brandsInfo[brandCount] = j_Brands_Info[refNum];
            brandCount += 1;
        }

        landID[`${GenerateLandID(count - i + 1,data)}`] = {
            available: isAvailable,
            tokenID: count - i + 1,
            type: isAvailable? m_APIActiveLands[refNum].activeListing: null,
            isBrand: isBrandLand,
            canTrade: canTrade,
            isOwner: isOwner,
            deployed_building: m_APIActiveLands[refNum]?.building
        };
        
    }

    return {landID,availableLands,brandsInfo,tradableLands, ownerLands};
}

function GenerateLandsData(lands, userLands) {
    const worldPos = new THREE.Vector3();

    // console.log(userLands, 'UserLands');

    lands.traverse(land => {

        if(!land.vhc_name || !land.isMesh) return;

        // console.log(land);

        const landData = GetLandIDDataAPI(land.landID,land, userLands);

        land.availableLands = landData.availableLands;
        land.landData = landData.landID;
        land.brandData = landData.brandsInfo;
        land.tradableLands = landData.tradableLands;
        land.ownerLands = landData.ownerLands;

        // land.tag = "vhc-land";

        if (land.availableLands > 0) {

            land.tag = "vhc-land";
            land.layers.enable( m_ObjectLayer ); 
        }

        if (Object.keys(land.brandData).length > 0) {
            
            land.getWorldPosition(worldPos);

            const obj = CreateBrandUI(
                worldPos.x,
                j_Land_Info[land.vhc_name].brandUIHeight,
                worldPos.z,
                land.brandData[0],
                land);

            land.layers.enable( m_ObjectLayer ); 

            lands.add(obj);

        }

        if (Object.keys(land.ownerLands).length > 0) {
            
            land.getWorldPosition(worldPos);

            const obj = CreateBrandUI(
                worldPos.x,
                j_Land_Info[land.vhc_name].brandUIHeight,
                worldPos.z,
                {
                    name: land.ownerLands[0].name,
                    about: land.ownerLands[0].name,
                    website: land.ownerLands[0].tokenUri,
                    logo: land.ownerLands[0].imageUrl,
                },
                land,'owner');

            land.layers.enable( m_ObjectLayer ); 

            lands.add(obj);

        }
        
    })
}

function GenerateBrandLand(land) {
    let hasBrandLand = false;
    let brandInfo = {};

    for (let landID in land.landData) {
        const data = land.landData[landID];

        if (j_Brands_Info[data.tokenID]){

            hasBrandLand = true;
            brandInfo = j_Brands_Info[data.tokenID];
            break;
        }
        
    }

    if (hasBrandLand) {

        const obj = CreateBrandUI(land.position.x,j_Land_Info[data.type].brandUIHeight,land.position.z,brandInfo,land);
        
        sectionGroup.add(obj);
    }
}

//#endregion