import * as THREE from "three";

import { assetsBlob } from "./ViewerData";
import { m_CurrentInterior, DeSelectCurrentObject } from "../../viewer-main";

let m_ImageCustomiser, m_ImageViewer, m_ImageButtonChanger, m_ImageFile, m_CloseImage, m_ObjectControl;

let currentSceneObject = new THREE.Group(), currentUrl = null, UIState = false;
const ImageLoader = new THREE.TextureLoader();

function SetUIState(state = false) {
    UIState = state;
}


function SetStatus(config = {
    isError: false,
    text: 'Successfull'
}) {

    const statusParent = document.querySelector('#action-completed');
    statusParent.classList.remove('trans-hide');

    const status = document.querySelector('#action-completed-text');

    status.innerText = config.text;

    if(config.isError){
        status.style.color = 'red';
    }
    else{
        status.style.color = '#22B02E';
    }

    setTimeout(() => {
        statusParent.classList.add('trans-hide');
    }, 5000);
    
}

function ClosePanel() {
    OpenArtworkControlPanel(false);
    DeSelectCurrentObject();
}

function InitUI() {
    m_ImageCustomiser = document.querySelector('#customiser');
    m_ImageViewer = document.querySelector('#image-preview-viewer');
    m_ImageButtonChanger = document.querySelector('#change-artwork');
    m_ImageFile = document.querySelector('#image-file');
    m_CloseImage = document.querySelector('#close-viewer');

    m_ObjectControl = document.querySelector('#artwork-control-panel');

    m_ImageCustomiser.classList.add('hide');

    m_ImageButtonChanger.addEventListener('click',()=>{
        const files = m_ImageFile.files;

        if(files.length < 1){
            return alert('Uploaded File cannot be empty');
        }

        if(!currentSceneObject) return;

        if(GetFileType(files[0].name) != 'image'){
            return alert('Uploaded File is not supported');
        }
        
        currentSceneObject.userData['image'] = files[0];
        DisplayArtworkImage(currentSceneObject);

        AddArtworkToFrame(currentUrl,currentSceneObject);  

    })

    m_CloseImage.addEventListener('click',()=>{
        m_ImageCustomiser.classList.add('hide');
        m_ImageFile.value = '';
        UIState = false;
    })
}

function SaveArtworkInfo(config = {}) {

    if(!currentSceneObject) {
        return console.warn("scene object not found")
    }

    m_CurrentInterior.customisationConfig[currentSceneObject.name] = {
        ...m_CurrentInterior.customisationConfig[currentSceneObject.name],
        ...config
    }

}

function OpenArtworkUploader() {

    UIState = true;
    m_ImageCustomiser.classList.remove('hide');
}

function OpenArtworkControlPanel(state = true) {

    m_ObjectControl.classList.add('hide');

    if(state){
        m_ObjectControl.classList.remove('hide');

        const data = m_CurrentInterior?.customisationConfig[currentSceneObject?.name];

        window.postMessage({
            type: 'object-info-state',
            data: {
                ...data,
                pos_x: currentSceneObject?.parent.position.x,
                pos_y: currentSceneObject?.parent.position.y,
                pos_z: currentSceneObject?.parent.position.z,
                rot_z: currentSceneObject?.parent.rotation.z,
                scale: currentSceneObject?.parent.userData['uniformScale'] || 1
            }
        }, undefined);
    }
    else{

    }
    
}

/**
 * 
 * @param {*} object 
 * @param {*} isOwner 
 * @returns 
 */
function TriggerArtworkFrame(object = {}, isOwner = false) {
    
    currentSceneObject = object;

    if(!object.userData['custom'] && isOwner) {
        OpenArtworkControlPanel(false);
        OpenArtworkUploader();
        return
    };
    
    OpenArtworkControlPanel();

    /*if(!object.userData['custom']?.name){

        return alert("Cannot get image from cloud / Not connected");
    }*/

    GetImageFromArtwork(object);

}

/**
 * 
 * @param {THREE.Object3D} object 
 * @returns 
 */
function DisplayArtworkImage() {

    const object = currentSceneObject;

    if(!object.userData['custom']) {
        console.log("Error image not found");
        return;
    };
    
    const objectName = object.userData['custom'].name;
    const objectUrl = object.userData['custom'].blobUrl;

    if(!objectName){

        return console.warn("Cannot get image from cloud / Not connected");
    }

    GetImageFromArtwork(object);

    /*if(currentUrl) URL.revokeObjectURL(currentUrl);

    currentUrl = URL.createObjectURL(object.userData['image']);*/
    m_ImageViewer.setAttribute('src',objectUrl);

}

function ControlObject(params = {
    x,y,z,rot_z,scale
}) {

    const parent = currentSceneObject.parent;
    if(!parent) return;

    const opts = {
        x: parent.position.x,
        y: parent.position.y,
        z: parent.position.z,
        rot_z: THREE.MathUtils.radToDeg(parent.rotation.z),
        scale: parent.userData['uniformScale'] || 1,
        ...params
    }

    parent.position.set(opts.x,opts.y,opts.z);

    parent.rotation.z = THREE.MathUtils.degToRad(opts.rot_z);

    parent.scale.setScalar(opts.scale);
    parent.userData['uniformScale'] = opts.scale;

}



export {TriggerArtworkFrame, UIState, SetUIState,InitUI, AddArtworkToFrame, OpenArtworkUploader, OpenArtworkControlPanel, DisplayArtworkImage, ControlObject, currentSceneObject, SaveArtworkInfo, SetStatus, ClosePanel}

// Support Function

const valid_Images = ['jpeg','gif','png','jpg','svg','jfif','webp'];

const valid_Models = ['glb'];

function GetFileType(text = ''){

    let ext = 'null';

    try {
        const type = text.split('.');
        ext = type[type.length - 1].toLowerCase();
    } 
    catch (error) {
        ext = 'null';
    }

    if (valid_Images.indexOf(ext) > -1) 
    {
        return 'image';
    } 
    else if(valid_Models.indexOf(ext) > -1)
    {
        return 'model';
    }
    else
    {
        return 'unknown';
    }

}

/**
 * 
 * @param {string} url 
 * @param {THREE.Object3D} object 
 */
function AddArtworkToFrame(config = {
    blob: new Blob(),
    object: currentSceneObject,
    onComplete: ()=>{}
}) {

    console.log(currentSceneObject);
    
    const opts = {
        blob: new Blob(),
        object: currentSceneObject,
        onComplete: ()=>{},
        ...config
    }

    const url = URL.createObjectURL(opts.blob);

    const object = opts.object;
    UIState = false;


    if (object.children.length > 0) {
        object.remove(object.children[0])
    }
    
    ImageLoader.load(url,(texture)=>{

        texture.flipY = false;

        object.material.map = texture; 
        object.material.needsUpdate =  true;

        object.userData.custom = {
            name: opts.blob.name,
            blobUrl: url,
            type: 'artwork'
        }

        opts.onComplete();

        m_CurrentInterior.customisationConfig[object.name] = {
            ...m_CurrentInterior?.customisationConfig[object.name],
            src_name: opts.blob.name
        }

        m_CurrentInterior.customisationData = {
            ...m_CurrentInterior.customisationData,
            [object.name]: {
                file_name: opts.blob.name,
                blob: opts.blob
            }
        }

    })


}

/**
 * 
 * @param {THREE.Object3D} object 
 */
function GetImageFromArtwork(object ={}) {

    if (object.children.length < 1) {
        return;
    }
}