import * as THREE from "three";

import { Capsule } from "three/examples/jsm/math/Capsule.js";
import { Octree } from "three/examples/jsm/math/Octree.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { UIState } from "./UIController.js";


// Custom Class
import { PlayerAvatar } from "./PlayerAvatar";
import { PlayerRaycaster } from "./PlayerObjectRaycaster";

import EventEmitter from "events";


const clock = new THREE.Clock(),
      GRAVITY = 30,
      STEPS_PER_FRAME = 5;

class PlayerController {
  constructor({playerAvatarUrl = '/static/samples/avatar.glb', colliders = new THREE.Group(), isOwner = false, canvas }){

    this.canvas = canvas;
    this.playerAvatarUrl = playerAvatarUrl;
    this.colliders = colliders;
    this.playerGroup = new THREE.Group();
    this.isOwner = isOwner;
    this.events = new EventEmitter();

    this.playerCollider = new Capsule(
      new THREE.Vector3(0, 0.35, 0),
      new THREE.Vector3(0, 1, 0),
      0.35
    );

    this.playerVelocity = new THREE.Vector3();
    this.playerDirection = new THREE.Vector3();
    this.cameraPostion = new THREE.Vector3();
    this.cameraOffsetPosition = {x: 0, y: 0.5, z: -3};
    this.avatarPosition = new THREE.Vector3();
    this.worldOctree = new Octree();
    this.deltaTime = 0;
    this.avatarYRotationAngle = 0;

    this.horizontalAxis = 0;
    this.verticalAxis = 0;
    this.animationIncrementSpeed = 0.2;

    this.emoting = null;

    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.01,
      500
    );
    
    this.control = new OrbitControls( this.camera, this.canvas);
    this.keyStates = {};

    window.addEventListener("keydown", this.OnKeyDown.bind(this));
    window.addEventListener("keyup", this.OnKeyUp.bind(this));

    
    this.playerAvatar = new PlayerAvatar(this.playerAvatarUrl);
    this.playerAvatarMesh = new THREE.Group();
    this.playerRaycaster = new PlayerRaycaster(this.colliders,this.camera, this.isOwner);
  }

  async Initialize(){
    // Configure Control
    this.control.enablePan = false;
    this.control.enableDamping = true;
    this.control.dampingFactor = 0.5;
    this.control.rotateSpeed = 0.3;

    this.control.minDistance = 0.2;
    this.control.maxDistance = -this.cameraOffsetPosition.z;

    // Configure Collision
    this.worldOctree.fromGraphNode(this.colliders);

    // Configure Player Avatar
    await this.playerAvatar.Initialize()
    this.playerAvatarMesh = this.playerAvatar.getAvatarMesh();

    //Set Control Taaget
    this.control.minPolarAngle = 0.0174533 * -5; // radians
    this.control.maxPolarAngle = 0.0174533 * 85; // radians
    this.control.target.set(this.playerAvatarMesh.position);
    this.camera.position.set(this.playerAvatarMesh.position.x,this.playerAvatarMesh.position.y + this.cameraOffsetPosition.y,this.playerAvatarMesh.position.z - this.control.maxDistance);

    /*this.nametag = new THREE.Mesh(new THREE.BoxGeometry(1,1,0.1),new THREE.MeshBasicMaterial({
      color: 'white'
    }))*/

    // this.nametag.position.set(0,2,0);

    this.playerGroup.add(this.playerAvatarMesh,this.camera);

    return {
      camera: this.camera,
      group: this.playerGroup
    }
    // return this.playerGroup;
  }

  // Delete Avatar
  RemovePlayer(scene){
    this.control.dispose();
    scene.remove(this.playerGroup);
  }

  // Collision Function
  PlayerCollisions(){
    const result = this.worldOctree.capsuleIntersect(this.playerCollider);

    this.playerOnFloor = false;

    if (result) {
      this.playerOnFloor = result.normal.y > 0;

      if (!this.playerOnFloor) {
        this.playerVelocity.addScaledVector(
          result.normal,
          -result.normal.dot(this.playerVelocity)
        );
      }

      this.playerCollider.translate(result.normal.multiplyScalar(result.depth));
    }
  }

  // Movement function
  GetForwardVector() {
    this.camera.getWorldDirection(this.playerDirection);
    this.playerDirection.y = 0;
    this.playerDirection.normalize();

    return this.playerDirection;
  }

  GetSideVector() {
    this.camera.getWorldDirection(this.playerDirection);
    this.playerDirection.y = 0;
    this.playerDirection.normalize();
    this.playerDirection.cross(this.camera.up);

    return this.playerDirection;
  }

  MovePlayer(deltaTime) {

    let damping = Math.exp(-4 * deltaTime) - 1;

    if (!this.playerOnFloor) {
      this.playerVelocity.y -= GRAVITY * deltaTime;
      // small air resistance
      damping *= 0.1;
    }

    // console.log(playerCollider.end);
    this.playerVelocity.addScaledVector(this.playerVelocity, damping); 
    const deltaPosition = this.playerVelocity.clone().multiplyScalar(deltaTime); 
    this.playerCollider.translate(deltaPosition);

    // Avatar Position
    this.avatarPosition.copy(this.playerCollider.end);
    this.avatarPosition.setY(this.playerCollider.end.y - 1);
    this.playerAvatarMesh.position.copy(this.avatarPosition);

    // Avatar Rotation
    this.avatarYRotationAngle = Math.atan2( ( this.playerAvatarMesh.position.x - this.camera.position.x), (  this.playerAvatarMesh.position.z - this.camera.position.z ));

    this.events.emit('move', {
      pos: this.avatarPosition,
      rot: this.playerAvatarMesh.rotation,
      anim: [this.horizontalAxis, this.verticalAxis],
      emote: this.emoting
    });
    
    const state = this.GetInputPressState();
    
    if(!state) return;
    this.playerAvatarMesh.rotation.y = THREE.MathUtils.lerp(this.playerAvatarMesh.rotation.y, this.avatarYRotationAngle, deltaTime * 5);     
  }

  GetInputPressState(){

    for(const i in this.keyStates ){

      if(this.keyStates[i] == true){
        return true;
      }
    }

    return false;
  }
  // Input Function
  GetInput(deltaTime) {

    if(UIState) return;

    // gives a bit of air control
    const speedDelta = deltaTime * (this.playerOnFloor ? 12.5 : 8);

    // Forward Input
    const isForward = this.keyStates["KeyW"] || this.keyStates["ArrowUp"];
    const isBackward = this.keyStates["KeyS"] || this.keyStates["ArrowDown"];
    const isLeft = this.keyStates["KeyA"] || this.keyStates["ArrowLeft"];
    const isRight = this.keyStates["KeyD"] || this.keyStates["ArrowRight"];
    const isEmoting = this.keyStates["KeyC"];

    if (isEmoting) {
      this.emoting = 'dance'
      this.playerAvatar.SetAnimationState(this.emoting);
    }
    else{
      this.emoting = null;
    }

    if (isForward) {
      this.playerVelocity.add(this.GetForwardVector().multiplyScalar(speedDelta));

      this.verticalAxis += this.animationIncrementSpeed * speedDelta;
    }
    else if (isBackward) {

      this.playerVelocity.add(this.GetForwardVector().multiplyScalar(-speedDelta/5));

      this.verticalAxis -= this.animationIncrementSpeed * speedDelta;
    }
    else{
      this.verticalAxis = 0;
    }

    if(!isForward && !isBackward && !isLeft && !isRight && this.playerOnFloor && !isEmoting){
      //Maybe Smooth lerp
      this.playerVelocity.set(0,0,0);

    }

    // Side Input
    if (isLeft) {

      this.horizontalAxis += this.animationIncrementSpeed * speedDelta;

      this.playerVelocity.add(this.GetSideVector().multiplyScalar(-speedDelta/2));
    }
    else if (isRight) {

      this.horizontalAxis -= this.animationIncrementSpeed * speedDelta;

      this.playerVelocity.add(this.GetSideVector().multiplyScalar(speedDelta/2));
    }
    else{
      this.horizontalAxis = 0;
    }

    this.horizontalAxis = THREE.MathUtils.clamp(this.horizontalAxis, -1,1);
    this.verticalAxis = THREE.MathUtils.clamp(this.verticalAxis, -1,1);

    this.playerAvatar.SetAnimationVector(this.horizontalAxis, this.verticalAxis);
    // console.log(this.horizontalAxis, this.verticalAxis);

    //Disable Jump for now
    /*if (this.playerOnFloor) {
      if (this.keyStates["Space"]) {
        this.playerVelocity.y = 10 ;
        this.playerAvatar.SetAnimationState('jump');
      }
    }*/
  }

  // Camera Movement Function
  MoveCamera() {
    //y- Up calibration
    //z - makes it looks forward
    this.control.target.set(this.playerCollider.end.x,this.playerCollider.end.y + this.cameraOffsetPosition.y,this.playerCollider.end.z + 0.5);

    this.control.update();
  }

  // Support functions
  OnKeyUp(event){
    this.keyStates[event.code] = false;
  }

  OnKeyDown(event){
    this.keyStates[event.code] = true;
  }

  // Update Function
  UpdatePlayer() {
    
    this.deltaTime = Math.min(0.05, clock.getDelta()) / STEPS_PER_FRAME;

    for (let i = 0; i < STEPS_PER_FRAME; i++) {
      this.GetInput(this.deltaTime);
      this.MovePlayer(this.deltaTime);
      this.MoveCamera(this.deltaTime);
      this.playerAvatar.UpdateAnimation(this.deltaTime);
      this.playerRaycaster.RaycastObjects();
      this.PlayerCollisions();
    }

    
  }

  UpdatePlayerDelta(delta){
    this.GetInput(delta);
    this.MovePlayer(delta);
    this.MoveCamera(delta);
    this.playerAvatar.UpdateAnimation(delta);
    this.playerRaycaster.RaycastObjects();
    this.PlayerCollisions();
  }

}

export { PlayerController };