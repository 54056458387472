import * as THREE from 'three';

import {LandInfoPanel,OpenVHCModal,InitializModalEvents} from './userinterface-2d';
import j_District_Info from '../landData/district-information.json';


//Global Variables
let m_Scene, m_Raycaster,m_Camera, m_RaycastObjects, m_RaycastTag,INTERSECTED,mouseDown,mouseMove,mouseState, isAvailableforClick, m_Canvas;

const m_Pointer = new THREE.Vector2(); 
let m_MousePosition = {}; 

let m_HightLightMesh,m_OldMesh = null, timeout,touchTriggered = false;
const m_HightLightMaterial1 = new THREE.MeshBasicMaterial({color: 0x0033ff, transparent: true, opacity: 0.5});

import { m_VHCModal, m_ControlTheme, detailsPanel } from './userinterface-2d';

import { m_ProfileAccount } from './authenticate-user';


function isTouchDevice(){
    return window.ontouchstart !== undefined;
}

// alert(isTouchDevice());

window.addEventListener('mousemove', OnMouseMove, false );
window.addEventListener('mousedown', OnMouseClick);
window.addEventListener('mouseup', OnMouseRelease);

window.addEventListener('touchstart', OnTouchEnd, {passive: false});

window.addEventListener('touchmove', OnTouchMove, false);
// window.addEventListener('touchend', clearPickPosition);


/**
 * This functions creates an Hover Effect on the Mesh of a Raycasted Object
 * @param {Boolean} state 
 * @param {THREE.Mesh} mesh 
 */
function HoverMeshEffect(state,mesh)
{
    if (state) {

        if( m_HightLightMesh !== null) 
        {
            m_Scene.remove(m_HightLightMesh);
        }

        m_HightLightMaterial1.color.set(j_District_Info[mesh.district].color);
        //console.log(j_District_Info[mesh.district]);
        
        m_HightLightMesh = mesh.clone();

        mesh.getWorldPosition(m_HightLightMesh.position);
            
        m_HightLightMesh.rotation.set(0,mesh.parent.rotation.y,0);

        m_HightLightMesh.scale.set(m_HightLightMesh.scale.x * mesh.parent.scale.x,m_HightLightMesh.scale.y,m_HightLightMesh.scale.z * mesh.parent.scale.z);

        m_HightLightMesh.tag = " ";
        m_HightLightMesh.material = m_HightLightMaterial1;

        m_HightLightMesh.scale.multiplyScalar(1);
        m_Scene.add(m_HightLightMesh);

        m_OldMesh = mesh;
    }
    else{
        if( m_HightLightMesh !== null) 
        {
            m_Scene.remove(m_HightLightMesh);
        }
    }
}

/**
 * 
 * @param {THREE.Scene} scene 
 * @param {THREE.Camera} camera 
 * @param {THREE.Mesh[]} objects 
 * @param {String} tag 
 */
function InitializeRaycaster(config = {
    scene,
    camera,
    objects,
    tag,
    canvas,
    onModalOpened: ()=>{
        console.log("Modal was opened");
    },
    onModalClosed: ()=>{
        console.log("Modal was closed");
    }
}) 
{
    m_Raycaster = new THREE.Raycaster();
    m_Scene = config.scene;
    m_Camera = config.camera;
    m_RaycastObjects = config.objects;
    m_Canvas = config.canvas

    InitializModalEvents(config.onModalOpened,config.onModalClosed);

    m_RaycastTag = config.tag ? config.tag : null;

}

const pickPosition = {x: 0, y: 0};

function getCanvasRelativePosition(event, isMobile = false) {

    if(!m_Canvas){
        return {
            x: 0,
            y: 0
        }
    }

    const rect = m_Canvas.getBoundingClientRect();

    if (isMobile) {
        return {
            x: event.pageX - rect.left,
            y: event.pageY - rect.top,
        };
    }
    else{
        return {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };
    }
  
}

function setPickPosition(event, isMobile = false) {

    if(!m_Canvas) return;

    const pos = getCanvasRelativePosition(event, isMobile);

    pickPosition.x = (pos.x / m_Canvas.clientWidth ) *  2 - 1;
    pickPosition.y = (pos.y / m_Canvas.clientHeight) * -2 + 1;  // note we flip Y
}

function clearPickPosition() {
    // unlike the mouse which always has a position
    // if the user stops touching the screen we want
    // to stop picking. For now we just pick a value
    // unlikely to pick something
    pickPosition.x = -100000;
    pickPosition.y = -100000;
}

function OnMouseRelease(e) {

    mouseState = false;

    // clearPickPosition();
}


let timeout_click, timeout_unclick;

function OnMouseClick(event) {
   
    mouseDown = false;

    mouseState = true;

    if (timeout_click !== undefined) {
        window.clearTimeout(timeout_click);
    }

    timeout_click = window.setTimeout(function () {
        mouseDown = true;
    }, 200);

}

function OnMouseMove( event ) {

    mouseMove = true;

    /*m_Pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    m_Pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;*/

    m_MousePosition.x = event.clientX;
    m_MousePosition.y = event.clientY;


    setPickPosition(event);

    if (timeout !== undefined) {
        window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(function () {
        mouseMove = false;
    }, 50);

    
}


const m_Clickable = ["BUTTON","SPAN"];

function OnTouchEnd(event) {
    // event.preventDefault();

    if (m_ProfileAccount.contains(event.target) || m_VHCModal.contains(event.target) || m_Clickable.indexOf(event.target.tagName) != -1) {
        
    }
    else{
        event.preventDefault();
    }

    //Fall back pointer using Vector 2
    /*m_Pointer.x = (event.changedTouches[0].pageX  / window.innerWidth) * 2 - 1;
    m_Pointer.y = -(event.changedTouches[0].pageY  / window.innerHeight) * 2 + 1;*/

    m_MousePosition.x = event.changedTouches[0].pageX;
    m_MousePosition.y = event.changedTouches[0].pageY;


    setPickPosition(event.changedTouches[0], true);

    touchTriggered = true;
}

function OnTouchMove(event) {

    mouseMove = true;

    setPickPosition(event.changedTouches[0], true);

    if (timeout !== undefined) {
        window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(function () {
        // trigger the new event on event.target, so that it can bubble appropriately
        mouseMove = false;
    }, 50);
}




/**
 * 
 * @returns 
 */
function RaycastObjects(isHotspotHovered) {

    if((mouseDown && mouseMove) || mouseMove || mouseState) {

        return;
    } 

    if (isHotspotHovered) {

        ResetRaycast();
        return;
    }
    
    m_Raycaster.setFromCamera( pickPosition, m_Camera );

    const intersects = m_Raycaster.intersectObjects( m_RaycastObjects );

    //If we are orbiting return!

    if ( intersects.length > 0 ) {

        if(intersects[ 0 ].object.tag != m_RaycastTag) 
        {
            if ( INTERSECTED ) INTERSECTED.material.color.setHex( INTERSECTED.currentHex );

            INTERSECTED = null;

            document.body.style.cursor = "default";
            
            LandInfoPanel(false);
            HoverMeshEffect(false);
            return;
        }

        if ( INTERSECTED != intersects[ 0 ].object ) {

            isAvailableforClick = false;
            if ( INTERSECTED ) INTERSECTED.material.color.setHex( INTERSECTED.currentHex );

            INTERSECTED = intersects[ 0 ].object;

            // LandInfoPanel(true,INTERSECTED.district,INTERSECTED.vhc_name,INTERSECTED.instinct,INTERSECTED.availableLands);
            LandInfoPanel(true,INTERSECTED.district,INTERSECTED.vhc_name,INTERSECTED.instinct,INTERSECTED.tradableLands);

            INTERSECTED.currentHex = INTERSECTED.material.color.getHex();
            //INTERSECTED.material.color.setHex( 0xff0000 );
            //INTERSECTED.material.color.setHex( 0xff0000 );

            HoverMeshEffect(true,INTERSECTED);
            //Cursor change
            document.body.style.cursor = "pointer";

        }
        else{

            isAvailableforClick = true;

            //Mobile Open Modal
            if (isAvailableforClick && touchTriggered) {
                OpenVHCModal(INTERSECTED);
            }

            //PC Open Modal
            if (isAvailableforClick && mouseDown) {
                OpenVHCModal(INTERSECTED);
            }

        }

    } else {

        if(INTERSECTED == null) return;

        ResetRaycast();
        /*INTERSECTED = null;

        document.body.style.cursor = "default";
        LandInfoPanel(false);
        HoverMeshEffect(false);*/
    }

    touchTriggered = false;
    mouseDown = false;
}

function ResetRaycast() {
    INTERSECTED = null;

    document.body.style.cursor = "default";
    LandInfoPanel(false);
    HoverMeshEffect(false);
}

export { InitializeRaycaster,RaycastObjects, m_Scene, m_MousePosition, mouseState, mouseMove };

