<script>
    let state = true;

    const onboardKey = '_vh_ob';

    function SetDate(data) {

       if (!window.localStorage)  return null;

        window.localStorage.setItem(onboardKey,data)

    }

    function GetData() {
        if (!window.localStorage)  return null;

        return window.localStorage.getItem(onboardKey);
    }

    const hasOnboard = GetData();

</script>

{#key state}

    {#if state && !hasOnboard}

        <section>
            <div class="content" id="welcome-panel-parent">
                <img src="/static/assets/vhc-logo-standalone.png" alt="">
                <h3>Welcome to Vault Hill City!</h3>

                <div>
                    <h1>The 7 Districts</h1>

                    <h5>A Different Kind of Metaverse</h5>
                </div>

                <p>Vault Hill is the first decentralised urban planning-led metaverse designed to make you feel more human every time you visit. With the help of architects and urban planning experts, we’ve designed a wondrous city that is split into 4 islands, each containing 7 districts. These 7 districts are themed into experiences that support the basic human instincts.</p>

                <button on:click={()=>{
                    state = false;
                    SetDate('chjdl')
                }}>Got it</button>
            </div>

        </section>
        
    {/if}
    
{/key}
    

<style>
    section{
        display: flex;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: #000000B2;
        z-index: 20;
        justify-content: center;
        align-items: center;
    }

    .content{
        width: max-content;
        max-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 30px;
    }

    .content > div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 5px;
    }

    h1{
        color: #CEABF5;
        font-size: 48px;
        text-align: center;
    }

    h3{
        color: #FFFFFF;
        font-size: 28px;
        text-align: center;
    }

    h5{
        color: #FFFFFF;
        font-size: 18px;
        text-align: center;
    }

    p{
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        line-height: 1.8;
    }

    button{
        width: 200px;
        background-color: #A96CEF;
        font-size: 16px;
        color: #FFFFFF;
    }

    img{
        max-width: 100px;
    }

    @media screen and (max-width : 480px) {
        section{
            padding: 20px;
        }

        .content{
            max-width: 100%;
            row-gap: 20px;
        }

        h1{
            color: #CEABF5;
            font-size: 36px;
            text-align: center;
        }

        h3{
            color: #FFFFFF;
            font-size: 22px;
            text-align: center;
        }

        h5{
            color: #FFFFFF;
            font-size: 16px;
            text-align: center;
        }
    }
</style>