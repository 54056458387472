
import Map from './map/Map.svelte';
import Viewer from './viewer/Viewer.svelte';
import NotFound from './common/404.svelte';

const routes = {
    '/' : Map,
    '/viewer/:id' : Viewer,
    '*': NotFound
}

export {routes}