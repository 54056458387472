<script>

</script>

<div id="viewer-canvas">

</div>

<style>
    #viewer-canvas{
        position: fixed;
        width: 100%;
        height: 100%;
    }
</style>