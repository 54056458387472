import Cookies from "js-cookie";
// import { CloseInfoPanel } from "./userinterface-2d";

import { isVland } from "../global/verify-token-type";
import { request, gql } from 'graphql-request';

import { StoreGuestSession, GetGuestSession, DeleteGuestSession } from "../global/user-session-info";


const JWT_HEADER_COOKIE = 'ck_hp';
const JWT_HEADER_SIG_COOKIE = 'ck_sig';
const m_JWTToken = Cookies.get(JWT_HEADER_COOKIE);
const m_JWTTokenSIG = Cookies.get(JWT_HEADER_SIG_COOKIE);

const API_SERVER = process.env.MARKET_API || "https://mp-server.vaulthill.io/graphql";


//Action Buttons
const SIGN_UP = process.env.SIGN_UP;
const LOG_IN = process.env.LOG_IN;
const PROFILE = process.env.PROFILE;
let panelState = false;

const USER_DETAILS = (token)=> gql`
{
    userByToken(token: "${token}") {
        firstname
        lastname
        email
        walletAddress
        disabled
        collections {
            name
        }
        avatarUrl
        avatarSnapshotUrl
        assets {
            tokenId
            name
            imageUrl
            animationUrl
            animationRenderUrl
            description
        }
    }
}
`

// var AuthenticatedFunctionCall =()=>{};


//Dom elements
let m_ProfileAccount; 

let m_CreateAccount, m_SignIn, m_Guest, m_ProfileModal, m_ProfileName, m_ProfileEmail; 

//Account
let m_ProfileHeader, m_ProfilePanel, m_ProfileLogOut, m_ProfileEdit, m_CurrentExecutionFunction = ()=>{};


function InitPanel() {
    m_ProfileAccount = document.querySelector('#vhcProfileModal'); 


    m_CreateAccount = document.querySelector('#p-create-account'); 
    m_SignIn = document.querySelector('#p-sign-in'); 
    m_Guest = document.querySelector('#p-guest'); 
    m_ProfileModal = document.querySelector('#vhcProfileModal');
    m_ProfileName = document.querySelector('#account-name'); 
    m_ProfileEmail = document.querySelector('#userEmail'); 

    //Account
    m_ProfileHeader = document.querySelector('.ProfileHeader'); 
    m_ProfilePanel = document.querySelector('#profile-panel'); 
    m_ProfileLogOut = document.querySelector('#user-sign-out'); 
    m_ProfileEdit = document.querySelector('#user-edit-profile'); 

    try {
        ['mousedown', 'touchstart'].forEach(e => {
            m_ProfileHeader.addEventListener(e,()=>{

                panelState = !panelState;

                if (panelState) {
                    // CloseInfoPanel();
                    m_ProfilePanel.classList.remove('hidepanel')
                }
                else{
                    m_ProfilePanel.classList.add('hidepanel')
                }
                
            })

            window.addEventListener(e,(evt)=>{

                if(!panelState) return;

                if (evt.target !== m_ProfilePanel && !m_ProfilePanel.contains(evt.target) && evt.target !== m_ProfileHeader  && !m_ProfileHeader.contains(evt.target)) {

                    m_ProfilePanel.classList.add('hidepanel');
                    panelState = false;
                }

            })

            m_ProfileLogOut.addEventListener(e,()=>{
                Cookies.remove(JWT_HEADER_COOKIE);
                Cookies.remove(JWT_HEADER_COOKIE, {path: '/', domain: '.vaulthill.io'});
                DeleteGuestSession();
                window.location.reload();
            })

            m_ProfileEdit.addEventListener(e,()=>{
                window.open(`${PROFILE}?next=${window.location.href}`,"_self");
            })
        });

        m_CreateAccount.addEventListener('click',()=>{
            window.open(`${SIGN_UP}?next=${window.location.href}`,"_self");
        })

        m_SignIn.addEventListener('click',()=>{
            window.open(`${LOG_IN}?next=${window.location.href}`,"_self");
        })

        m_Guest.addEventListener('click',async ()=>{

            SetGuestDetails({
                name: '#Guest',
                icon: 'G',
                callback: m_CurrentExecutionFunction
            })
        })
    } catch (error) {
        console.log(error);
    }
}

function SetGuestDetails({name, icon, callback} = {
    callback: ()=>{}
}) {

    m_ProfileModal.classList.add('hide');

    m_ProfileEmail.innerHTML = name;
    m_ProfileEdit.style.display = 'none';
    m_ProfileName.innerHTML = `<h1>${icon}</h1>`;

    StoreGuestSession({
        isGuest: true,
        name: 'Annoymous',
        avatarType: 'male'
    })

    callback? callback({}): null;
}
//Support Functions

const getCurrentUser = () => {

    if (m_JWTToken) {
        return JSON.parse(window.atob(m_JWTToken.split('.')[1]))
    }

    return null
}

const userDetails = (!m_JWTToken || !m_JWTTokenSIG)? null: `${m_JWTToken}.${m_JWTTokenSIG}`;

let m_CurrentUserDetails = {};

async function AuthenticateFunction(method = ()=>{}) {
    
    const isGuest = GetGuestSession();

    if(!m_ProfileModal) return;

    if(!userDetails){

        if(isGuest){

            SetGuestDetails({
                name: '#Guest',
                icon: 'G',
                callback: ()=>{
                    method({})
                }
            })

            return;
        }

        m_CurrentExecutionFunction = method;
        return;
    }

    m_ProfileModal.classList.add('hide');

    const userModel = await GetUserDataFromAPI(API_SERVER,USER_DETAILS(userDetails));

    if(!userModel){
        m_ProfileModal.classList.remove('hide');
        return;
    }

    // const userModel = sample_response.data.userByToken;

    if(!userModel.avatarSnapshotUrl){
        m_ProfileName.innerHTML = `<h1>${userModel.firstname.charAt(0).toUpperCase()}</h1>`;
    }
    else{
        m_ProfileName.innerHTML = `<img src="${userModel.avatarSnapshotUrl}" />`;
    }

    m_ProfileEmail.innerHTML = userModel.email;
    m_CurrentUserDetails = userModel;

    // console.log(userModel);

    const userLands = GetUserLands(userModel.assets !== null ? userModel.assets : []);

    m_CurrentUserDetails.validUserLands = userLands;
    method(userLands);
}

export {AuthenticateFunction, getCurrentUser, m_ProfileAccount, InitPanel, m_CurrentUserDetails}


// Support Function
function GetUserDataFromAPI(url, query, variables = {}) {

    return new Promise((resolve, reject) =>{
        fetch(url,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({query: query})
        })
        .then(response => response.json())
        .then((data)=>{
            resolve(data.data.userByToken);
            // resolve(sample_response.data.userByToken);
        })
        .catch((e)=>{
            resolve(null);
        })
    })
}

const sample_response = {
    
  "data": {
    "userByToken": {
      "id": "6377302d3dd2210c644b3926",
      "firstname": "Gbenga",
      "lastname": "Omowole",
      "email": "omowole.gbenga@gmail.com",
      "walletAddress": "0x53b4b97286815B11392BDAa0f884758B98B05878",
      "avatarUrl": "https://models.readyplayer.me/6377f69ba9869f44e5e751f6.glb",
      "avatarSnapshotUrl": "https://renderapi.s3.amazonaws.com/gB2jWryBE.png",
      "disabled": null,
      "assets": [
        {
          "tokenId": "111",
          "name": "Play - VLAND#111",
          "tokenAddress": "0x5b192639c791d092ac77d729ed6d7f1caebab0e6",
          "tokenUri": "https://api.thegraph.com/ipfs/QmdSaCP5fwKuHJWmZzpjawY7sjVXgw4ba6rJgVQNpomvrY",
          "imageUrl": "https://vaulthill-marketplace.mypinata.cloud/ipfs/QmZozcDrMw3RcUzq1ScUEPDdiSaSxyceuufmFA3cEoVSdb",
          "animationUrl": "https://vaulthill-marketplace.mypinata.cloud/ipfs/QmSbFkYz6DbagNfTYiwxZfYYEA73KijW1Eeze67BXMXMXj"
        },
        {
          "tokenId": "112",
          "name": "Play - VLAND#112",
          "tokenAddress": "0x5b192639c791d092ac77d729ed6d7f1caebab0e6",
          "tokenUri": "https://api.thegraph.com/ipfs/QmPt8VfTHHKGwsj4vvNSvSiUPT2uEC2zrDjobCT2RwPzdA",
          "imageUrl": "https://vaulthill-marketplace.mypinata.cloud/ipfs/QmZozcDrMw3RcUzq1ScUEPDdiSaSxyceuufmFA3cEoVSdb",
          "animationUrl": "https://vaulthill-marketplace.mypinata.cloud/ipfs/QmSbFkYz6DbagNfTYiwxZfYYEA73KijW1Eeze67BXMXMXj",
          "animationRenderUrl": null
        },
        {
          "tokenId": "12",
          "name": "Building Interior",
          "tokenAddress": "0x1eff4cefe4704d3f0b05e416808a0bf84e2f5076",
          "tokenUri": null,
          "imageUrl": "https://gateway.pinata.cloud/ipfs/QmeZ5ZRtgocvAKu7ejjtAmPRAKcqLQZ92rkTHkqYjte4qs",
          "animationUrl": "https://gateway.pinata.cloud/ipfs/QmdLEJDXAmHuFxp3W4FjgQqSWLFHUYK5RxWfDT92LvCLBy",
          "animationRenderUrl": "https://vhc-marketplace-assets-staging.s3.amazonaws.com/test-interior.glb",
          "customization_url": "update.zip",
          "collection-type": "building"
          
        },
        {
          "tokenId": "8",
          "name": "Motorbike",
          "tokenAddress": "0x1eff4cefe4704d3f0b05e416808a0bf84e2f5076",
          "tokenUri": null,
          "imageUrl": "https://gateway.pinata.cloud/ipfs/QmUihQn9SZfb6oqEdyphbQEGJnq3pysnJUaHdJgTGoyUb3",
          "animationUrl": "https://gateway.pinata.cloud/ipfs/QmQuGBsM7tCziP5VK63z2CGbe3FZBmxy5dBGyX1AUBCGjo",
          "animationRenderUrl": "https://vhc-marketplace-assets-staging.s3.us-east-2.amazonaws.com/motorbike.glb"
        }
      ],
      "roles": [
        "USER"
      ]
    }
  }
}

function GetUserLands(assets = []) {

    const landData = {};

    for (const asset of assets) {
        
        if (asset.name.includes('VLAND')) {
            landData[asset.tokenId] = asset;
        }
        
    }

    return landData;
}

// To get user Assets Land, NFT's **
// Get a public list of lands, owners and building installed - 
// Store a zip file,**







