<script>

	import { onMount } from 'svelte';
	import Router from 'svelte-spa-router'


	// import Map from './map/Map.svelte';
	// import Viewer from './viewer/Viewer.svelte';
	import {routes} from './routes';
	
	

	onMount(()=>{

	})
 
</script>

<main>
	<!-- <Map/> -->

	<!-- <Viewer/> -->
	<Router {routes} />

		
</main>

<style>
	
</style>