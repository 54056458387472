<script>
    import {onMount} from 'svelte';

    onMount(()=>{
        const leaveBtn = document.querySelector('#leave-button');

        const leavePanel = document.querySelector('#leave-panel');
        const cancelLeaveBtn = document.querySelector('#leave-cancel');
        const enterLeaveBtn = document.querySelector('#leave-enter');

        leaveBtn.addEventListener('click',()=>{
            leavePanel.classList.remove('hide');
        })

        cancelLeaveBtn.addEventListener('click',()=>{
            leavePanel.classList.add('hide');
        })

        enterLeaveBtn.addEventListener('click',()=>{
            window.location.href = '/';
        })
    })

</script>


<div class="controls">
        
    <button id="leave-button">
        <img src="./static/assets/logout.svg" alt="logout icon">
        <p>LEAVE</p>
    </button>

    <div id="leave-panel" class="hide">
        <div class="leave-panel-content">
            <h1>Leave Experience</h1>

            <div>
                <button id="leave-cancel">Cancel</button>
                <button id="leave-enter">Leave</button>
            </div>
        </div>
    </div>
    
</div>

<style>
    .controls{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px;
    }   

    

    #leave-button{
        background-color: #F1F1F1;
        border-radius: 25px ;
        /* padding: 10px 20px; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        column-gap: 5px;
        min-width: 110px;
        /* height: 100px; */
    }

    #leave-button img{
        max-width: 20px;
        max-height: 20px;
        
    }

    #leave-button p{
        margin: 0;
    }

    #leave-panel{
        position: fixed;
        top: 0;
        left: 0;
        background-color: #1b1b116c;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .leave-panel-content{
        background-color: #F1F1F1;
        border-radius: 10px;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        justify-content: center;
        align-items: center;
    }

    .leave-panel-content > div{
        display: flex;
        justify-content: center;
        column-gap: 10px;
        
    } 

    .leave-panel-content > div > button{
        min-width: 120px;
        font-weight: 600;
    }

    #leave-cancel{
       border: 1px solid #A96CEF; 
       color: #A96CEF;
    }
    
    #leave-enter{
        background-color: #A96CEF;
        color: #f3f3f3;
    }

</style>