

<script>

</script>

<section id="vhcProfileModal" class="profileModal">
      
    <div class="p_leftSection">
    <div class="p_leftSection_cover"></div>
    <img src="./static/assets/vhc-logo-black-min.png" alt="">
    </div>

    <div class="p_rightSection">

        <div class="p_Header">
        <h1>Welcome to Vault Hill City - Interactive Map</h1>
        <p>Choose a way to enter the experience</p>
        </div>

        <div class="p_ActionButtons">
        <button id="p-create-account">
            <img src="../static/assets/create-account.svg" alt="create account">
            <h1>Create account</h1>
            <p>Create an account to fully enjoy Vault Hill City</p>
        </button>

        <button id="p-sign-in">
            <img src="../static/assets/sign-in.svg" alt="sign in">
            <h1>Sign in</h1>
            <p>Continue your Vault Hill City experience</p>
        </button>

        <button id="p-guest">
            <img src="./static/assets/guest.svg" alt="guest">
            <h1>Guest</h1>
            <p>Your information will be locally stored and your experience limited</p>
        </button>
        </div>
    </div>

</section> 

<style>
    #vhcProfileModal{
        z-index: 100;
    }
</style>
