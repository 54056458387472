<script>

</script>

<div id="map-canvas">

</div>

<style>
    #map-canvas{
        position: fixed;
        width: 100%;
        height: 100%;
    }
</style>