import * as THREE from "three";

import { TriggerArtworkFrame, UIState, InitUI } from "./UIController";


class PlayerRaycaster {

    constructor(raycastObjects, camera, isOwner) {
        this.m_RaycastObjects = raycastObjects;
        this.m_Camera = camera;
        this.m_Raycaster =  new THREE.Raycaster();
        this.m_Mouse = new THREE.Vector2();
        this.currentObject = null;
        this.isOwner = isOwner;
        this.m_HightLightMesh = null;
        this.m_HightLightMaterial1 = new THREE.MeshStandardMaterial({color: 0x8a22f2, emissive: 0x8a22f2});

        window.addEventListener('mousemove', this.setPickPosition.bind(this), false );
        window.addEventListener('click', this.onMouseClick.bind(this), false );

        // this.m_Raycaster.layers.set(0);
        InitUI();
    }

    setPickPosition(event) {
        this.m_Mouse.x = ( event.clientX / window.innerWidth) * 2 - 1;
        this.m_Mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    }

    deselectObject(){

        if( this.m_HightLightMesh !== null){

            this.m_RaycastObjects.remove(this.m_HightLightMesh);
        }
    }

    addSelectionBox(){

        if(!this.currentObject.parent) return;

        this.deselectObject();

        this.m_HightLightMesh = this.currentObject.parent.clone();

        this.currentObject.parent.getWorldPosition(this.m_HightLightMesh.position);
        this.currentObject.parent.getWorldQuaternion(this.m_HightLightMesh.rotation);
        this.currentObject.parent.getWorldScale(this.m_HightLightMesh.scale);

        this.m_HightLightMesh.scale.multiplyScalar(1.015);
        this.m_HightLightMesh.remove(this.m_HightLightMesh.children[0]);

        this.m_HightLightMesh.material = this.m_HightLightMaterial1;
        this.m_RaycastObjects.add(this.m_HightLightMesh);
    }

    onMouseClick(){
        if(!this.currentObject) return;

        this.addSelectionBox();
        TriggerArtworkFrame(this.currentObject, this.isOwner);
    }

    RaycastObjects(){

        if(UIState) {
            this.currentObject = null;
            return;
        };

        this.m_Raycaster.setFromCamera( this.m_Mouse, this.m_Camera );

        const intersects = this.m_Raycaster.intersectObjects( this.m_RaycastObjects.children );

        //If we are orbiting return!
        if ( intersects.length > 0 ) {

            this.currentObject = intersects[ 0 ].object;

            if (intersects[ 0 ].object.name.includes('vhc_artwork')) {
                document.body.style.cursor = 'pointer';
            }
            else if(intersects[ 0 ].object.parent.name.includes('vhc_artwork')){
                this.currentObject = intersects[ 0 ].object.parent;
                document.body.style.cursor = 'pointer';
            }
            else{
                document.body.style.cursor = 'default';
                this.currentObject = null;
            }

        } 
    }

    RaycastArtwork(){
        // document.body.style.cursor = 'pointer';
    }
}

export {PlayerRaycaster}

// Support Function