
import { m_CurrentUserDetails } from "../map-functions/authenticate-user";

const API_SERVER = process.env.MARKET_API || "https://mkt-server-dev.vaulthill.io/graphql";


function VerifyOwnerShip(landID: string) {
    const userData = m_CurrentUserDetails;
    const assets = userData?.validUserLands;

    if(!assets){
        return {
            state: false,
            avatar: undefined
        };
    }
    
    for(const index in assets){
        const land = assets[index];

        if(land.tokenId == landID) {
            return {
                state: true,
                avatar: userData.avatarUrl
            };
        }
    }
    

    return {
        state: false,
        avatar: userData.avatarUrl
    };

}

/*{
    "tokenId": "357",
    "tokenAddress": "0x69f0f4bd8c673142a81a96cc3075602021070814",
    "minterAddress": null,
    "name": "Play - VLAND#357",
    "imageUrl": "https://vaulthill-marketplace.mypinata.cloud/ipfs/QmZozcDrMw3RcUzq1ScUEPDdiSaSxyceuufmFA3cEoVSdb",
    "balances": [
        {
            "ownerAddress": "0xfebca517126d22a44efe34d06360d14d127b2751"
        }
    ],
    "building": {
        "tokenId": "7",
        "animationRenderUrl": "https://vhc-marketplace-assets-dev.s3.us-east-2.amazonaws.com/TheOasisPlaze_A.glb",
        "buildingCustomization": {
            "brandName": null,
            "fileUrl": null,
            "brandLogoUrl": null,
            "headerUrl": null,
            "description": null,
            "deploymentDetails": {
                "relatedVlandIds": [
                    "357"
                ],
                "walletAddress": "0x565eCe738084b8cFdD20f3aA97dB1fA3Eafd24B9",
                "datetime": "25/01/2023"
            }
        }
    }
}*/

function VerifyOwnerShipv2(buildingDetails) {

    const userData = m_CurrentUserDetails;    
    
    const balances = buildingDetails?.balances;    

 
    try {
        if(Array.isArray(balances)){

            if(balances.length > 0){

                if(userData?.walletAddress.trim().toLowerCase() == balances[0].ownerAddress.trim().toLowerCase()){                

                    return {
                        state: true,
                        avatar: userData?.avatarUrl
                    };
                }

            }
        }
        
        const landWalletAddress = buildingDetails?.building?.buildingCustomization?.deploymentDetails?.walletAddress.trim().toLowerCase();

        if(landWalletAddress && landWalletAddress == userData.walletAddress.trim().toLowerCase()){
            return {
                state: true,
                avatar: userData?.avatarUrl
            };
        }
        
        return {
            state: false,
            avatar: userData.avatarUrl
        };
    } catch (error) {
        return {
            state: false,
            avatar: userData.avatarUrl
        };
    }

}


// const url

const query =(landID: string)=> `
{
    vland(tokenId: "${landID}") {
        tokenId
        tokenAddress
        minterAddress
        
        name
        imageUrl

        balances {
            ownerAddress
        }

        building {
            tokenId
            animationRenderUrl
            buildingCustomization {
                brandName
                fileUrl
                brandLogoUrl
                headerUrl
                description
                deploymentDetails {
                    relatedVlandIds
                    walletAddress
                    datetime
                }
            }
        }
    }
}`


type buildingInfo = {
    building?: {
        animationRenderUrl?: string
        buildingCustomization:{
            fileUrl: string
        }
    }
}

function GetLandBuildingInfo(landID: string) {
    
    return new Promise<buildingInfo>((resolve, reject) =>{
        fetch(API_SERVER,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({query: query(landID)})
        })
        .then(response => response.json())
        .then((data)=>{
            const res: buildingInfo = data.data.vland;
            resolve(res);
        })
        .catch((e)=>{

            const res: buildingInfo = {};            
            resolve(res);
        })
    })
}

export {VerifyOwnerShip, GetLandBuildingInfo, VerifyOwnerShipv2}

