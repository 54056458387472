import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";


let player_animations = {
  idle: {
    src: "/static/animations/breathing-idle.fbx",
    clip: null
  },
  forward:  {
    src: "/static/animations/walking.fbx",
    clip: null
  },
  backward:  {
    src: "/static/animations/walking-backwards.fbx",
    clip: null
  },
  jump:  {
    src: "/static/animations/jump.fbx",
    clip: null
  },
  left:  {
    src: "/static/animations/left-walk.fbx",
    clip: null
  },
  right:  {
    src: "/static/animations/right-walk.fbx",
    clip: null
  },
  dance:  {
    src: "/static/animations/hip-hop-dance.fbx",
    clip: null
  },
};


/*const player_animations = {
  idle: "/static/animations/breathing-idle.fbx",
  forward: "/static/animations/walking.fbx",
  backward: "/static/animations/walking-backwards.fbx",
  jump: "/static/animations/jump.fbx",
  left: "/static/animations/left-walk.fbx",
  right: "/static/animations/right-walk.fbx",
  dance: "/static/animations/hip-hop-dance.fbx"
};*/

class PlayerAvatar  {

  constructor(avatarUrl = ''){

    this.avatarUrl = avatarUrl;

    this.m_AvatarLoadingManager = new THREE.LoadingManager();
    this.m_GLBLoader = new GLTFLoader(this.m_AvatarLoadingManager);
    this.m_FBXLoader = new FBXLoader(this.m_AvatarLoadingManager);
    this.modelReady = false;
    this.prevAnimation = null;
    this.currentAnimation = null;
    this.additiveAnimation = null;
    this.prevAdditiveAnimation = null;
    this.avatar = new THREE.Group();

    this.clips = {};
    this.idleBlend = 0;
    
  }

  async Initialize(){

    const state = await new Promise((resolve, reject)=>{

      this.m_GLBLoader.load(this.avatarUrl,(gltf)=>{

        this.m_Mixer =  new THREE.AnimationMixer(gltf.scene);

        gltf.scene.traverse(function (node) {
          if (node.isMesh) {
            node.castShadow = true;
            node.frustumCulled = false;
          }
        });

        Object.entries(player_animations).map(async ([index, data])=>{

          if(data.clip) {

            this.clips[index] = this.m_Mixer.clipAction(data.clip);

            return;
          };

          this.m_FBXLoader.load(data.src,(model)=>{

            if (!Array.isArray(model.animations)){
              console.log("Could not find any animations");
              return;
            }
            
            player_animations[index].clip = model.animations[0];

            this.clips[index] = this.m_Mixer.clipAction(player_animations[index].clip);
            

          },()=>{},(err)=>{
            console.log(err);
          })

        })

        this.m_AvatarLoadingManager.onLoad = ()=>{
          this.modelReady = true;
          this.avatar = gltf.scene;
          resolve({avatar: gltf.scene, state: 0})
        }

        this.m_AvatarLoadingManager.onError = ()=>{
          resolve({error: 'Could not get player avatar'})
        }
      })

    })

    return state;
  }

  SetAnimationState(state = 'idle') {

    // return;
    if (!this.modelReady) return;
    if (!this.avatar) return;

    this.additiveAnimation = this.clips[state];

    if(this.prevAdditiveAnimation == this.additiveAnimation) return;

    if(this.additiveAnimation){

      if(this.prevAnimation) this.prevAnimation.fadeOut(0.2);

      this.additiveAnimation
      .reset()
      .fadeIn(0.1)
      .play();

    }
    else if(horizontal != 0 && vertical == 0){
      
      if(horizontal > 0){
        this.currentAnimation = this.clips['left'];
      }
      else{
        this.currentAnimation = this.clips['right'];
      }
    }
    else{

      this.currentAnimation = this.clips['forward'];
    }


    if (this.currentAnimation == this.prevAnimation) return;

    if(this.prevAnimation) this.prevAnimation.fadeOut(0.2);

    this.prevAdditiveAnimation = this.additiveAnimation;

  }

  SetAnimationVector(horizontal,vertical) {

    if (!this.modelReady) return;
    if (!this.avatar) return;

    this.prevAnimation = this.currentAnimation;

    if(horizontal == 0 && vertical == 0){

      this.currentAnimation = this.clips['idle'];
      
    }
    else if(horizontal == 0 && vertical != 0){

      if(vertical > 0){
        this.currentAnimation = this.clips['forward'];
      }
      else{
        this.currentAnimation = this.clips['backward'];
      }
    }
    else if(horizontal != 0 && vertical == 0){
      
      if(horizontal > 0){
        this.currentAnimation = this.clips['left'];
      }
      else{
        this.currentAnimation = this.clips['right'];
      }
    }
    else{

      this.currentAnimation = this.clips['forward'];
    }


    if (this.currentAnimation == this.prevAnimation) return;

    if(this.prevAnimation) this.prevAnimation.fadeOut(0.2);

    if(this.additiveAnimation) {
      this.additiveAnimation.fadeOut(0.2);
      this.prevAdditiveAnimation = null;
    };

    this.currentAnimation
    .reset()
    .fadeIn(0.2)
    .play();

  }

  UpdateAnimation(deltatime = 0) {
    if(!this.modelReady) return;
    this.m_Mixer.update(deltatime);
  }

  getAvatarMesh(){
    return this.avatar;
  }

  getAnimationState(){
    return this.activeState
  }

}

export {PlayerAvatar}
