{
    "beta":{
        "name": "District Beta",
        "instinct": "Play",
        "type": "play",
        "isActive": true,
        "startNumber": 0,

        "color": "#0033ff",
        "landColor": "#3d7fc2",
        "uiColor": "#2D69F3",

        "layerHill":"white",
        "layer1":"#B0C7ED",
        "layer2":"#7FA5EF",
        "layer3":"#4D7EF2",
        
        "x": 30,
        "z": 50,
        "rotation": 0,
        "about":"Flock to District Beta when the urge to have fun and let loose strikes. Experiences here are abundant and enjoyable, like dancing, social gatherings, happy hours, concerts, group events and more."
    },
    "omega":{
        "name": "District Omega",
        "instinct": "Vitality",
        "type": "vitality",
        "isActive": true,
        "startNumber": 139,

        "uiColor": "#44CF9D",
        "color": "green",
        "landColor": "#008d2d",

        "layerHill":"white",
        "layer1":"#A2DDCD",
        "layer2":"#75E0C4",
        "layer3":"#47E2BD",

        "x": -30,
        "z": -50,
        "rotation": 0,
        "about": "Focused on looking inwards - Desire to thrive physically and mentally, to be calm, to be active, to feel healthy and be one's best self. This one it is!"
    },
    "delta":{
        "name": "District Delta",
        "instinct": "Romance",
        "type": "romance",
        "isActive": true,
        "startNumber": 276,

        "uiColor": "#E13E36",
        "color": "#f3403f",
        "landColor": "#f3403f",

        "layerHill":"white",
        "layer1":"#ffbfd1",
        "layer2":"#f3403f",
        "layer3":"#d32254",

        "x": 30,
        "z": -50,
        "rotation": 0,
        "about": "No humanistic metaverse would truly be complete without the possibility for love, something that abounds in District Delta. Experiences here, like speed dating avatar-to-avatar, might save you a lot of time wasted kissing frogs in the real world."
    },
    "alpha":{
        "name": "District Alpha",
        "instinct": "Curiosity",
        "type": "curiosity",
        "isActive": true,
        "startNumber": 407,

        "uiColor": "#F1A828",
        "color": "yellow",
        "landColor": "#f19900",

        "layerHill":"white",
        "layer1":"#ffda9b",
        "layer2":"#f19900",
        "layer3":"#ffa200",
        

        "x": 0,
        "z": 0,
        "rotation": 0,
        "about": "Indulge your desire to learn something new or perfect what you already know in almost any area or discipline. Experiences in District Alpha might involve taking an interactive course, attending a seminar, participating in a workshop, or having a fully immersive adventure focused around learning a specific skill or subject."
    },
    "pi":{
        "name": "District PI",
        "instinct": "Idealism",
        "type": "idealism",
        "isActive": true,
        "startNumber": 537,

        "uiColor": "#EC7F35",
        "color": "orange",
        "landColor": "#ba6700",

        "layerHill":"white",
        "layer1":"#ffd8af",
        "layer2":"#ffab53",
        "layer3":"#ff8200",

        "x": 57.5,
        "z": 0,
        "rotation": 0,
        "about": "Expand your mind and senses in District Pi. While the possibilities are endless, some experiences you might encounter here are empathy-based understanding sessions. For instance, actually feel what it is like to be elderly or to live under completely different conditions. On the other hand, you might spend your time here rooting out what it means to be your best and truest self."
    },
    "kappa":{
        "name": "District Kappa",
        "instinct": "Community",
        "type": "community",
        "isActive": true,
        "startNumber": 664,

        "uiColor": "#F05DA3",
        "color": "pink",
        "landColor": "#ff465f",

        "layerHill":"white",
        "layer1":"#ffa2ce",
        "layer2":"#a4004e",
        "layer3":"#900044",

        "x": -30,
        "z": 50,
        "rotation": 0,
        "about": "The physical world can feel very segregated when it comes to trying to establish and fit into a community. In District Kappa, all are welcome to attend group events and social gatherings that help to form friendships and a real-world support system."
    },
    "sigma":{
        "name": "District Sigma",
        "instinct": "Imagination",
        "type": "imagination",
        "isActive": true,
        "startNumber": 805,

        "uiColor": "#9033ED",
        "color": "purple",
        "landColor": "#9122ff",

        "layerHill":"white",
        "layer1":"#cf9eff",
        "layer2":"#9122ff",
        "layer3":"#340069",

        "x": -57.5,
        "z": 0,
        "rotation": 0,
        "about": "Explore big ideas and collaborate with others to make your greatest dreams come to life in District Sigma. In this district, if you can dream it, you can build it."
    }
}