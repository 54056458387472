<script>

</script>

<div id="detailsPanel" class="hide">

    <div id="detailsImageBG">
        <div id="detailsImageHolder"> 
            <img id="detailsImage" src="./static/assets/VHC_Vitality_Sub-island_Test-3.jpg" alt="VHC Metaverse" />
        </div>
    </div>
    
    <h1 id="detailsDistrict" class="GradientBackground"></h1>

    <h2 id="detailsName"></h2>
    
    <p id="detailsAbout"></p>

    <button id="panelClose" class="panelCloseButton CircularButton">X</button>
    
    <button id="panelActionButton" class="vhc_action_button panelVRButton hide"></button>
    
</div>

<style>

</style>