<script>
  import { onMount } from 'svelte';

  import { InitPanel } from '../../js/map-functions/authenticate-user';
  import {ExportSceneBuilding} from '../../js/viewer-main';

  export let isViewer = false;

  let isOwner = false;


  window.addEventListener('message', (e) => {

    if(e.origin != window.location.protocol + '//' + window.location.host){
      return;
    }

    const data = e.data;

    if(data?.type != 'building-state'){
      return;
    }
    
    isOwner = data?.data?.isLandOwner;

  });

  onMount(()=>{

    InitPanel();
    
  })

  async function SaveBuilding(e) {
    e.target.disabled = true;

    await ExportSceneBuilding();

    e.target.disabled = false;
  }
    

</script>

<section>

  <!-- User Profile -->
  {#if isViewer}

    {#key isOwner}

      {#if isOwner}
        <button on:click={SaveBuilding} id="viewer-save-button">
          <i class="bi bi-cloud-arrow-up-fill"></i>
          <p>SAVE</p>
        </button>
      {/if}
      
    {/key}
    
  {/if}
  
  <div id="userProfile" class="userProfile">
    <div class="ProfileHeader" id="account-name"></div>

    <div class="profilePanel hidepanel" id="profile-panel">
      <p id="userEmail"></p>
      <button id="user-edit-profile">Edit Profile</button>
      <button id="user-sign-out">Sign Out</button>
    </div>

  </div>
	
</section> 

<style>
	section{
    z-index: 10;
    position: fixed;
    display: flex;
    right: 0;
    top: 0;
    justify-content: right;
    column-gap: 10px;
    width: max-content;
    padding: 10px;
  }

  #viewer-save-button{
    background-color: #F1F1F1;
    border-radius: 25px ;
    /* padding: 10px 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    column-gap: 5px;
    min-width: 110px;
    height: max-content;
    

  }

  #viewer-save-button i {
    font-size: 17px;
    font-weight: 500;
  }
  
  #viewer-save-button:disabled{
    opacity: .5;
    pointer-events: none;
  }
</style>