<script>

</script>

<div id="landInfo" class="landinfoParent hide">
    <div  class="landInfo" id="landInfoChild">
    <div>
        
        <p>Land Type <span id="landInfoType" style="font-size: 20px;">Exclusive</span></p>

        <p>Human Instinct <span id="landInfoInstinct">Play</span></p>

        <p>District <span id="landInfoDistrict">Play</span></p>
    </div>
    
    <div class="landInfoTypeLand">
        <div class="circle-wrap">
        <div class="circle" id="landRadialFill1">
            <div class="mask full" id="landRadialFill2">
            <div class="fill" id="landRadialFill3"></div>
            </div>

            <div class="mask half">
            <div class="fill" id="landRadialFill4"></div>
            </div>

            <div class="inside-circle">
            <p>
                <span id="landInfoLands">4/4</span>
                <span>/</span>
                <span id="landInfoLandsTotal">4</span>
            </p>
            </div>
            
        </div>
        </div>

        
        <p style="text-align: center;">Available Lands</p>
    </div>
    
    <!-- <p style="font-size: 12px;">click to explore</p> -->
    </div>
</div>


<style>
    
</style>