import * as THREE from 'three';

import { OpenInfoPanel,BrandInfoPanel } from "./userinterface-2d";
import { Create3DUI } from "./userinterface-3d";

import j_District_Info from '../landData/district-information.json';

import { ControlLookAt } from './map-controls';

import { m_Scene, mouseState, mouseMove } from './land-raycast';

import { m_ObjectLayer } from './land-builder';


const m_CreatedUI = [];

let m_DistrictUIHolder = null;
function CreateDistrict3DUI(height,scale, onUIClick=()=>{},onHoverEnter=()=>{},onHoverExit=()=>{}) {

    const m_UIHeight = height;
    const m_DistrictUI = new THREE.Group();

    for (let district in j_District_Info) {

        const data = j_District_Info[district];

        data.imageLink = `./static/land-images/hotspot/${data.type}.svg`;

        m_DistrictUIHolder = Create3DUI({
            x: data.x * scale,
            y: m_UIHeight,
            z: data.z * scale,
            rotation_y: 0
        },CreateDistrictHotspot(data),
        ()=>
        {
            OpenInfoPanel(data);
            onUIClick(data.x,data.z);

            ControlLookAt(0,new THREE.Vector2(data.x,data.z));
        },"hotspot",()=>{
            onHoverEnter();
        }, ()=>{
            onHoverExit()
        });   
        
        m_DistrictUI.add(m_DistrictUIHolder);
        m_CreatedUI.push(m_DistrictUIHolder);
    }

    return m_DistrictUI;
}



let m_BrandUI = null, hasWebsiteLink = false;

function CreateBrandUI(x,y,z,data,land,type='brand') {

    m_BrandUI = Create3DUI({
        x: x,
        y: y,
        z: z,
        rotation_y: 0
    },
    CreateBrandHotspot(data, type),
    (el)=>{

        hasWebsiteLink = data.website != "";
        OpenInfoPanel({
            instinct: data.name,
            name: "",
            about: data.about,
            uiColor: "#434343",
            showButon: true,
            buttonText: "Visit Website",
            buttonEnabled: hasWebsiteLink,
            action: ()=>{
                window.open(data.website);
            },
            imageLink: data.logo,
            isBrandUI: true
        });

        ControlLookAt(1,land);

        SelectHotspot(true,land);

    },
    "hotspot",
    ()=>{

        if (!mouseState) {
            BrandInfoPanel(true,data.name);
        }
        
    },()=>{
        BrandInfoPanel(false);
    });

    m_CreatedUI.push(m_BrandUI);

    return m_BrandUI;
}


/**
 * 
 * @param {THREE.Camera} camera 
 */
function UILookAtCamera(camera) {
    
    m_CreatedUI.forEach((el)=>{

        if (el.vhc_name === "UIObject") {

            el.lookAt(camera.position);

            // el.rotation.y = Math.atan2( ( camera.position.x -  el.position.x ), ( camera.position.z -  el.position.z ) );
        }
    })
}

//Helper Functions
function CreateDistrictHotspot(data) 
{

    return `<div class="hexagone" style="background: linear-gradient(180deg, ${data.uiColor} 40%, #ddd 50%, #00000000 70%); background-size: 200% 200%">
        <div class="Hexagon">
          <img class="HexagonBG" src="/static/land-images/hotspot/polygon.svg" alt="Hexagon">
          <img class="HexagonIcon" src="${data.imageLink}" alt="${data.name}">
          <h1 style="color: ${data.uiColor}">${data.instinct}</h1>
          <p>${data.name}</p>
        </div>
    </div>`
}


function CreateBrandHotspot(data,type){
   if (type=='owner') {
         return `
        <div class="vhc-brands">
            <img src="/static/land-images/hotspot/location-brand-owner.svg"></img>
        </div>
        `
   }

    return `
    <div class="vhc-brands">
        <img src="/static/land-images/hotspot/location-brand.svg"></img>
    </div>
    `
}

let m_HightLightMesh = null, oldMesh = null;
const m_HightLightMaterial1 = new THREE.MeshStandardMaterial({color: "#bb4400", transparent: true, opacity: 0.5, emissive: "#bb4400", emissiveIntensity: 0.5});

function SelectHotspot(state = true, mesh) {

    if (state) {

        if( m_HightLightMesh !== null) 
        {
            m_Scene.remove(m_HightLightMesh);
            if(oldMesh !== null) oldMesh.layers.enable(m_ObjectLayer);
        }

        m_HightLightMesh = mesh.clone();
        oldMesh = mesh;

        mesh.getWorldPosition(m_HightLightMesh.position);
            
        m_HightLightMesh.rotation.set(0,mesh.parent.rotation.y,0);

        m_HightLightMesh.scale.set(m_HightLightMesh.scale.x * mesh.parent.scale.x,m_HightLightMesh.scale.y,m_HightLightMesh.scale.z * mesh.parent.scale.z);

        m_HightLightMesh.tag = " ";
        m_HightLightMesh.material = m_HightLightMaterial1;

        mesh.layers.disable(m_ObjectLayer);

        m_HightLightMesh.layers.enable(m_ObjectLayer);

        m_HightLightMesh.scale.multiplyScalar(1);
        m_Scene.add(m_HightLightMesh);
    }
    else{
        if( m_HightLightMesh !== null) 
        {
            m_Scene.remove(m_HightLightMesh);
            if(oldMesh !== null) oldMesh.layers.enable(m_ObjectLayer);
        }
    }
    
}


export { CreateDistrict3DUI, CreateBrandUI, UILookAtCamera, SelectHotspot };
