<script>

export let inputname = '';
export let longText = false;
export let disabled = false;
export let value = '';
export let onChange = ()=>{};

</script>

<div class="input-box">
    <div>
        <p>{inputname}</p>
    </div>

    {#if longText}
        <textarea disabled={disabled} on:change={(evt)=>{
            onChange(evt.target.value);                
        }}>{value}</textarea>
        
    {:else}

        <input type="text" value={value} disabled={disabled} on:change={(evt)=>{
            onChange(evt.target.value);                
        }}>
    {/if}
    
</div>

<style>
    p{
        font-size: 13px;
    }
    .input-box{
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    input{
        padding: 10px;
        width: 100%;
        font-size: 16px;
    }

    textarea{
        /* height: max-content; */
        font-size: 16px;
        padding: 10px;
        height: 150px;
        resize: none;
        font-family: inherit;
    }
</style>