const m_SessionStorage = window.sessionStorage;

const isGuestKey = '_vhc_guest';

function StoreGuestSession(data = {}) {
    m_SessionStorage.setItem(isGuestKey, JSON.stringify(data));
}

function GetGuestSession() {
    try {
        return JSON.parse(m_SessionStorage.getItem(isGuestKey) || '');
    } catch (error) {
        return null
    }
}

function DeleteGuestSession() {
    m_SessionStorage.removeItem(isGuestKey);
}


export {StoreGuestSession, GetGuestSession, DeleteGuestSession}