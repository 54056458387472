import j_Land_Info from '../landData/land-information.json';
import j_District_Info from '../landData/district-information.json';

import { LightMode, DarkMode } from './scene-builder';

import { GenerateLandID } from './land-id-generator';

import { IsUIHovered, SetUIHoveredState } from './userinterface-3d';
import { SelectHotspot } from './scene-3d-ui-builder';

import { m_MousePosition } from './land-raycast';
import process from 'process';


//Land Info Selectors
let m_LandInfo, m_LandInfoDistrict, m_LandInfoType, m_LandInfoInstinct, m_LandInfoLands, m_LandInfoLandsTotal, m_LandInfoLandsFill; 

let m_RadialFill, m_RadialFill2, m_RadialFill3 , m_RadialFill4;


//Modal Selectors
let m_VHCModal, m_VHCModalClose, m_VHCModalDataHolder, m_VHCModalDataHeader, m_VHCModalDataHeaderType; 


//Panel Selectors
let detailsPanel, panelClose, panelName, panelDistrict, panelAbout, panelImage, panelImageBG, panelActionButton, panelImageHolder; 


let m_ControlTheme;


const MARKET_URL = process.env.MARKET_URL;

/*detailsPanel.addEventListener('mouseenter',()=>{
    SetUIHoveredState(true);
});

detailsPanel.addEventListener('mouseleave',()=>{
    SetUIHoveredState(false);
});*/

//Control Menu

let m_btn_mode, m_ImageDay, m_ImageNight; 

//Brands Panel
let m_BrandInfo, m_BrandInfoText; 


/*['mousedown', 'touchstart'].forEach(e => {
    m_VHCModalClose.addEventListener(e,CloseVHCModal);
    m_VHCModal.addEventListener(e,(ev)=>{
        if (ev.target == m_VHCModal) CloseVHCModal();
    });
    panelClose.addEventListener(e,CloseInfoPanel);
    panelActionButton.addEventListener(e,()=>{
        panelActionButtonFunction();
    });
});


m_btn_mode.addEventListener('change',()=>{

    SwitchThemeMode(m_btn_mode.checked);    
})*/


let m_CurrentDistrict, m_Renderer, m_Modal_Opened = false, 
    f_OnModalOpened,f_OnModalClosed,
    w_width,w_height,bounds, divisor, radialString,
    landInfo_coordinate = {};


let panelActionButtonFunction = ()=>{};


function InitDomElements() {
    m_LandInfo = document.querySelector('#landInfo'); 
    m_LandInfoDistrict = document.querySelector('#landInfoDistrict'); 
    m_LandInfoType = document.querySelector('#landInfoType'); 
    m_LandInfoInstinct = document.querySelector('#landInfoInstinct'); 
    m_LandInfoLands = document.querySelector('#landInfoLands');
    m_LandInfoLandsTotal = document.querySelector('#landInfoLandsTotal');

    m_LandInfoLandsFill = document.querySelector('#landInfoLandsFill'); 

    m_RadialFill = document.querySelector('#landRadialFill1');
    m_RadialFill2 = document.querySelector('#landRadialFill2');
    m_RadialFill3 = document.querySelector('#landRadialFill3');
    m_RadialFill4 = document.querySelector('#landRadialFill4');


    //Modal Selectors
    m_VHCModal = document.querySelector('#vhcModal'); 
    m_VHCModalClose = document.querySelector('#vhc_modal_close'); 
    m_VHCModalDataHolder = document.querySelector('#vhc_modal_data'); 
    m_VHCModalDataHeader = document.querySelector('#vhc_land_header'); 
    m_VHCModalDataHeaderType = document.querySelector('#vhc_land_header_type'); 


    //Panel Selectors
    detailsPanel = document.querySelector('#detailsPanel');
    panelClose = document.querySelector('#panelClose'); 
    panelName = document.querySelector('#detailsName'); 
    panelDistrict = document.querySelector('#detailsDistrict'); 
    panelAbout = document.querySelector('#detailsAbout'); 
    panelImage = document.querySelector('#detailsImage'); 
    panelImageBG = document.querySelector('#detailsImageBG'); 
    panelActionButton = document.querySelector('#panelActionButton'); 
    panelImageHolder= document.querySelector('#detailsImageHolder'); 


    m_ControlTheme= document.querySelector('.ControlMenu');


    //Control Menu
    m_btn_mode = document.querySelector('#modeControl'); 
    m_ImageDay = document.querySelector('#day-day'); 
    m_ImageNight = document.querySelector('#day-night'); 

    //Brands Panel
    m_BrandInfo = document.querySelector('#brandInfo'); 
    m_BrandInfoText = document.querySelector('#brandInfoText'); 

    detailsPanel.addEventListener('mouseenter',()=>{
        SetUIHoveredState(true);
    });

    detailsPanel.addEventListener('mouseleave',()=>{
        SetUIHoveredState(false);
    });


    m_btn_mode.addEventListener('change',()=>{
        SwitchThemeMode(m_btn_mode.checked);    
    });

    ['mousedown', 'touchstart'].forEach(e => {
        m_VHCModalClose.addEventListener(e,CloseVHCModal);
        m_VHCModal.addEventListener(e,(ev)=>{
            if (ev.target == m_VHCModal) CloseVHCModal();
        });
        panelClose.addEventListener(e,CloseInfoPanel);
        panelActionButton.addEventListener(e,()=>{
            panelActionButtonFunction();
        });
    });
}



/**
 * This function displays information about the selected land.
 * 
 * Set show = false (disables Panel)
 * 
 * Set show = true (Enables Panel)
 * 
 * set state = 0 (Show Land Info)
 * 
 * set state = 1 (Show Brand Info)
 * @param {boolean} show 
 * @param {string} district 
 * @param {string} landType 
 * @param {string} instinct 
 */
function LandInfoPanel(show = false,district,landType,instinct,availableLands){

    if (show) 
    {
        EnableInterface(m_LandInfo);
        // m_LandInfo.style.opacity = 0;

        landInfo_coordinate = GetPanelCoordinate(m_LandInfo);

        m_LandInfo.style.left = landInfo_coordinate.x;
        m_LandInfo.style.top = landInfo_coordinate.y;
        
        //This allows us to update styles once per district
        if (m_CurrentDistrict != district) {
            
            m_LandInfo.style.background = `linear-gradient(180deg, ${j_District_Info[district].uiColor} 40%, #ddd 50%, #00000000 70%)`;

            m_LandInfo.style["background-size"] = "200% 200%";

            m_CurrentDistrict = district;
        }
        
        m_LandInfoDistrict.innerText = GetUpperCaseFirstLetter(district);
        m_LandInfoType.innerText = GetUpperCaseFirstLetter(landType);
        m_LandInfoInstinct.innerText = GetUpperCaseFirstLetter(instinct);

        m_LandInfoLands.innerText = availableLands;

        m_LandInfoLands.style.color = availableLands > 0? "#57C84D": "#1b1b1b";

        m_LandInfoLandsTotal.innerText = GetLandCount(landType);

        // m_LandInfoLandsFill.style.transform = "rotate(0deg)";

        divisor = availableLands/GetLandCount(landType);

        radialString = `rotate(${divisor * 180}deg)`;
        // m_RadialFill.style.transform = radialString;
        m_RadialFill2.style.transform = radialString;
        m_RadialFill3.style.transform = radialString;
        m_RadialFill4.style.transform = radialString;

        // m_LandInfo.style.opacity = 1;
    }
    else{
        // m_LandInfo.classList.remove("show");
        // m_LandInfo.classList.add("hide");

        DisableInterface(m_LandInfo);
    }
    
}

function InitializeUIEvents(renderer = null) {
    m_Renderer = renderer;
}

function InitializModalEvents(onModalOpened = ()=>{},onModalClosed = ()=>{}) {
    f_OnModalOpened = onModalOpened;
    f_OnModalClosed = onModalClosed;
}

let brand_coordinate = {};
function BrandInfoPanel(show = false,name = "") {
    if (show) {
        EnableInterface(m_BrandInfo);

        brand_coordinate = GetPanelCoordinate(m_BrandInfo);

        m_BrandInfo.style.left = brand_coordinate.x;
        m_BrandInfo.style.top = brand_coordinate.y;

        m_BrandInfoText.innerText = name;
    }
    else{
        DisableInterface(m_BrandInfo);
    }
}

// OpenVHCModal();

/**
 * Opens Modal Panel to purchase lands
 * 
 * Input parameter data is a JSON Object containing (district, instinct, LandID start, price and Type)
 * @param {JSON} data 
 * @returns 
 */
function OpenVHCModal(data) {

    SelectHotspot(false);

    m_VHCModal.style.display = "flex";

    if(!data || m_Modal_Opened) return;

    CloseInfoPanel();
    LandInfoPanel(false);

    // m_VHCModalDataHolder.innerHTML = "";
    // m_VHCModalDataHeader.innerText = `District ${GetUpperCaseFirstLetter(data.district)} - ${data.instinct}`;

    // m_VHCModalDataHeaderType.innerText = GetUpperCaseFirstLetter(data.vhc_name);

    // console.log(data);

    /*const fragment = document.createDocumentFragment('div');
    
    for (let land in data.landData) {

        fragment.appendChild(SpawnLandInterface(data,land,data.landData[land]));

    }*/

    const firstLand = Object.keys(data?.landData)[0];

    const landDetails = data?.landData[firstLand];

    const deploymentDetails = landDetails?.deployed_building;

    window.postMessage({
        type: 'land-details',
        data: {
            title: deploymentDetails?.buildingCustomization?.brandName? deploymentDetails?.buildingCustomization?.brandName: `VLAND#${data.landID}`,
            district: `${GetUpperCaseFirstLetter(data.instinct)} - District ${GetUpperCaseFirstLetter(data.district)}`,
            land_type: GetUpperCaseFirstLetter(data.vhc_name),
            experience_link: `#/viewer/${data.landID}`,
            // experience_link: `${MARKET_URL}${data.landID}`,
            description: deploymentDetails? (deploymentDetails?.buildingCustomization?.brandName)? deploymentDetails?.buildingCustomization?.description : 'No description' : `${data.vhc_name} piece of land`,
            experience_bg: deploymentDetails?.buildingCustomization?.headerUrl? deploymentDetails?.buildingCustomization?.headerUrl : `/static/land-images/${data.vhc_name}.png`,
            display_picture: deploymentDetails? deploymentDetails?.buildingCustomization?.brandLogoUrl : '/static/assets/vhc-logo-standalone.png'
        }
    }, undefined);

    // m_VHCModalDataHolder.append(fragment);
    f_OnModalOpened? f_OnModalOpened(): "";

    m_Modal_Opened = true;
}

/**
 * Closes the Modal Panel to purchase lands
 */
function CloseVHCModal() {
    m_VHCModal.style.display = "none";

    f_OnModalClosed? f_OnModalClosed(): "";

    m_Modal_Opened = false;
}


function LandAvailability(listing) {
    if (listing == null) {
        return "View Details";
    }
    else if(listing.type == "BUY_NOW"){
        return "Buy Now";
    }
    else{
        return "Place a Bid";
    }
}

function LandPrice(listing) {
    if (listing == null) {
        return "N/A";
    }
    else if(listing.type == "BUY_NOW"){
        return "VHC " + listing.buyNow.price.value || "N/A";
    }
    else{
        return "VHC " + listing.auction.startingPrice.value || "N/A";
    }
}


const market_url = process.env.MARKET_URL || "";

/**
 * Create Modal Box a single Land
 * @param {JSON} data 
 * @param {Number} ref 
 * @returns 
 */
function SpawnLandInterface(data,id,landData)
{
    
    // const m_MarketPlaceURL = `https://mkt-staging.vaulthill.io/asset-details/${landData.tokenID}`;

    const m_MarketPlaceURL = `${market_url}${landData.tokenID}`;

    // const ans = GenerateLandID((data.landID - ref + 1),data);

    const object = document.createElement('div');

    const isLandAv = landData.type != null;
    // const isLandAv = landData.available;
    
    const avString = LandAvailability(landData.type);

    const avPrice = LandPrice(landData.type);

    object.classList.add('LandInterface');

    //Play - VLAND#26

    object.innerHTML =  `
        <div class="c_one">
            <div class="${isLandAv? "landAvailable": "landNotAvailable" }">
            </div> 
            <p>${isLandAv? "Available": "Not Available"}</p>

        </div>

        <div class="c_two"> 
            <img src="/static/land-images/${data.vhc_name}.png" alt=""> 
        </div>
            
        <div class="c_three">
            <div class="c_three_header">
                <h1>${GetUpperCaseFirstLetter(data.instinct)} - VLAND#${landData.tokenID}</h1>
                <p>${id}</p>
            </div>

            <div style="text-align: right;" class="c_three_sales">
                <p>Starting Price</p>
                <h1>${avPrice}</h1>
            </div>
        </div>

        <div class="c_four">
            <a class="vhc_action_button" href="#/viewer/${landData.tokenID}">View Land</a>

            <a class="vhc_action_button ${landData.available? "":"vhc_action_button_disabled"}" target="_blank" href="${m_MarketPlaceURL}">${avString}</a>

        </div>`
    return object;
}


/**
 * Closes the InfoPanel
 */
function CloseInfoPanel() {

    DisableInterface(detailsPanel);

    SelectHotspot(false);
}


const PanelData = {
    name: "",
    about: "",
    imageLink: "",
    action: ()=>{},
    uiColor: "",
    showButon: false,
    buttonText: "",
    isBrandUI: false
}


/**
 * Opens InfoPanel
 * @param {PanelData} data 
 * @returns 
 */
function OpenInfoPanel(data) {

    if(!data) return;

    EnableInterface(detailsPanel);

    panelDistrict.innerText = data.instinct;
    panelDistrict.style.color = data.uiColor;
    
    if (panelDistrict.classList.contains('GradientBackground')) {
        panelDistrict.classList.remove('GradientBackground');
    }

    panelName.innerText = data.name;
    
    panelAbout.innerText = data.about;  
    

    panelImage.src = data.imageLink;

    panelImage.alt = data.instinct + " logo";

    if (data.isBrandUI) {
        panelImage.style.padding = "0px";

        panelImageHolder.style.backgroundColor = "transparent";

        panelImageBG.style.background = "transparent";

        panelImageHolder.style.overflow = "visible";

        m_BrandWebsite = data.website;

    }
    else{
        // panelImage.style.padding = "20px";
        panelImageHolder.style.backgroundColor = data.uiColor;

        panelImageBG.style.background = `linear-gradient(180deg, ${data.uiColor} 0%, rgba(0, 0, 0, 0) 100%)`;

        panelImageHolder.style.overflow = "hidden";
    }
    

    if (data.showButon) {
        panelActionButton.classList.remove("hide");
        panelActionButton.innerText = data.buttonText;

        if (!data.buttonEnabled) {
            panelActionButton.classList.add('vhc_action_button_disabled');

            
        }
        else{
            panelActionButton.classList.remove('vhc_action_button_disabled');
        }
    }
    else{
        if (!panelActionButton.classList.contains("hide")) {
            panelActionButton.classList.add("hide");
        }
    }

    panelActionButtonFunction = data.action;
}


function SwitchThemeMode(state = true) {

    m_ImageDay.classList.toggle("hide");
    m_ImageNight.classList.toggle("hide");

    if (state) {
        DarkMode(m_Renderer);

        m_ImageNight.classList.remove("hide");
        m_ImageDay.classList.add("hide");

        if (!m_btn_mode.checked) {
            m_btn_mode.checked = true;
        }
    }
    else{
        LightMode(m_Renderer);

        m_ImageNight.classList.add("hide");
        m_ImageDay.classList.remove("hide");
    }
}

//Helper Functions
function GetLandCount(type) {

    if(j_Land_Info[type]) 
    {
        return j_Land_Info[type].quantity;
    }
    else 
    {
        return 1;
    }
}

function EnableInterface(element) {

    if (element.classList.contains("hide")) 
    {
        element.classList.remove('hide');
        element.classList.add('show');
    }
    else{
        element.classList.add('show');
    }
    
}

function DisableInterface(element) {

    if (element.classList.contains("show")) {
        element.classList.remove('show');
        element.classList.add('hide');
    }
    else{
        element.classList.add('hide');
    }
    
}

function GetUpperCaseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function GetPanelCoordinate(element)
{
    const pos = {
        x: "",
        y: ""
    };

    w_width = (window.innerWidth || document.documentElement.clientWidth);
    w_height = (window.innerHeight || document.documentElement.clientHeight);
    
    bounds = element.getBoundingClientRect();

    if ((m_MousePosition.x + bounds.width) > w_width) 
    {
        pos.x = (w_width - (bounds.width + 50))+"px";
    }
    else{
        pos.x = (m_MousePosition.x + 20)+"px";
    }

    if ((m_MousePosition.y + bounds.height) > w_height) 
    {
        pos.y = (w_height - (bounds.height + 50))+"px";
    }
    else{
        pos.y = (m_MousePosition.y + 5)+"px";
    }

    return pos;
}


export { LandInfoPanel,OpenVHCModal,CloseVHCModal,OpenInfoPanel,CloseInfoPanel,InitializeUIEvents,InitializModalEvents,GetLandCount, BrandInfoPanel, SwitchThemeMode, m_VHCModal, detailsPanel,m_ControlTheme, InitDomElements }