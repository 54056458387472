{
    "curiosity":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },

        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },

        "section5":{
            "x": 21.1,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-3" 
        },
        "section6":{
            "x": -21.1,
            "z": -0.075,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-3",
            "available": true 
        },


        "section7":{
            "x": 24.69,
            "z": -3.67,
            "flip": 0,
            "rotation": 240,
            "type": "sub-island-5"
        },
        "section8":{
            "x": 24.3,
            "z": 4.45,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5"
        },
        "section9":{
            "x": -24.62,
            "z": 4.39,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-5" 
        },
        "section10":{
            "x": -24.25,
            "z": -3.85,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },


        "section11":{
            "x": 11.22,
            "z": -21.243,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-6" 
        },
        "section12":{
            "x": 11.22,
            "z": 22.954,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-6" 
        },
        "section13":{
            "x": -11.26,
            "z": 22.95,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-6" 
        },
        "section14":{
            "x": -11.26,
            "z": -21.24,
            "flip": 1,
            "rotation": 180,
            "type": "sub-island-6"
        },


        "section15":{
            "x": 17.72,
            "z": -15.47,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section16":{
            "x": 17.70,
            "z": 16.16,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section17":{
            "x": -17.91,
            "z": -15.56,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section18":{
            "x": -17.77,
            "z": 16.16,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },


        "section19":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section20":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section21":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section22":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        }
    },
    "play":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },

        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },

        "section5":{
            "x": 21.1,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-3" 
        },
        "section6":{
            "x": -21.1,
            "z": -0.075,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-3",
            "available": true 
        },


        "section7":{
            "x": 24.25,
            "z": -4.15,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-4"
        },
        "section8":{
            "x": 24.25,
            "z": 4.15,
            "flip": 2,
            "rotation": 180,
            "type": "sub-island-4"
        },
        

        "section9":{
            "x": 11.15,
            "z": 11.2,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        "section10":{
            "x": -24.25,
            "z": -3.85,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },
        "section11":{
            "x": -15.6,
            "z": -19.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        

        "section12":{
            "x": -11.25,
            "z": -21.246,
            "flip": 1,
            "rotation": 180,
            "type": "sub-island-6" 
        },
        "section13":{
            "x": 11.222,
            "z": -21.246,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-6"
        },
        
        
        "section14":{
            "x": 2.18,
            "z": -5.3,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section15":{
            "x": -2.18,
            "z": -5.3,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section16":{
            "x": -2.18,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section17":{
            "x": 2.18,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section18":{
            "x": 4.545,
            "z": 22.969,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section19":{
            "x": -4.6,
            "z": 22.969,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": -9,
            "z": 15.4,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section21":{
            "x": -17.65,
            "z": 15.4,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-large" 
        },
        "section22":{
            "x": -22.3,
            "z": 7.689,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },



        "section23":{
            "x": -22.2,
            "z": 22.65,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        },
        "section24":{
            "x": -8.55,
            "z": 30.3,
            "flip": 120,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section25":{
            "x": -16.8,
            "z": 29.15,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-large" 
        },


        "section26":{
            "x": 31.5,
            "z": -7.356,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        },
        "section27":{
            "x": 31.5,
            "z": 8.03,
            "flip": 120,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section28":{
            "x": 34.75,
            "z": 0.9,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        }
    },
    "idealism":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },

        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },

        "section5":{
            "x": -21.1,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-3" 
        },


        "section6":{
            "x": 11.80,
            "z": -11.15,
            "flip": 0,
            "rotation": 240,
            "type": "sub-island-5"
        },
        "section7":{
            "x": 11.72,
            "z": 11.87,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5"
        },
        "section8":{
            "x": -15.46,
            "z": 20.64,
            "flip": 0,
            "rotation": -120,
            "type": "sub-island-5"
        },
        "section9":{
            "x": -24.62,
            "z": 4.39,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-5" 
        },
        "section10":{
            "x": -24.25,
            "z": -3.85,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },
        "section11":{
            "x": -15.6,
            "z": -19.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        

        "section12":{
            "x": -11.25,
            "z": -21.246,
            "flip": 1,
            "rotation": 180,
            "type": "sub-island-7" 
        },
        "section13":{
            "x": -10.92,
            "z": 22.20,
            "flip": 1,
            "rotation": 240,
            "type": "sub-island-7"
        },


        "section14":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section15":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section16":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section17":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },



        "section18":{
            "x": 5,
            "z": -23.36,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section19":{
            "x": 18.06,
            "z": -15.59,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": 22.70,
            "z": -7.91,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section21":{
            "x": 17.88,
            "z": 0.29,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section22":{
            "x": 22.5,
            "z": 7.5,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section23":{
            "x": 17.96,
            "z": 16.16,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section24":{
            "x": 4.94,
            "z": 23.85,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        }
    },
    "romance":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },


        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        

        "section5":{
            "x": 21.1,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-3" 
        },


        "section6":{
            "x": 15.76,
            "z": -19.13,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-5",
            "available": true
        },
        "section7":{
            "x": 24.39,
            "z": -3.81,
            "flip": 0,
            "rotation": 240,
            "type": "sub-island-5" 
        },
        "section8":{
            "x": 23.9,
            "z": 3.75,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        "section9":{
            "x": 15.4,
            "z": 18.8,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },


        "section10":{
            "x": 11.13,
            "z": -22.016,
            "flip": 1,
            "rotation": 60,
            "type": "sub-island-6" 
        },
        "section11":{
            "x": -10.93,
            "z": -21.89,
            "flip": 0,
            "rotation": -60,
            "type": "sub-island-6" 
        },


        "section12":{
            "x": 11.175,
            "z": 21.15,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-7" 
        },
        "section13":{
            "x": -10.85,
            "z": 21.3,
            "flip": 1,
            "rotation": 240,
            "type": "sub-island-7" 
        },
        
        

        "section15":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section16":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section17":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section18":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },

        
        "section19":{
            "x": -17.98,
            "z": -15.80,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": -22.71,
            "z": -7.83,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section21":{
            "x": -18.08,
            "z": -0.13,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section22":{
            "x": -22.71,
            "z": 8.27,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section23":{
            "x": -18.10,
            "z": 16.11,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },



        "section24":{
            "x": 32.96,
            "z": 7.26,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section25":{
            "x": 37.38,
            "z": -0.39,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section26":{
            "x": 32.92,
            "z": -7.93,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        },


        "section27":{
            "x": -10.14,
            "z": -33.61,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section28":{
            "x": -18.92,
            "z": -33.45,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section29":{
            "x": -23.38,
            "z": -26.2,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        }
    },
    "vitality":{

        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },


        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        

        "section5":{
            "x": 21.1,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-3-type-2" 
        },
        "section6":{
            "x": -21.1,
            "z": -0.075,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-3-type-3",
            "available": true
        },

        
        "section7":{
            "x": 23.9,
            "z": 3.75,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5-type-2" 
        },
        "section8":{
            "x": 15.4,
            "z": 18.8,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },


        
        "section9":{
            "x": 11.175,
            "z": 21.15,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-6" 
        },
        "section10":{
            "x": -10.85,
            "z": 21.3,
            "flip": 1,
            "rotation": 240,
            "type": "sub-island-6" 
        },


        "section11":{
            "x": -15,
            "z": 18.9,
            "flip": 0,
            "rotation": 240,
            "type": "sub-island-5" 
        },
        "section12":{
            "x": -23.6,
            "z": 3.6,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-5-type-2" 
        },


        "section13":{
            "x": -14.85,
            "z": -18.5,
            "flip": 2,
            "rotation": 180,
            "type": "sub-island-4-type-2" 
        },


        "section14":{
            "x": -10.75,
            "z": -11,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-5" 
        },
        
        

        "section15":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section16":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section17":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section18":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section19":{
            "x": 4.7,
            "z": -22.55,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": -4.44,
            "z": -22.55,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-large" 
        },
        "section21":{
            "x": 17.3,
            "z": -15.13,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section22":{
            "x": 8.93,
            "z": -15.29,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-large" 
        },
        "section23":{
            "x": 22.01,
            "z": -7.39,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },



        "section24":{
            "x": 9.23,
            "z": -31.45,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section25":{
            "x": 22.47,
            "z": -23.81,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section26":{
            "x": 17.89,
            "z": -31.19,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        },


        "section27":{
            "x": -30.9,
            "z": -6.8,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section28":{
            "x": -35.66,
            "z": -0.154,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-hill-large" 
        },
        "section29":{
            "x": -30.9,
            "z": 6.673,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        }
    },
    "imagination":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },

        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },

        "section5":{
            "x": 21.1,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-3" 
        },
        "section6":{
            "x": -21.1,
            "z": -0.075,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-3",
            "available": true 
        },


        "section7":{
            "x": -24.33,
            "z": -3.76,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-4"
        },
        "section8":{
            "x": -15.71,
            "z": 19.56,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-4"
        },
        

        "section9":{
            "x": 15.67,
            "z": -19.04,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-5" 
        },
        "section10":{
            "x": 24.65,
            "z": -3.56,
            "flip": 0,
            "rotation": -120,
            "type": "sub-island-5" 
        },
        "section11":{
            "x": 24.52,
            "z": 4.21,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        "section12":{
            "x": 15.64,
            "z": 19.72,
            "flip": 0,
            "rotation": -180,
            "type": "sub-island-5" 
        },


        "section13":{
            "x": 9.13,
            "z": -15.12,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section14":{
            "x": 5.04,
            "z": -23.27,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section15":{
            "x": -4.63,
            "z": -23.23,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section16":{
            "x": -9.46,
            "z": -15.18,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },


        "section17":{
            "x": 9.16,
            "z": 16.02,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section18":{
            "x": 4.60,
            "z": 23.85,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section19":{
            "x": -4.82,
            "z": 23.87,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": -9.45,
            "z": 15.93,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },


        "section21":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section22":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section23":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section24":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        }
    },
    "community":{
        "section1":{
            "x": 0,
            "z": -12.5,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-1" 
        },
        "section2":{
            "x": 0,
            "z": 12.5,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-1" 
        },

        "section3":{
            "x": 11.375,
            "z": 0,
            "flip": 1,
            "rotation": 0,
            "type": "sub-island-2" 
        },
        "section4":{
            "x": -11.375,
            "z": 0,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-2" 
        },

        
        "section5":{
            "x": -21.1,
            "z": -0.075,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-3",
            "available": true 
        },


        "section6":{
            "x": 12.09,
            "z": -10.81,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-4"
        },
        "section7":{
            "x": -15.72,
            "z": 19.56,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-4"
        },
        "section8":{
            "x": -24.34,
            "z": -3.77,
            "flip": 0,
            "rotation": 120,
            "type": "sub-island-4"
        },
        

        "section9":{
            "x": -2.21,
            "z": -27.04,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-5" 
        },
        "section10":{
            "x": -11.07,
            "z": -11.74,
            "flip": 0,
            "rotation": -180,
            "type": "sub-island-5" 
        },



        "section11":{
            "x": 11.85,
            "z": 23.75,
            "flip": 2,
            "rotation": 180,
            "type": "sub-island-6" 
        },
        "section12":{
            "x": -11.11,
            "z": 23.74,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-6" 
        },


        "section13":{
            "x": 2.35,
            "z": -5.1,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },
        "section14":{
            "x": -2,
            "z": -5.1,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section15":{
            "x": -2,
            "z": 5.125,
            "flip": 0,
            "rotation": 180,
            "type": "sub-island-hill-small" 
        },
        "section16":{
            "x": 2.35,
            "z": 5.125,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-small" 
        },


        "section17":{
            "x": 18.30,
            "z": -14.95,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section18":{
            "x": 22.793,
            "z": -7.41,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section19":{
            "x": 22.92,
            "z": 8.27,
            "flip": 0,
            "rotation": 60,
            "type": "sub-island-hill-large" 
        },
        "section20":{
            "x": 17.68,
            "z": 0.90,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        },
        "section21":{
            "x": 18.21,
            "z": 16.77,
            "flip": 0,
            "rotation": 0,
            "type": "sub-island-hill-large" 
        }
    }
}