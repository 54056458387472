<script>

    import { ControlObject, currentSceneObject } from "../../../../js/viewer-functions/functions/UIController";

    export let title = 'Postion';
    export let subHeading = '';
    export let prop = '';
    export let onChange =()=>{};

    export let config = {
        value: 0,
        min: 0,
        max: 0,
        step: 0
    }

    const opts = {
        value: 5,
        min: 0,
        max: 10,
        step: 0.1,
        ...config
    }

    opts.value = parseFloat(opts.value).toFixed(2);

    function OnInputChange(e) {
        opts.value = parseFloat(e.target.value).toFixed(2);

        ControlObject({
            [prop]: parseFloat(opts.value)
        })

        onChange(e);
    }
</script>


<div class="control-box-object-parent">

    {#if title}
        <h3>{title}</h3>
    {/if}

    {#if subHeading}
        <p>{subHeading}</p>
    {/if}
    
    <div class="control-box-object">

        <input type="range" name="" id="object-scale" class="cp-slider" min={opts.min} max={opts.max} step={opts.step} value={opts.value} object={prop} on:input={OnInputChange}>

        <input type="number" class="cp-number" bind:value={opts.value} on:change={OnInputChange}>

    </div>
</div>


<style>
    h3{
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .control-box-object-parent{
        display: flex;
        flex-direction: column;
        row-gap: 2.5px;
    }

    .control-box-object{
        display: grid;
        column-gap: 10px;
        grid-template-columns: 4fr 1.5fr;
        justify-content: center;
        align-items: center;
        height: 30px;
        
    }

    .cp-slider {
        -webkit-appearance: none;
        position: relative;
        width: 100%;
        height: 0;
        /* background-color: #1b1b1b; */
        background-color: transparent;
        border-radius: 5px; 
        border: none;
        border-bottom: 1px dotted #1b1b1b;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .cp-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%; 
        background: #A96CEF;
        cursor: pointer;
    }

    .cp-slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #A96CEF;
        cursor: pointer;
    }

    .cp-number{
        width: 100%;
        height: 100%;
        padding: 5px;
        font-family: inherit;
        font-size: 16px;
    }
</style>