{
    "basic":[
        15,
        387,
        1,
        16,
        89,90,
        2,3,
        459,460,461,462,463,464,465,466,
        13,
        14,
        373,
        4,
        5,
        6,
        7,
        8,
        26,
        27,28,
        29,
        17,
        30,31,
        374,
        375,
        388,
        20,
        32,
        376,
        33,
        377,
        34,
        35,
        36,
        37,
        378,
        9,
        471, 472,
        38,
        39,
        18,
        40,
        41,
        379,
        19,
        59, 60,
        61,
        21,
        62,
        380,
        63,
        64,
        385,
        389,
        46,
        420, 421,
        10,
        48,49,
        390,
        11,12,
        392, 393,
        473,
        107,
        53,54,
        50
    ]

    
}