
function SetLoaderState(state = true, data = {
    val: 100, string: 'loading...'
}) {
    try {
        const m_Loader = document.querySelector('#loader') as HTMLDivElement;

         //Prograss Bar
        const loaderProgressBar= document.querySelector('#loader-progress-bar') as HTMLInputElement; 
        const loaderProgressText= document.querySelector('#loader-progress-text') as HTMLParagraphElement;
        
        if(state){
            m_Loader.style.display = 'flex';

            loaderProgressBar.value = data.val.toString();
            loaderProgressText.innerText = data.string;
        }
        else{
            m_Loader.style.display = 'none';
        }
    } catch (error) {
        
    }
}



export {SetLoaderState}