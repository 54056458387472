//[100 + n][Type][SubIsland][Index][District]

const m_SubIslandData = 
{
    "sub-island-1": 1,
    "sub-island-2": 2,
    
    "sub-island-3": 3,
    "sub-island-3-type-2": 3,
    "sub-island-3-type-3": 3,

    "sub-island-4": 4,
    "sub-island-4-type-2": 4,

    "sub-island-5": 5,
    "sub-island-5-type-2": 5,

    "sub-island-6": 6,
}
function GenerateLandID(n,data = 
{
    vhc_name,sub_island,sub_island_index,instinct
}) 
{
    // console.log(m_SubIslandData[data.sub_island]);
    // console.log(data.name);
    // GetSubIslandID(sub_island);
    return (100 + n) + data.vhc_name[0].toUpperCase() + m_SubIslandData[data.sub_island] + GetSubIslandIndex(data.sub_island_index) + data.instinct[0].toUpperCase();
}


function GetSubIslandIndex(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}


export {GenerateLandID}