<script>

</script>

<div class="select-assets">
    <p>Select a building you own or buy from Marketplace (*Dummy)</p>
    
    <div class="item-listing" id="item-listing">
        
    </div>
</div>