<script>

    // import Tooltip from "../../common/Tooltip.svelte";

    import { tooltip } from "@svelte-plugins/tooltips/src";

    import { OpenArtworkUploader, DisplayArtworkImage, SaveArtworkInfo, OpenArtworkControlPanel, ClosePanel } from "../../../js/viewer-functions/functions/UIController";

    import ControlnputSlider from "./support/ControlnputSlider.svelte";
    import InputBox from "./support/InputBox.svelte";

    import { SetUIState } from "../../../js/viewer-functions/functions/UIController";

    

    let isOwner = false;

    let objectDetails = {
        name: '',
        creator: '',
        description: ''
    }

    let objectTransform = {
        x: 0,
        y: 0,
        z: 0,
        rot_z: 0,
        scale: 1
    }

    function ObjectView() {
        document.querySelector('#image-preview-panel')?.classList.remove('hide');
        DisplayArtworkImage();
        SetUIState(true);
    }

    function ObjectCancel() {
        ClosePanel();
    }

    function ObjectReplace() {
        OpenArtworkUploader();
    }

    function ObjectInfo() {
        document.querySelector('.viewer-side-panel')?.classList.remove('hide');
        document.querySelector('#viewer-info-panel')?.classList.remove('hide');
        document.querySelector('#viewer-edit-panel')?.classList.add('hide');
        SetUIState(true);
    }

    function ObjectEdit() {
        document.querySelector('.viewer-side-panel')?.classList.remove('hide');
        document.querySelector('#viewer-info-panel')?.classList.add('hide');
        document.querySelector('#viewer-edit-panel')?.classList.remove('hide');
        SetUIState(true);
    }

    function CloseInfoPanel() {
        // console.log("was clicked");
        document.querySelector('.viewer-side-panel')?.classList.add('hide');
        SetUIState(false);
    }

    function SaveAndCloseInfoPanel() {

        CloseInfoPanel();
        SaveArtworkInfo(objectDetails);
    }

    function CloseImagePreview() {
        document.querySelector('#image-preview-panel')?.classList.add('hide');
        SetUIState(false);
    }

    window.addEventListener('message', (e) => {

        if(e.origin != window.location.protocol + '//' + window.location.host){
        return;
        }

        const data = e.data;

        if(data?.type == 'building-state'){

            isOwner = data?.data?.isLandOwner;
            return;
        }

        if(data?.type == 'object-info-state'){

            objectDetails = {
                name: data?.data?.name,
                creator: data?.data?.creator,
                description: data?.data?.description
            }

            objectTransform = {
                x: data?.data?.pos_x || 0,
                y: data?.data?.pos_y || 0,
                z: data?.data?.pos_z || 0,
                rot_z: data?.data?.rot_z || 0,
                scale: data?.data?.scale || 1,
            }

            return;
        }
        
    });

    const toolTip = [
        {
            id: 'object-zoom',
            tooltip: 'Zoom Image'
        },
        {
            id: 'object-edit',
            tooltip: 'Edit Object'
        },
        {
            id: 'object-info',
            tooltip: 'View Details'
        },
        {
            id: 'object-replace',
            tooltip: 'Replace Content'
        },
        {
            id: 'object-cancel',
            tooltip: 'Close Panel'
        }
    ]

</script>



{#key isOwner}


<div class="artwork-control-panel hide" id="artwork-control-panel">

    <button on:click={ObjectView} use:tooltip={{ content: 'View Artwork'}}>
        <img src="./static/assets/zoom-icon.svg" alt="">
    </button>

    {#if isOwner}
        <button on:click={ObjectEdit} id="object-edit" use:tooltip={{ content: 'Edit Object'}}>
            <img src="./static/assets/edit-icon.svg" alt="">
        </button>

        <button on:click={ObjectReplace} id="object-replace" use:tooltip={{ content: 'Replace Content'}}>
            <img src="./static/assets/replace-icon.svg" alt="">
        </button>
    {/if}

    <button on:click={ObjectInfo} id="object-info" use:tooltip={{ content: 'View Details'}}>
        <img src="./static/assets/info-icon.svg" alt="">
    </button>

    <button on:click={ObjectCancel} id="object-cancel" use:tooltip={{ content: 'Close Panel'}}>
        <i class="bi bi-x" style="color: #8a22f2; font-size: 36px"></i>
    </button>

</div>

{/key}



<div class="image-preview hide" id="image-preview-panel">
    <div class="image-holder">
        <img src="./static/viewer-images/no-image.jpg" alt="frame" id="image-preview-viewer">
        <button on:click={CloseImagePreview}><i class="bi bi-x-lg"></i></button>
    </div>
</div>

<div class="viewer-side-panel hide">
    <div id="viewer-info-panel">
        <h1>Information</h1>

        {#key objectDetails}
            <InputBox inputname="Name" value={objectDetails.name} disabled={!isOwner} onChange={(val)=>{
                objectDetails.name = val;
            }}/>

            <InputBox inputname="Creator"  value={objectDetails.creator} disabled={!isOwner} onChange={(val)=>{
                objectDetails.creator = val;
            }}/>

            <InputBox inputname="Description"  value={objectDetails.description} longText disabled={!isOwner} onChange={(val)=>{
                objectDetails.description = val;
            }}/>
        {/key}
        
    </div>

    {#if isOwner}
        <div id="viewer-edit-panel">
            <h1>Edit</h1>

            {#key objectTransform}

                <ControlnputSlider 
                    title={'Position'} 
                    subHeading={'x'} 
                    prop={'x'}
                    config={{
                        value: objectTransform.x,
                        min: objectTransform.x - 5,
                        max: objectTransform.x + 5
                    }}
                />

                <ControlnputSlider 
                    title={''} 
                    subHeading={'y'} 
                    prop={'y'}
                    config={{
                        value: objectTransform.y,
                        min: objectTransform.y - 5,
                        max: objectTransform.y + 5
                    }}
                />

                <ControlnputSlider 
                    title={''} 
                    subHeading={'z'} 
                    prop={'z'}
                    config={{
                        value: objectTransform.z,
                        min: objectTransform.z - 5,
                        max: objectTransform.z + 5
                    }}
                />


                <ControlnputSlider title={'Rotation'} prop={'rot_z'} config={{
                    value: objectTransform.rot_z,
                    min: 0,
                    max: 360,
                    step: 0.1
                }}/>

                <ControlnputSlider title={'Scale'} prop={'scale'} config={{
                    value: objectTransform.scale,
                    min: 0.1,
                    max: 5,
                    step: 0.1
                }}/>
            {/key}
            
        </div>
    {/if}
    

    <button on:click={isOwner? SaveAndCloseInfoPanel : CloseInfoPanel} class="primaryButton">{isOwner? "Save & Close": "Close"}</button>
</div>

<style>
    
    button{
        font-family: 'Open Sans', sans-serif !important;
        font-size: 16px;
    }

    .primaryButton{
        background-color: #A96CEF;
        color: #fff;
    }

    .artwork-control-panel{
        position: fixed;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 15px;
        overflow: visible;

    }

    .artwork-control-panel button{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: none;
    }

    .artwork-control-panel img{
        width: 75%;
        height: 75%;
    }

    .image-preview{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-holder{
        max-width: 720px;
        max-height: 90%;
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
    }

    .image-holder img{
        max-width: 100%;
        max-height: 100%;
        width: 500px;
        border-radius: 10px;
        overflow: hidden;
    }

    .image-holder button{
        position: absolute;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 10px;
        top: -20px;
        right: -20px;
    }

    .image-holder button i{
        width: 20px;
        height: 20px;
    }

    .viewer-side-panel{
        height: 100%;
        width: 350px;
        background-color: #fff;
        position: fixed;
        right: 0;
        top: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        padding: 25px;
        z-index: 20;
        row-gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .viewer-side-panel > div{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    
</style>