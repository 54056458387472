{
    "sub-island-1":{
        "land1":{
            "x": -8.15,
            "z": 4.5,
            "type": "exclusive"
        },
        "land2":{
            "x": -6.6,
            "z": 3.8,
            "type":"deluxe"
        },
        "land3":{
            "x": -5,
            "z": 2.6,
            "type":"premium"
        },
        "land4":{
            "x": -3.3,
            "z": 1.75,
            "type":"standard"
        },
        "land5":{
            "x": -1.65,
            "z": 0.9,
            "type":"standard"
        },
        "land6":{
            "x": 0,
            "z": 0,
            "type":"standard"
        },
        "land7":{
            "x": 1.65,
            "z": 0.9,
            "type":"standard"
        },
        "land8":{
            "x": 3.2,
            "z": 1.75,
            "type":"standard"
        },
        "land9":{
            "x": 4.85,
            "z": 2.6,
            "type":"premium"
        },
        "land10":{
            "x": 6.5,
            "z": 3.8,
            "type":"deluxe"
        },
        "land11":{
            "x": 8.15,
            "z": 4.5,
            "type":"exclusive"
        }
    },
    "sub-island-2":{
        "land1":{
            "x": 0,
            "z": -3.275,
            "type": "standard"
        },
        "land2":{
            "x": 0,
            "z": -1.6,
            "type":"premium"
        },
        "land3":{
            "x": 0,
            "z": 0,
            "type": "deluxe"
        },
        "land4":{
            "x": 0,
            "z": 1.75,
            "type": "premium"
        },
        "land5":{
            "x": 0,
            "z": 3.4,
            "type":"standard"
        },
        "land6":{
            "x": 2.75,
            "z": 0,
            "type":"small-hill"
        }
    },
    "sub-island-3":{
        "land1":{
            "x": -6.6,
            "z": 4.375,
            "type":"standard"
        },
        "land2":{
            "x": -5,
            "z": 3.375,
            "type":"standard"
        },
        "land3":{
            "x": -3.3,
            "z": 2.35,
            "type":"premium"
        },
        "land4":{
            "x": -1.65,
            "z": 1.45,
            "type":"deluxe"
        },
        "land5":{
            "x": 0,
            "z": 0,
            "type":"exclusive"
        },
        "land6":{
            "x": -1.65,
            "z": -1.375,
            "type":"deluxe"
        },
        "land7":{
            "x": -3.3,
            "z": -2.285,
            "type":"premium"
        },
        "land8":{
            "x": -5,
            "z": -3.25,
            "type":"standard"
        },
        "land9":{
            "x": -6.6,
            "z": -4.25,
            "type":"standard"
        }
    },
    "sub-island-4":{

        "land1":{
            "x": 0,
            "z": 0,
            "type": "exclusive"
        },
        "land2":{
            "x": 0,
            "z": 2,
            "type": "deluxe"
        },
        "land3":{
            "x": 0,
            "z": 3.9,
            "type": "premium"
        },
        "land4":{
            "x": 0,
            "z": 5.85,
            "type": "standard"
        },
        "land5":{
            "x": 0,
            "z": 7.75,
            "type": "standard"
        },
        "land6":{
            "x": 0,
            "z": 9.7,
            "type": "standard"
        },
        "land7":{
            "x": 1.8,
            "z": 10.9,
            "type": "standard"
        },
        "land8":{
            "x": 3.6,
            "z": 11.85,
            "type": "standard"
        },
        "land9":{
            "x": 5.385,
            "z": 12.85,
            "type": "premium"
        },
        "land10":{
            "x": 7.15,
            "z": 13.8,
            "type": "deluxe"
        },
        "land11":{
            "x": 8.9,
            "z": 14.8,
            "type": "exclusive"
        },
        "land12":{
            "x": 8.9,
            "z": 12.85,
            "type": "deluxe"
        },
        "land13":{
            "x": 8.9,
            "z": 10.85,
            "type": "premium"
        },
        "land14":{
            "x": 8.9,
            "z": 8.9,
            "type": "standard"
        },
        "land15":{
            "x": 8.9,
            "z": 7,
            "type": "standard"
        },
        "land16":{
            "x": 8.9,
            "z": 5.1,
            "type": "standard"
        },
        "land17":{
            "x": 7.2,
            "z": 3.65,
            "type": "standard"
        },
        "land18":{
            "x": 5.41,
            "z": 2.65,
            "type": "standard"
        },
        "land19":{
            "x": 3.6,
            "z": 1.65,
            "type": "premium"
        },
        "land20":{
            "x": 1.8,
            "z": 0.6,
            "type": "deluxe"
        }
    },
    
    "sub-island-5":{
        "land1":{
            "x": -7.15,
            "z": 4,
            "type":"standard"
        },
        "land2":{
            "x": -5.3,
            "z": 2.75,
            "type":"standard"
        },
        "land3":{
            "x": -3.5,
            "z": 1.75,
            "type":"premium"
        },
        "land4":{
            "x": -1.7,
            "z": 0.7,
            "type":"deluxe"
        },
        "land5":{
            "x": 0,
            "z": 0,
            "type":"exclusive"
        },
        "land6":{
            "x": 0.15,
            "z": 2.15,
            "type":"deluxe"
        },
        "land7":{
            "x": 0.15,
            "z": 4.15,
            "type":"premium"
        },
        "land8":{
            "x": 0.2,
            "z": 6.2,
            "type":"standard"
        },
        "land9":{
            "x": 0.2,
            "z": 8.175,
            "type":"standard"
        }
    },
    "sub-island-6":{
        "land1":{
            "x": 8.9,
            "z": 5,
            "type":"exclusive"
        },
        "land2":{
            "x": 7.15,
            "z": 4.4,
            "type":"deluxe"
        },
        "land3":{
            "x": 5.35,
            "z": 3.4,
            "type":"premium"
        },
        "land4":{
            "x": 3.6,
            "z": 2.4,
            "type":"standard"
        },
        "land5":{
            "x": 1.8,
            "z": 1.45,
            "type": "standard"
        },
        "land6":{
            "x": 0,
            "z": 0,
            "type": "standard"
        },
        "land7":{
            "x": 0,
            "z": -1.95,
            "type":"standard"
        },
        "land8":{
            "x": 0,
            "z": -3.9,
            "type":"standard"
        },
        "land9":{
            "x": 0,
            "z": -5.825,
            "type":"premium"
        },
        "land10":{
            "x": 0,
            "z": -7.75,
            "type":"deluxe"
        },
        "land11":{
            "x": 0,
            "z": -9.7,
            "type":"exclusive"
        }
    },
    "sub-island-7":{
        "land1":{
            "x": 9.0,
            "z": -4.49,
            "type":"standard"
        },
        "land2":{
            "x": 9.0,
            "z": -2.5,
            "type":"standard"
        },
        "land3":{
            "x": 9.0,
            "z": -0.65,
            "type":"standard"
        },
        "land4":{
            "x": 8.98,
            "z": 1.25,
            "type":"premium"
        },
        "land5":{
            "x": 9.09,
            "z": 3.02,
            "type":"deluxe"
        },
        "land6":{
            "x": 8.9,
            "z": 5,
            "type":"exclusive"
        },
        "land7":{
            "x": 7.15,
            "z": 4.4,
            "type":"deluxe"
        },
        "land8":{
            "x": 5.35,
            "z": 3.4,
            "type":"premium"
        },
        "land9":{
            "x": 3.6,
            "z": 2.4,
            "type":"standard"
        },
        "land10":{
            "x": 1.8,
            "z": 1.45,
            "type": "standard"
        },
        "land11":{
            "x": 0,
            "z": 0,
            "type": "standard"
        },
        "land12":{
            "x": 0,
            "z": -1.95,
            "type":"standard"
        },
        "land13":{
            "x": 0,
            "z": -3.9,
            "type":"standard"
        },
        "land14":{
            "x": 0,
            "z": -5.825,
            "type":"premium"
        },
        "land15":{
            "x": 0,
            "z": -7.75,
            "type":"deluxe"
        },
        "land16":{
            "x": 0,
            "z": -9.7,
            "type":"exclusive"
        }
    },

    "sub-island-hill-small":{
        "land1":{
            "x": 0,
            "z": 0,
            "type":"small-hill"
        }
    },
    "sub-island-hill-large":{
        "land1":{
            "x": 0,
            "z": 0,
            "type":"large-hill"
        }
    },

    

    "sub-island-3-type-2":{
        "land1":{
            "x": 0,
            "z": 0,
            "type":"exclusive"
        },
        "land2":{
            "x": -1.65,
            "z": 1.45,
            "type":"deluxe"
        },
        "land3":{
            "x": -3.3,
            "z": 2.35,
            "type":"premium"
        },
        "land4":{
            "x": -5,
            "z": 3.375,
            "type":"standard"
        },
        "land5":{
            "x": -6.6,
            "z": 4.375,
            "type":"standard"
        },
        "land6":{
            "x": -6.6,
            "z": -4.25,
            "type":"standard"
        },
        "land7":{
            "x": -5,
            "z": -3.25,
            "type":"standard"
        },
        "land8":{
            "x": -3.3,
            "z": -2.285,
            "type":"premium"
        },
        "land9":{
            "x": -1.65,
            "z": -1.375,
            "type":"deluxe"
        }
        
    },
    "sub-island-3-type-3":{
        "land1":{
            "x": -6.6,
            "z": -4.25,
            "type":"standard"
        },
        "land2":{
            "x": -6.6,
            "z": 4.375,
            "type":"standard"
        },
        "land3":{
            "x": -5,
            "z": 3.375,
            "type":"standard"
        },
        "land4":{
            "x": -3.3,
            "z": 2.35,
            "type":"premium"
        },
        "land5":{
            "x": -1.65,
            "z": 1.45,
            "type":"deluxe"
        },
        "land6":{
            "x": 0,
            "z": 0,
            "type":"exclusive"
        },
        "land7":{
            "x": -1.65,
            "z": -1.375,
            "type":"deluxe"
        },
        "land8":{
            "x": -3.3,
            "z": -2.285,
            "type":"premium"
        },
        "land9":{
            "x": -5,
            "z": -3.25,
            "type":"standard"
        }
    },


    "sub-island-4-type-2":{
        "land1":{
            "x": 0,
            "z": 9.7,
            "type": "standard"
        },
        "land2":{
            "x": 0,
            "z": 7.75,
            "type": "standard"
        },
        "land3":{
            "x": 0,
            "z": 5.85,
            "type": "standard"
        },
        "land4":{
            "x": 0,
            "z": 3.9,
            "type": "premium"
        },
        "land5":{
            "x": 0,
            "z": 2,
            "type": "deluxe"
        },
        "land6":{
            "x": 0,
            "z": 0,
            "type": "exclusive"
        },
        "land7":{
            "x": 1.8,
            "z": 0.6,
            "type": "deluxe"
        },
        "land8":{
            "x": 3.6,
            "z": 1.65,
            "type": "premium"
        },
        "land9":{
            "x": 5.41,
            "z": 2.65,
            "type": "standard"
        },
        "land10":{
            "x": 7.2,
            "z": 3.65,
            "type": "standard"
        },
        "land11":{
            "x": 8.9,
            "z": 5.1,
            "type": "standard"
        },
        "land12":{
            "x": 8.9,
            "z": 7,
            "type": "standard"
        },
        "land13":{
            "x": 8.9,
            "z": 8.9,
            "type": "standard"
        },
        "land14":{
            "x": 8.9,
            "z": 10.85,
            "type": "premium"
        },
        "land15":{
            "x": 8.9,
            "z": 12.85,
            "type": "deluxe"
        },
        "land16":{
            "x": 8.9,
            "z": 14.8,
            "type": "exclusive"
        },
        "land17":{
            "x": 7.15,
            "z": 13.8,
            "type": "deluxe"
        },
        "land18":{
            "x": 5.385,
            "z": 12.85,
            "type": "premium"
        },
        "land19":{
            "x": 3.6,
            "z": 11.85,
            "type": "standard"
        },
        "land20":{
            "x": 1.8,
            "z": 10.9,
            "type": "standard"
        }
        
        
    },


    

    "sub-island-5-type-2":{
        "land1":{
            "x": 0,
            "z": 0,
            "type":"exclusive"
        },
        "land2":{
            "x": 0.15,
            "z": 2.15,
            "type":"deluxe"
        },
        "land3":{
            "x": 0.15,
            "z": 4.15,
            "type":"premium"
        },
        "land4":{
            "x": 0.2,
            "z": 6.2,
            "type":"standard"
        },
        "land5":{
            "x": 0.2,
            "z": 8.175,
            "type":"standard"
        },
        "land6":{
            "x": -7.15,
            "z": 4,
            "type":"standard"
        },
        "land7":{
            "x": -5.3,
            "z": 2.75,
            "type":"standard"
        },
        "land8":{
            "x": -3.5,
            "z": 1.75,
            "type":"premium"
        },
        "land9":{
            "x": -1.7,
            "z": 0.7,
            "type":"deluxe"
        }
    }
}