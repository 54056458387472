import * as THREE from "three";
import JSZip from 'jszip';

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
import ExportCustomZip from "./zip-export";


const API_SERVER = process.env.MARKET_API || "https://mp-server.vaulthill.io/graphql";

// const API_SERVER = "https://3000-vaulthill-vhcmarketplac-r2bg5hhhf13.ws-eu80.gitpod.io/graphql";

const API_UPLOAD_BUCKET = process.env.API_UPLOAD_BUCKET || "vhc_mkt_dev";

const options = {
    trs: true,
    onlyVisible: true,
    truncateDrawRange: true,
    binary: true,
    maxTextureSize: 2048,
    forceIndices: true,
    includeCustomExtensions: true
};

type BuildingGLB = {
    success: string,
    error: string,
    model: Blob
}

type SaveState = {
    success: string,
    error: string
}

type customiseData = {
    walletAddress: string
    buildingId: string
    data:{
        brandName?: string
        fileUrl?: string
        brandLogoUrl?: string
        headerUrl?: string
        description?: string
    }
}

const m_EmptyBuildingInfo = {
    buildingId: "",
    walletAddress: "",
    data:{
        brandName: "",
        fileUrl: "",
        brandLogoUrl: "",
        headerUrl: "",
        description: ""
    }
}

class BuildingLoader {

    importUrl: string;
    importType: string;
    building: THREE.Group;
    m_GLBLoader: GLTFLoader;
    m_GLBExporter: GLTFExporter;
    m_DracoLoader: DRACOLoader;
    animations: Array<THREE.AnimationClip>;
    buildingInfo: customiseData;

    constructor(buildingUrl: string = 'static/samples/test-interior.glb',type: string ='model', buildingInfo: customiseData = m_EmptyBuildingInfo){
        this.importUrl = buildingUrl;
        this.importType = type;

        this.building = new THREE.Group();

        this.m_GLBLoader =  new GLTFLoader();
        this.m_DracoLoader =  new DRACOLoader();
        this.m_DracoLoader.setDecoderPath('/static/draco/');
        this.m_GLBLoader.setDRACOLoader(this.m_DracoLoader);

        this.m_GLBExporter =  new GLTFExporter();

        this.animations = [];
        this.buildingInfo = buildingInfo
    }

    customisationData = {}

    customisationConfig = {}

    async Initialize(){
        
        if(this.importType == 'model'){
            await this.AddGLBBuilding(this.importUrl);
        }

        if(this.importType == 'zip'){
            await this.AddZIPBuilding(this.importUrl);
        }
        

        return this.building;
    }

    getBuilding(){
        return this.building;
    }

    RemoveBuilding(scene){
        scene.remove(this.building);
    }

    async AddGLBBuilding(url: string){

        const blobData = this.customisationData;

        const state = await new Promise((resolve, reject)=>{

            this.m_GLBLoader.load(url,(gltf)=>{
                
                gltf.scene.traverse(async function (node) {
                    if (node instanceof THREE.Mesh) {
                        node.receiveShadow = true;
                        node.frustumCulled = false;
                        node.material.side = THREE.FrontSide;

                        if(node.name.includes('vhc_artwork')){

                            let image_url = "./static/viewer-images/upload-bg.svg";

                            if(node.userData?.custom){

                                image_url = URL.createObjectURL(blobData[node.name].blob);

                                node.userData.custom.blobUrl = image_url;

                            }

                            const texture = await new THREE.TextureLoader().loadAsync(image_url);

                            texture.flipY = false;                            

                            const material = new THREE.MeshBasicMaterial({
                                color : '#FFF',
                                map: texture,
                                transparent: false
                            });

                            /* const bbox = new THREE.Box3().setFromObject(node);

                            const width = (bbox.max.x - bbox.min.x)/node.scale.x;
                            const height = (bbox.max.y - bbox.min.y)/node.scale.y;

                            const plane = new THREE.PlaneGeometry(width,height);
                            const plane_mesh = new THREE.Mesh(plane,material);

                            //Set THREEJS UV
                            // node.geometry.attributes.uv = plane.attributes.uv;
                            // node.geometry.attributes.uv2 = plane.attributes.uv;*/

                            node.material = material;
                        }
                    }
                });
                
                if( this.animations) this.animations = gltf.scene.animations;

                if( gltf.scene ) {
                    this.building = gltf.scene; 
                    // this.building.scale.set(0.1,0.1,0.1);
                    // this.building.position.set(0,-100,0);
                }               
                resolve(true);
            },()=>{

            },(err)=>{
                console.log(err);
                
                resolve(false);
            })
        })
        
        return state;
    }

    async AddZIPBuilding(url: string){

        const state = await new Promise((resolve, reject)=>{
            fetch(url)
            .then(res => res.arrayBuffer())
            .then((zip)=>{
                
                JSZip.loadAsync(zip)
                .then(async (data)=>{

                    const indexGLB = data.file('index.glb');

                    if(!indexGLB) return resolve(false);

                    const indexBlob = await indexGLB.async('blob');

                    const configFile = data.file('config.vhc');

                    if(configFile){
                        const config = await configFile.async('string');
                        this.customisationConfig = JSON.parse(config);
                    }


                    for (const objectName in this.customisationConfig) {
                        
                        const object = this.customisationConfig[objectName];

                        const file_name = object?.src_name;
                        const customisation = data.file(`customisation/${file_name}`);

                        if(!customisation) continue;

                        const blobFile = await customisation.async('blob');

                        this.customisationData = {
                            ...this.customisationData,
                            [objectName]: {
                                file_name: file_name,
                                blob: blobFile
                            }
                        }
                        
                    }
                                        
                    const blobUrl = URL.createObjectURL(indexBlob);

                    await this.AddGLBBuilding(blobUrl);
                    URL.revokeObjectURL(blobUrl);
                    return resolve(true)
                    
                })
            })
            .catch((err)=>{
                return resolve(false);
            })
        })
        
        return state;
    }

    async ExportBuilding(){

        const state = await new Promise<BuildingGLB>((resolve, reject)=>{

            this.m_GLBExporter.parse( this.building, function ( result ){    
                if (result instanceof ArrayBuffer) {

                    const blob = new Blob( [ result ], { type: 'model/gltf-binary' } );
                    
                    resolve({success: `Generated successfully`, model: blob, error: ''});
                }
                else{
                    resolve({error: 'Error generating glb model, try again', success: '', model: new Blob()}); 
                }
                
            },(err)=>{
                resolve({error: 'Error generating glb model, try again', success: '', model: new Blob()});
            },{
                ...options,
                animations: this.animations
            });
        })
        
        const buffer = await state.model.arrayBuffer();

        const upload_state = await new Promise<ValidateUploadUrl>((resolve, reject)=>{

            const callback = async (file: Blob)=>{
                const state = await UploadBuildingToServer(file,this.buildingInfo.walletAddress,this.buildingInfo);

                resolve(state);
            }

            ExportCustomZip({
                root:{
                    'customisation': async ()=>{
                        const data = {};

                        for(const i in this.customisationData){
                            const file = this.customisationData[i];

                            const buffer = await file.blob.arrayBuffer();

                            data[`${file.file_name}`] = buffer;
                            
                        }
                        
                        return data;
                        
                    },
                    'config.vhc': ()=>{
                        return JSON.stringify(this.customisationConfig)
                    },
                    'index.glb': buffer,
                    
                },
                content: callback,
                save: false
            })
        })
        
        return upload_state;
    }
  
}



const CUSTOMIZE_BUILDING_QUERY = ({buildingId, walletAddress, data}: customiseData)=> `
mutation {
  customizeBuilding(walletAddress: "${walletAddress}" buildingId: "${buildingId}" data: {brandName:"${data.brandName}" fileUrl: "${data.fileUrl}" brandLogoUrl: "${data.brandLogoUrl}" headerUrl: "${data.headerUrl}" description: "${data.description}"}) {
    tokenId
    tokenAddress
    minterAddress
    name
    animationUrl
    animationRenderUrl
    images
    description
    buildingCustomization {
      brandName
      fileUrl
      brandLogoUrl
      headerUrl
      description
      deploymentDetails {
        walletAddress
        relatedVlandIds
        datetime
      }
    }
  }
}`;


type ValidateUploadUrl = {
    success?: string
    error?: string
    fileUrl?: string
}

//Support function

async function UploadBuildingToServer(file: Blob, walletAddress: string, buildingData: customiseData){

    if(!walletAddress){
        return {error: 'no wallet address found'};
    }
    
    const UPLOAD_ENDPOINT = `${API_SERVER}/api/v1/files?manifest={"${API_UPLOAD_BUCKET}": ["file"]}&dir=${walletAddress}&replaceable=true`;

    const uploadEndPoint = UPLOAD_ENDPOINT.replace('/graphql','');

    const state = await new Promise<ValidateUploadUrl>((resolve, reject)=>{
        const formData = new FormData();
        formData.append('file',file,'upload.zip');    

        fetch(uploadEndPoint,{
            method: 'POST',
            body: formData,
        })
        .then(res => res.json())
        .then((data)=>{
            const fileUrl = data?.fileUrl || null; 

            if(!fileUrl){
                return resolve ({
                    error: 'Could not upload changes, try again!'
                })
            }

            resolve ({
                success: 'Got url',
                fileUrl: fileUrl
            })
            
        })
        .catch((err)=>{
            console.log(err);
            
            return resolve ({
                error: 'Could not upload changes, try again!'
            })
            
        })
    })

    if(!state.success) {
        return state;
    };

    const state_customise = await new Promise<ValidateUploadUrl>((resolve, reject)=>{

        const query = CUSTOMIZE_BUILDING_QUERY({
            ...buildingData,
            data: {
                ...buildingData.data,
                fileUrl: state.fileUrl,
            }
        });
                
        fetch(API_SERVER,{
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({query: query}),
        })
        .then(res => res.json())
        .then((data)=>{            
            
            const savedBuilding = data?.data?.customizeBuilding || null; 

            if(!savedBuilding){
                return resolve ({
                    error: 'Could not save changes, try again!'
                })
            }

            resolve ({
                success: 'Successfully saved building'
            })
            
        })
        .catch((err)=>{
            
            return resolve ({
                error: 'Could not save changes, try again!'
            })
            
        })
    })

    return state_customise;
}

export {BuildingLoader}