{
    "standard":{
        "price":"VHC 200,000",
        "quantity": 1,
        "scale": 100,
        "brandUIHeight": 80
    },
    "premium":{
        "price":"VHC 200,000",
        "quantity": 1,
        "scale": 100,
        "brandUIHeight": 80
    },
    "deluxe":{
        "price":"VHC 200,000",
        "quantity": 1,
        "scale": 100,
        "brandUIHeight": 90
    },
    "exclusive":{
        "price":"VHC 200,000",
        "quantity": 1,
        "scale": 100,
        "brandUIHeight": 100
    },
    "small-hill":{
        "price":"VHC 1000",
        "quantity": 0,
        "scale": 3
    },
    "large-hill":{
        "price":"VHC 1000",
        "quantity": 0,
        "scale": 3.25
    },
    "partner":{
        "price":"VHC 1000",
        "quantity": 0,
        "scale": 1
    }
}