<script>
    //Commons
	import Panel from '../common/Panel.svelte';
	import Onboarding from '../common/Onboarding.svelte';
	import Loader from '../common/Loader.svelte';

    //Components
	import BuildingSelector from './components/BuildingSelector.svelte';
	import LeaveExperience from './components/LeaveExperience.svelte';
	import ObjectCustomiser from './components/ObjectCustomiser.svelte';
	import ObjectControlPanel from './components/ObjectControlPanel.svelte';
	import Canvas from './components/Canvas.svelte';

    import { StartViewer } from '../../js/viewer-main';

	import { onMount } from 'svelte';

	onMount(()=>{
		StartViewer();
	})
 
</script>

<section class="viewer-section">
	
	<Panel isViewer={true}/>
	<Onboarding/>
	<Loader/>


	<Canvas/>
	<BuildingSelector/>
	<LeaveExperience/>
	<ObjectCustomiser/>
	<ObjectControlPanel/>
	
	<!-- <Footer/> -->
	
</section>

<style>
	.viewer-section{
        position: absolute;
        top: 0;
        left: 0;
		width: 100%;
		height: 100%;
    }
</style>