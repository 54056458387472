import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module';

import { BuildAllDistricts } from './map-functions/land-builder';
import { InitializeRaycaster,RaycastObjects } from './map-functions/land-raycast';
import { CreateDistrict3DUI } from './map-functions/scene-3d-ui-builder';
import { initBirds, UpdateBirds } from './map-functions/bird';

import { InitializeControls, ControlUpdates, ControlLookAt } from './map-functions/map-controls.js';
import { CreateWebGLRenderer, CreateCSS3DRenderer, InitializeCamera, InitializeLight, AddGLBObject, AddMapWaterSystem, AddCloudPar, AddCloudMove, AddLensfair} from './map-functions/scene-builder.js';

import { InitPostProcessing, RenderPostProcessedScene } from './map-functions/post-processing';

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { InitializeUIEvents, SwitchThemeMode, InitDomElements } from './map-functions/userinterface-2d';


import { UILookAtCamera } from './map-functions/scene-3d-ui-builder';

import { IsUIHovered } from './map-functions/userinterface-3d';

import { AuthenticateFunction } from './map-functions/authenticate-user';
import { SetLoaderState } from './global/loader-controller';

var mobile = require('is-mobile');

const isMobile = mobile();



// const stats = new Stats();

// stats.showPanel(0)
// document.body.appendChild(stats.dom);

let camera, scene, renderer, ui_renderer,m_UI, light, sceneBackgroundTexture;

const BLOOM_SCENE = 1;

const bloomLayer = new THREE.Layers();

bloomLayer.set( BLOOM_SCENE );

var m_modalIsActive = false;

const VHCMapScale = 50;

var d_Pointer = new THREE.Vector2(0,0);


//DOM Elements


function StartApp() {

    InitDomElements();

    AuthenticateFunction(async (userLands)=>{
        await init(userLands || {});
        animate();
    });
    
}


//More like unity Start function

async function init(userLands) {
    
    SetLoaderState(true,{
        val: 0, string: "Initializing VaultHill Map"
    });

    scene = new THREE.Scene();
    //scene.background = new THREE.Color( ox000000 );
	// scene.fog = scback.fog;
    // scene.fog = new THREE.Fog(0xcccccc,0.001,5000);

    // renderer = CreateWebGLRenderer(container.clientWidth - 1, container.clientHeight - 1);
    renderer = CreateWebGLRenderer(window.innerWidth, window.innerHeight);

    // renderer = CreateWebGLRenderer(3840, 2160);

    // ui_renderer = CreateCSS3DRenderer(container.clientWidth - 1, container.clientHeight - 1);
    ui_renderer = CreateCSS3DRenderer(window.innerWidth, window.innerHeight);

    const canvas = document.querySelector('#map-canvas'); 

    canvas.appendChild( renderer.domElement );
    canvas.appendChild( ui_renderer.domElement );

    camera = InitializeCamera(50,0.5,10,15000,{
        x: 0,y: 4000,z: 5000
    });
    
    
    light = InitializeLight({
        useHemisphere: true,
        hem_color: 0xffe5c0,
        hem_base: 0xffffff,
        hem_intensity: 1,
        useDirectional: true,
        direct_intensity: 0,
        direct_color: 0xffe5c0,
        useSkybox: false,
        scene: scene,
        renderer: renderer,
        map_scale: VHCMapScale
    });

    new RGBELoader().load("./static/threejs/env-bg.hdr", function(texture){  
        texture.mapping = THREE.EquirectangularReflectionMapping;
        // scback.bloomOff = texture;
        sceneBackgroundTexture = texture;
        // scene.background = texture;
    });

    
    scene.add(camera);
    scene.add(light);

    light.traverse((object)=>{
        object.layers.enable( 0 );
    })

    camera.traverse((object)=>{
        object.layers.enable( 0 );
    })

    
    InitializeControls(camera,VHCMapScale,ui_renderer,d_Pointer,{
        minX: -2500,
        maxX: 2500,
        minZ: -2500,
        maxZ: 2500,
        maxY: 2,
        minDistance: 100,
        maxDistance: 5000
    });
    
    SetLoaderState(true,{
        val: 20, string: "Preparing Environment"
    });

    //Build VHC Map

    const m_VHCMap = await BuildAllDistricts(VHCMapScale,BLOOM_SCENE,userLands);

    m_VHCMap.position.y = VHCMapScale/4;
   
    scene.add(m_VHCMap);

    SetLoaderState(true,{
        val: 50, string: "Adding NFT Assets"
    });
    
    
    m_UI = CreateDistrict3DUI(400,VHCMapScale,(x,z)=>{
        d_Pointer.set(x,z);
    })

    scene.add(m_UI);


    /*const m_DistrictsBase = await AddGLBObject('threejs/c_district.glb',{
        x:0, y: 0, z: 0,scale_x: VHCMapScale, scale_y: VHCMapScale, scale_z: VHCMapScale
    })*/

    const m_DistrictsBase = await AddGLBObject('threejs/c_district_updated.glb',{
        x:0, y: 0, z: 0,scale_x: VHCMapScale, scale_y: VHCMapScale, scale_z: VHCMapScale
    })

    // m_DistrictsBase.layers.enable( BLOOM_SCENE )


    scene.add(m_DistrictsBase);

    const water = await AddMapWaterSystem(7500,64,{
        // groundColor: '#074a5d',
        groundColor: '#005a87',
        waterColor: '#005477',
        waterScale: 10,
        textureSize: 64,
        flowX: 2,
        flowY: 2,
        waterPosition: 0,
        groundGap : 0,
        rotation: -90,
    })

    scene.add( water );


    SetLoaderState(true,{
        val: 80, string: "Connecting to VHC Server"
    });
    

    InitializeRaycaster({
        scene: scene,
        camera: camera,
        objects: m_VHCMap.children,
        tag: "vhc-land",
        canvas: canvas,
        onModalOpened: ()=>{
            SetModalUIState(true);
        }, 
        onModalClosed: ()=>{
            SetModalUIState(false);
        }
    });


    //Birds

    if (!isMobile) {

        //clouds    
        const m_SceneCloud = AddCloudPar(VHCMapScale);
        const m_LensFare = AddLensfair();

        //if its mobile
        scene.add(m_SceneCloud);
        scene.add(m_LensFare);

        const Birds = initBirds(renderer);
        scene.add(Birds);
    }
    

    //Post Processing 

    InitPostProcessing(scene,camera,renderer,bloomLayer,canvas,{
        useBloom: true,
        bloomStrenght: 0.3,
        bloomRadius: 0,
        useAliasing: true,
        useFog: false,
        fogColor: 0xcccccc,
        fogDensity: 0.0001,
        sceneBackground: sceneBackgroundTexture
    })
    
    SetLoaderState(true,{
        val: 100, string: "Running Postprocessing"
    });

    InitializeUIEvents(renderer);


    //Check for client time 
    //Dark mode is 7PM - 6 AM (19:00 - 6:00)
    //Light mode is 6AM - 7PM (6:00 - 19:00)

    const today = new Date().getHours();
    // const today = 19;

    if (today >= 6 && today < 19) {
        SwitchThemeMode(false);
    }
    else{
        SwitchThemeMode(true);
    }

    // 

    // InitScreenShot(renderer);

    SetLoaderState(false);

    onWindowResize();
    
    //Event on Window Resize
    window.addEventListener( 'resize', onWindowResize );
}


function SetModalUIState(state = true) {
    m_modalIsActive = state;
}


function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    // renderer.setSize( container.clientWidth - 1, container.clientHeight - 1 );
    renderer.setSize( window.innerWidth, window.innerHeight );
    // ui_renderer.setSize( container.clientWidth - 1, container.clientHeight - 1 );
    ui_renderer.setSize( window.innerWidth, window.innerHeight );
}


//More like unity update function
function animate() {
    

    requestAnimationFrame( animate );
    
    if(!m_modalIsActive) 
    {
        render();
    }

    // stats.update();

}

function render() {

    if (!isMobile) {
        AddCloudMove(5);

        UpdateBirds();
    }
    

    RaycastObjects(IsUIHovered);

    ControlUpdates();
    
    ui_renderer.render( scene, camera);
    // renderer.render( scene, camera );

    RenderPostProcessedScene();

    //UI Face Camera

    UILookAtCamera(camera);
}


export {StartApp}
