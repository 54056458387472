import FileSaver from 'file-saver';
import isArrayBuffer from "./validate-array-buffer";
import JSZip from 'jszip';


export type ExportOptions = {

  /**
   * Specify if you want to save the file
   * @default true
   */
  save?: boolean

  /**
   * Callback when save is false
   */
  content?: (blob: Blob)=> void

  /**
   * ZIP filename prefix
   * @default 'vhc'
   */
  filenamePfx?: string

  /**
   * Use a function to generate the filename, eg. `filename: editor => 'my-file.zip',`
   */
   filename?: () => string,

   /**
    * Callback to execute once the export is completed
    */
   done?: () => void,

   /**
    * Callback to execute on export error
    */
   onError?: (error: Error) => void,

   /**
    * Use the root object to create the folder structure of your zip (async functions are supported)
    * @example
    * root: {
    *   environemnts: {
    *     'image1.jpg': ',
    *     'some-file.txt': 'My custom content',
    *   },
    *   'config.json': '{}`
    *   'index.glb': 'root glb file`
    * }
    */
   root?: Record<string, unknown>

   /**
    * Custom function for checking if the file content is binary
    */
   isBinary?: (content: string, name: string) => boolean,
};

const ExportCustomZip = (opts: ExportOptions = {}) => {
  
  const config: ExportOptions = {
    filenamePfx: 'vhc',
    filename: undefined,
    done: () => {},
    onError: console.error,
    root: {
      'customisation': {
        
      },
      'config.json': JSON.stringify({}),
      'index.glb': '',
    },
    isBinary: undefined,
    ...opts,
  };
  
 
  const zip = new JSZip();
  const onError = opts.onError || config.onError;
  const root = opts.root || config.root;

  // @ts-ignore
  createDirectory(zip, root, opts.save)
  .then(async () => {      

    const content = await zip.generateAsync({ type: 'blob' });

    if(!opts.save) return opts.content? opts.content(content): null;
      
    const filenameFn = opts.filename || config.filename;
    const done = opts.done || config.done;
    const filenamePfx = opts.filenamePfx || config.filenamePfx;
    const filename = filenameFn ? filenameFn() : `${filenamePfx}_${Date.now()}.zip`;
    FileSaver.saveAs(content, filename);
    done?.();
  })
  .catch(onError);

  function createFile(zip: JSZip, name: string, content: string) {
    const opts: JSZip.JSZipFileOptions = {};
    const ext = name.split('.')[1];

    if(typeof content === 'string'){
      const isBinary = config.isBinary ?
        config.isBinary(content, name) :
        !(ext && ['html', 'css'].indexOf(ext) >= 0) &&
        !/^[\x00-\x7F]*$/.test(content);

      if (isBinary) {
        opts.binary = true;        
      }
    }
    
    zip.file(name, content, opts);
  }

  async function createDirectory(zip: JSZip, root: ExportOptions["root"]) {
    // @ts-ignore
    root = typeof root === 'function' ? await root() : root;

    for (const name in root) {
      if (root.hasOwnProperty(name)) {
        let content = root[name];
        
        // Override for Array Buffer
        const isBuffer = isArrayBuffer(content);

        if(isBuffer){
          // @ts-ignore
          createFile(zip, name, content);
          continue;
        }
        
        content = typeof content === 'function' ? await content() : content;
        const typeOf = typeof content;

        if (typeOf === 'string') {          
          // @ts-ignore
          createFile(zip, name, content);
          
        } else if (typeOf === 'object') {
          const dirRoot = zip.folder(name);
          // @ts-ignore
          await createDirectory(dirRoot, content);
        }
      }
    }
  }

};

export default ExportCustomZip;

//Helper function
function isAbv(value) {
  return value && value.buffer instanceof ArrayBuffer && value.byteLength !== undefined;
}