
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

let IsUIHovered = false;


/**
 * This function creates a canvas composed of a HTML DIV as the parent, uses threeJS CSS Renderer
 * @param {Number} width 
 * @param {Number} height 
 * @param {Number} position_x 
 * @param {Number} position_y 
 * @param {Number} position_z 
 * @param {Number} rotation_y 
 * @param {Function<HTMLElement>} htmlElementCallback 
 * @returns {CSS3DObject}
 */
function UIElement( width = "auto",height = "auto", position_x, position_y, position_z, rotation_y, /**
 * Returns the created HTML element for a custom event
 */htmlElementCallback ) 
{
    const div = document.createElement( 'div' );
    
    if (width === "auto") {
        div.style.width = "max-content";
    }
    else{
        div.style.width = `${width}px`;
    }

    if (height === "auto") {
        div.style.height = "max-content";
    }
    else{
        div.style.height = `${height}px`;
    }
    
    const object = new CSS3DObject( div );
    object.position.set( position_x, position_y, position_z );
    object.rotation.y = rotation_y;
    object.vhc_name = "UIObject";

    htmlElementCallback ? htmlElementCallback(div):'';


    return object;

};


const m_ReferenceCSS3DJson = {
    x:0,
    y:0,
    z:0,
    rotation_y:0,
}

/**
 * This functions creates and returns a 3D UI Element
 * @param {m_ReferenceCSS3DJson} transform 
 * @param {HTMLElement.innerHTML} domElement 
 * @param {Function} onClick 
 * @param {HTMLElement.class} cssClass 
 * *@param {Function} onHoverEnter 
 * *@param {Function} onHoverExit 
 * @returns 
 */
function Create3DUI(transform = {
    x:0,
    y:0,
    z:0,
    rotation_y:0,
},domElement,onClick=()=>{}, cssClass = "hotspot",onHoverEnter =()=>{},onHoverExit=()=>{})
{    
    const m_UIElement = UIElement("auto","auto",transform.x,transform.y,transform.z,transform.rotation_y,(element) => {
        element.innerHTML = domElement;
        element.classList.add(cssClass);

        // console.log(transform.x,transform.z);

        element.addEventListener("pointerdown",()=>{

            onClick(element);
        });

        element.addEventListener("mouseenter",()=>{

            onHoverEnter(); 
            IsUIHovered = true;
        });

        element.addEventListener("mouseleave",()=>{

            onHoverExit(); 
            IsUIHovered = false;
        })
        
    })

    return m_UIElement;
}

function SetUIHoveredState(state) {
    IsUIHovered = state;
}

export { Create3DUI, IsUIHovered, SetUIHoveredState };
